.cdk-drag-preview {
  box-sizing: border-box;
  background-color: white;
  border-radius: 4px;
  box-shadow:
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
  .grid-col {
    display: none;
  }
  .grid-col:first-of-type {
    display: flex;
    width: 100%;
    border: none !important;
  }
}

.cdk-drag-placeholder {
  opacity: 0.5;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.grid-list.cdk-drop-list-dragging {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.datatype-placeholder {
  height: 3.6rem;
  padding: 0 1rem;
  border: 1px solid $color-grey-200;
  margin-bottom: 0.5rem;
}
