.mat-mdc-menu-panel {
  min-height: auto !important;
  .mat-mdc-menu-content {
    min-width: 15rem;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    height: 100%;
    .mat-mdc-menu-item.menu-title {
      font-size: 1.1rem;
      font-weight: 600;
    }
    .menu-search {
      width: 100%;
      padding: 0.5rem 2rem;
      display: flex;
      align-items: center;
      > mat-icon {
        flex-shrink: 0;
        margin-right: 0.5rem;
      }
      input {
        flex-grow: 1;
        border: 0;
        outline: 0;
      }
      .clear-search {
        flex-shrink: 0;
        width: 1.2rem;
        height: 1.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-grey-600;
        border-radius: 50%;
        color: white;
        &:hover,
        &:active,
        &.active {
          background-color: $color-grey-200;
        }
      }
      button.clear-search {
        padding: 0;
        border: none;
      }
    }
    .menu-items-container {
      max-height: 30rem;
      overflow-y: auto;
    }
    .mat-mdc-menu-item {
      font-size: 1.3rem;
      font-weight: 400;
      height: 3rem;
      min-height: 3rem;
      line-height: 3rem;
      display: flex;
      align-items: center;
      color: $color-primary-900;
      position: relative;
      span {
        font-size: 1.3rem;
        font-weight: 400;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    a.mat-mdc-menu-item,
    button.mat-mdc-menu-item:not(.custom-option) {
      border-radius: 3px;
      span {
        color: $color-primary-900;
      }
      mat-icon {
        margin-right: 0.5rem;
        color: $color-primary-900;
        &.color-error {
          color: $color-error;
        }
      }
      &:active,
      &:focus {
        background-color: transparent;
      }
      &:hover {
        background-color: $color-grey-50;
      }
      &.active {
        background-color: $color-primary-900;
        color: white;
        mat-icon,
        span {
          background-color: $color-primary-900;
          color: white;
        }
        &::after {
          color: white;
        }
      }
      &:disabled,
      &[disabled] {
        color: $color-grey-800;
        pointer-events: none;
        span,
        mat-icon {
          color: $color-grey-800;
        }
      }
      &.color-error {
        color: $color-error;
        span,
        mat-icon {
          color: $color-error;
        }
      }
    }
    mat-divider {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
    hr {
      border: 0;
      height: 1px;
      border-top: 1px solid $color-grey-600;
      &.hr-light {
        border-top-color: $color-grey-200;
      }
    }
  }
}

.mat-mdc-menu-panel.search-menu {
  min-height: auto !important;
  max-height: 40rem !important;
  .mat-mdc-menu-content {
    .menu-search {
      margin-left: 1rem;
      padding-left: 1rem;
      min-height: 2.5rem;
      min-width: 20rem;
      height: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      background-color: transparent;
      &.has-value,
      &:focus-within {
        background-color: white;
      }
      input {
        margin: 0 0.5rem;
        border: none;
        background-color: transparent;
        outline: none;
      }
      .clear-search {
        padding: 0 2px;
        background-color: $color-grey-600;
        border-radius: 50%;
        color: white;
        margin-right: 1rem;
        &:hover,
        &:active,
        &.active {
          background-color: $color-grey-200;
        }
      }
    }
    .menu-search-content {
      max-height: 35rem;
      overflow: auto;
    }
  }
}
