/*! component's theme wise override definitions and variables */
/*! component's common definitions and variables */
/*! component's theme wise override definitions and variables */
/*! component's common definitions and variables */
/* pivot-pager */
.e-pivotview .e-expand::before {
  content: "\e859";
}
.e-pivotview .e-collapse::before {
  content: "\e83d";
}
.e-pivotview.e-rtl .e-expand::before {
  content: "\e854";
}
.e-pivotview .e-sort::before {
  content: "\e667";
}
.e-pivotview .e-pv-filter::before {
  content: "\e909";
}
.e-pivotview .e-pv-filtered::before {
  color: #e3165b;
  content: "\ebb3";
}
.e-pivotview .e-remove::before {
  content: "\e208";
}
.e-pivotview .e-selected-option-icon::before {
  content: "\ea84";
}
.e-pivotview .e-clear-filter-icon::before {
  content: "\ea82";
}
.e-pivotview .e-dropdown-icon::before {
  content: "\e83d";
}
.e-pivotview .e-export::before {
  content: "\e711";
}
.e-pivotview .e-new-report::before {
  content: "\e712";
}
.e-pivotview .e-save-report::before {
  content: "\e703";
}
.e-pivotview .e-saveas-report::before {
  content: "\e704";
}
.e-pivotview .e-rename-report::before {
  content: "\e70c";
}
.e-pivotview .e-remove-report::before {
  content: "\e706";
}
.e-pivotview .e-sub-total::before {
  content: "\e709";
}
.e-pivotview .e-grand-total::before {
  content: "\e707";
}
.e-pivotview .e-toolbar-fieldlist::before {
  content: "\e434";
}
.e-pivotview .e-toolbar-grid::before {
  content: "\e708";
}
.e-pivotview .e-toolbar-chart::before {
  content: "\e70f";
}
.e-pivotview .e-toolbar-formatting::before {
  content: "\e725";
}
.e-pivotview .e-pivot-button .e-edit::before {
  content: "\e891";
}
.e-pivotview .e-sort-ascend-icon::before {
  content: "\e734";
}
.e-pivotview .e-sort-descend-icon::before {
  content: "\e733";
}

.e-pivotview-pdf-export::before {
  content: "\e70d";
}

.e-pivotview-excel-export::before {
  content: "\e700";
}

.e-pivotview-csv-export::before {
  content: "\e701";
}

.e-pivotview-png-export::before {
  content: "\e715";
}

.e-pivotview-jpeg-export::before {
  content: "\e713";
}

.e-pivotview-svg-export::before {
  content: "\e716";
}

.e-mdx::before {
  content: "\e724";
}

.e-pivotview-select-icon::before {
  content: "\e614";
}

.e-pivotview-export::before {
  content: "\e711";
}

.e-pivotview-grid::before {
  content: "\e708";
}

.e-pivotview-expand::before {
  content: "\e556";
}

.e-pivotview-collapse::before {
  content: "\e554";
}

.e-pivot-format-menu::before {
  content: "\e728";
}

.e-pivot-number-format-menu::before {
  content: "\e70b";
}

.e-pivot-conditional-format-menu::before {
  content: "\e725";
}

.e-pivot-format-toolbar::before {
  content: "\e70b";
}

.e-pivotview-group::before {
  content: "\e74b";
}

.e-pivotview-ungroup::before {
  content: "\e74c";
}

.e-level-options .e-selected-level-icon::before {
  content: "\e614";
}

/*! PivotView layout */
.e-pivotview {
  /* stylelint-disable */
  /* stylelint-enable */
  /* stylelint-enable */
  /* stylelint-disable */
  /* stylelint-enable */
}
.e-pivotview .e-value-field-settings,
.e-pivotview .e-member-editor-dialog,
.e-pivotview .e-group-field-settings,
.e-pivotview .e-pivot-format-dialog {
  max-height: 450px !important;
  border-radius: 4px;
  max-width: 400px !important;
}
.e-pivotview .e-value-field-settings .e-field-name-text-container {
  display: -ms-flexbox;
  display: flex;
}
.e-pivotview .e-value-field-settings .e-field-name-title {
  -ms-flex: none;
      flex: none;
  font-size: 13px;
  font-weight: 500;
}
.e-pivotview .e-value-field-settings .e-field-name-content {
  -ms-flex: auto;
      flex: auto;
  font-size: 13px;
  font-weight: 500;
  width: 100%;
}
.e-pivotview .e-value-field-settings .e-caption-input-container,
.e-pivotview .e-value-field-settings .e-type-option-container,
.e-pivotview .e-value-field-settings .e-base-field-option-container,
.e-pivotview .e-value-field-settings .e-base-item-option-container {
  padding-top: 20px;
}
.e-pivotview .e-value-field-settings .e-base-field-option-text,
.e-pivotview .e-value-field-settings .e-base-item-option-text,
.e-pivotview .e-value-field-settings .e-type-option-text {
  font-size: 13px;
  font-weight: 500;
  width: 100%;
}
.e-pivotview .e-value-field-settings .e-caption-input-text {
  font-size: 13px;
}
.e-pivotview .e-group-field-settings {
  /* stylelint-disable */
  width: auto !important;
  /* stylelint-enable */
}
.e-pivotview .e-group-field-settings .e-caption-option-text,
.e-pivotview .e-group-field-settings .e-input-option-text,
.e-pivotview .e-group-field-settings .e-group-inerval-option-text,
.e-pivotview .e-group-field-settings .e-checkbox-wrapper {
  padding-bottom: 10px;
}
.e-pivotview .e-group-field-settings .e-group-start-option-container .e-checkbox-wrapper {
  padding-top: 0;
}
.e-pivotview .e-group-field-settings .e-caption-option-text,
.e-pivotview .e-group-field-settings .e-input-option-text,
.e-pivotview .e-group-field-settings .e-group-inerval-option-text,
.e-pivotview .e-group-field-settings .e-checkbox-wrapper .e-label {
  font-size: 13px;
  font-weight: 500;
  width: 100%;
}
.e-pivotview .e-group-field-settings .e-group-input-container,
.e-pivotview .e-group-field-settings .e-group-end-option-container,
.e-pivotview .e-group-field-settings .e-group-interval-option-container {
  padding-top: 20px;
}
.e-pivotview .e-group-field-settings .e-group-field-div-content {
  margin-bottom: 10px;
}
.e-pivotview .e-member-editor-dialog {
  min-width: 320px;
  /* stylelint-disable */
  /* stylelint-disable */
}
.e-pivotview .e-member-editor-dialog .e-editor-search-container {
  display: -ms-flexbox;
  display: flex;
  padding: 0 20px 10px;
}
.e-pivotview .e-member-editor-dialog .e-editor-search-container .e-clear-icon-hide {
  visibility: hidden;
}
.e-pivotview .e-member-editor-dialog .e-filter-sort {
  box-shadow: none;
  margin-left: 10px;
}
.e-pivotview .e-member-editor-dialog .e-filter-sort .e-member-sort .e-sort-ascend-icon,
.e-pivotview .e-member-editor-dialog .e-filter-sort .e-member-sort .e-sort-descend-icon {
  font-size: 16px;
}
.e-pivotview .e-member-editor-dialog .e-member-prompt {
  padding: 15px 20px;
  text-align: center;
  width: 100%;
}
.e-pivotview .e-member-editor-dialog .e-select-all-container ul li .e-text-content > .e-icon-expandable {
  display: none;
}
.e-pivotview .e-member-editor-dialog .e-member-editor-container-outer-div {
  display: inline-block;
  height: 150px;
  max-width: 400px;
  overflow: auto;
  width: 100%;
}
.e-pivotview .e-member-editor-dialog .e-member-editor-container {
  display: inline-table;
  width: 100%;
}
.e-pivotview .e-member-editor-dialog .e-member-editor-container.e-olap-field-list-tree {
  overflow-x: auto;
}
.e-pivotview .e-member-editor-dialog .e-select-all-container ul,
.e-pivotview .e-member-editor-dialog .e-member-editor-container ul {
  margin-left: -35px;
  overflow: hidden;
}
.e-pivotview .e-member-editor-dialog .e-select-all-container ul li .e-list-text,
.e-pivotview .e-member-editor-dialog .e-member-editor-container ul li .e-list-text {
  font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif", "-apple-system", "BlinkMacSystemFont";
}
.e-pivotview .e-member-editor-dialog .e-select-all-container ul li .e-list-icon,
.e-pivotview .e-member-editor-dialog .e-member-editor-container ul li .e-list-icon {
  margin: 0;
}
.e-pivotview .e-member-editor-dialog .e-select-all-container ul,
.e-pivotview .e-member-editor-dialog .e-member-editor-container ul {
  margin-left: -35px;
  overflow: hidden;
}
.e-pivotview .e-member-editor-dialog .e-select-all-container ul li .e-list-text,
.e-pivotview .e-member-editor-dialog .e-member-editor-container ul li .e-list-text {
  font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif", "-apple-system", "BlinkMacSystemFont";
}
.e-pivotview .e-member-editor-dialog .e-select-all-container ul li .e-list-icon,
.e-pivotview .e-member-editor-dialog .e-member-editor-container ul li .e-list-icon {
  margin: 0;
}
.e-pivotview .e-member-editor-dialog .e-excelfilter {
  padding-top: 20px;
}
.e-pivotview .e-member-editor-dialog .e-excelfilter .e-member-editor-container-outer-div {
  height: 150px;
}
.e-pivotview .e-member-editor-dialog.e-node-limit .e-excelfilter .e-member-editor-container-outer-div {
  height: 145px;
}
.e-pivotview .e-member-editor-dialog .e-label-filter,
.e-pivotview .e-member-editor-dialog .e-value-filter {
  height: 248px;
  overflow: auto;
  padding: 20px;
  padding-bottom: 0;
}
.e-pivotview .e-member-editor-dialog .e-label-filter .e-filter-text-div,
.e-pivotview .e-member-editor-dialog .e-value-filter .e-filter-text-div {
  /* stylelint-disable */
  color: rgba(0, 0, 0, 0.6) !important;
  /* stylelint-enable */
  font-size: 13px;
  font-weight: 500;
}
.e-pivotview .e-member-editor-dialog .e-label-filter .e-filter-option-container-1,
.e-pivotview .e-member-editor-dialog .e-label-filter .e-filter-option-container-2,
.e-pivotview .e-member-editor-dialog .e-label-filter .e-separator-div,
.e-pivotview .e-member-editor-dialog .e-value-filter .e-filter-option-container-1,
.e-pivotview .e-member-editor-dialog .e-value-filter .e-filter-option-container-2,
.e-pivotview .e-member-editor-dialog .e-value-filter .e-separator-div {
  padding-top: 20px;
}
.e-pivotview .e-member-editor-dialog .e-label-filter .e-between-text-div,
.e-pivotview .e-member-editor-dialog .e-value-filter .e-between-text-div {
  padding: 8px 0;
}
.e-pivotview .e-member-editor-dialog .e-label-filter .e-disable,
.e-pivotview .e-member-editor-dialog .e-value-filter .e-disable {
  display: none;
}
.e-pivotview .e-member-editor-dialog .e-filter-tab-container {
  border: none;
}
.e-pivotview .e-member-editor-dialog .e-filter-tab-container .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text {
  margin: 0 0 0 6px;
}
.e-pivotview .e-member-editor-dialog .e-filter-tab-container .e-tab-header .e-text-wrap .e-tab-icon {
  height: fit-content;
}
.e-pivotview .e-member-editor-dialog .e-filter-tab-container .e-tab-header .e-text-wrap .e-tab-icon::before {
  font-size: 14px;
  /* stylelint-disable */
  top: 0 !important;
  /* stylelint-enable */
}
.e-pivotview .e-member-editor-dialog .e-filter-tab-container.e-rtl .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text {
  margin: 0 6px 0 0;
}
.e-pivotview .e-member-editor-dialog .e-disable {
  display: none !important;
}
.e-pivotview .e-member-editor-dialog .e-clear-filter-button {
  float: left;
  margin: 0 !important;
}
.e-pivotview .e-member-editor-dialog .e-clear-filter-button.e-disable {
  display: none;
}
.e-pivotview .e-member-editor-dialog .e-editor-label-container {
  -ms-flex-align: center;
      align-items: center;
  background: #f5f5f5;
  display: -ms-flexbox !important;
  display: flex !important;
  /* stylelint-enable */
  height: 40px;
  -ms-flex-pack: center;
      justify-content: center;
  opacity: 56%;
}
.e-pivotview .e-member-editor-dialog .e-editor-label-container .e-editor-label {
  color: #000;
  font: 500 13px Roboto;
  margin-bottom: 0;
  opacity: 0.87;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.e-pivotview .e-member-editor-dialog.e-rtl .e-member-editor-outer-container .e-editor-search-container .e-filter-sort {
  margin-left: 0;
  margin-right: 10px;
}
.e-pivotview .e-member-editor-dialog.e-rtl .e-member-editor-outer-container ul {
  margin-left: 0;
  margin-right: -35px;
}
.e-pivotview .e-member-editor-dialog.e-rtl .e-member-editor-outer-container .e-editor-search-container .e-filter-sort {
  margin-left: 0;
  margin-right: 10px;
}
.e-pivotview .e-member-editor-dialog.e-rtl .e-member-editor-outer-container ul {
  margin-left: 0;
  margin-right: -35px;
}
.e-pivotview .e-member-editor-dialog.e-rtl .e-footer-content .e-clear-filter-button {
  float: right;
  margin: 0;
}
.e-pivotview .e-member-editor-dialog.e-olap-editor-dialog .e-member-editor-outer-container .e-select-all-container ul,
.e-pivotview .e-member-editor-dialog.e-olap-editor-dialog .e-member-editor-outer-container .e-member-editor-container ul {
  margin-left: 0;
}
.e-pivotview .e-member-editor-dialog.e-olap-editor-dialog .e-member-editor-outer-container .e-treeview ul:first-child {
  padding-left: 10px;
  padding-right: 10px;
}
.e-pivotview .e-member-editor-dialog.e-olap-editor-dialog .e-member-editor-outer-container .e-editor-search-container .e-level-drop {
  margin: 0 5px;
}
.e-pivotview .e-member-editor-dialog.e-olap-editor-dialog .e-member-editor-outer-container .e-editor-search-container .e-level-drop .e-caret {
  font-size: 12px;
}
.e-pivotview .e-member-editor-dialog.e-olap-editor-dialog .e-member-editor-outer-container .e-editor-search-container .e-level-drop li .e-disabled {
  opacity: 0.5;
  pointer-events: none;
}
.e-pivotview .e-member-editor-dialog.e-olap-editor-dialog .e-member-editor-outer-container .e-select-all-container ul,
.e-pivotview .e-member-editor-dialog.e-olap-editor-dialog .e-member-editor-outer-container .e-member-editor-container ul {
  margin-left: 0;
}
.e-pivotview .e-member-editor-dialog.e-olap-editor-dialog .e-member-editor-outer-container .e-treeview ul:first-child {
  padding-left: 10px;
  padding-right: 10px;
}
.e-pivotview .e-member-editor-dialog.e-olap-editor-dialog .e-member-editor-outer-container .e-editor-search-container .e-level-drop {
  margin: 0 5px;
}
.e-pivotview .e-member-editor-dialog.e-olap-editor-dialog .e-member-editor-outer-container .e-editor-search-container .e-level-drop .e-caret {
  font-size: 12px;
}
.e-pivotview .e-member-editor-dialog.e-olap-editor-dialog .e-member-editor-outer-container .e-editor-search-container .e-level-drop li .e-disabled {
  opacity: 0.5;
  pointer-events: none;
}
.e-pivotview .e-member-editor-dialog.e-olap-editor-dialog.e-rtl .e-member-editor-outer-container ul {
  margin-right: 0;
}
.e-pivotview .e-member-editor-dialog .e-member-editor-outer-container:not(.e-excelfilter) .e-editor-search-container {
  padding-top: 4px !important;
}
.e-pivotview .e-value-field-settings .e-dlg-header-content,
.e-pivotview .e-member-editor-dialog .e-dlg-header-content,
.e-pivotview .e-pivot-format-dialog .e-dlg-header-content,
.e-pivotview .e-group-field-settings .e-dlg-header-content {
  border: 0;
  border-radius: 4px;
  font-weight: normal;
}
.e-pivotview .e-value-field-settings .e-footer-content,
.e-pivotview .e-member-editor-dialog .e-footer-content,
.e-pivotview .e-pivot-format-dialog .e-footer-content,
.e-pivotview .e-group-field-settings .e-footer-content {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}
.e-pivotview .e-value-field-settings .e-dlg-content,
.e-pivotview .e-member-editor-dialog .e-dlg-content,
.e-pivotview .e-pivot-format-dialog .e-dlg-content,
.e-pivotview .e-group-field-settings .e-dlg-content {
  overflow: hidden;
}
.e-pivotview .e-value-field-settings .e-dlg-content .e-empty-field::-webkit-input-placeholder,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-empty-field::-webkit-input-placeholder,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-empty-field::-webkit-input-placeholder,
.e-pivotview .e-group-field-settings .e-dlg-content .e-empty-field::-webkit-input-placeholder {
  color: #f44336 !important;
  font-weight: 600 !important;
}
.e-pivotview .e-value-field-settings .e-dlg-content .e-empty-field:-moz-placeholder,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-empty-field:-moz-placeholder,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-empty-field:-moz-placeholder,
.e-pivotview .e-group-field-settings .e-dlg-content .e-empty-field:-moz-placeholder {
  color: #f44336 !important;
  font-weight: 600 !important;
}
.e-pivotview .e-value-field-settings .e-dlg-content .e-empty-field::-moz-placeholder,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-empty-field::-moz-placeholder,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-empty-field::-moz-placeholder,
.e-pivotview .e-group-field-settings .e-dlg-content .e-empty-field::-moz-placeholder {
  color: #f44336 !important;
  font-weight: 600 !important;
}
.e-pivotview .e-value-field-settings .e-dlg-content .e-empty-field:-ms-input-placeholder,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-empty-field:-ms-input-placeholder,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-empty-field:-ms-input-placeholder,
.e-pivotview .e-group-field-settings .e-dlg-content .e-empty-field:-ms-input-placeholder {
  color: #f44336 !important;
  font-weight: 600 !important;
}
.e-pivotview .e-member-editor-dialog .e-dlg-content,
.e-pivotview .e-pivot-format-dialog .e-dlg-content {
  padding: 0;
}
.e-pivotview .e-value-field-settings .e-footer-content,
.e-pivotview .e-member-editor-dialog .e-footer-content {
  border-top: 1px solid #e0e0e0;
}
.e-pivotview .e-value-field-settings,
.e-pivotview .e-pivot-format-dialog {
  min-width: 300px;
}
.e-pivotview .e-value-field-settings .e-dlg-content,
.e-pivotview .e-pivot-format-dialog .e-dlg-content {
  overflow: auto;
}
.e-pivotview .e-pivotchart-type-dialog {
  /* stylelint-disable */
  max-width: 350px !important;
  max-height: 380px !important;
  /* stylelint-enable */
}
.e-pivotview .e-pivotchart-type-dialog .e-chart-type-option-container,
.e-pivotview .e-pivotchart-type-dialog .e-chart-type-option-text {
  padding-bottom: 20px;
}
.e-pivotview .e-pivotchart-type-dialog .e-chart-type-option-container .e-chart-type-option-text,
.e-pivotview .e-pivotchart-type-dialog .e-chart-type-option-text .e-chart-type-option-text {
  padding-bottom: 5px;
}
.e-pivotview .e-pivotchart-type-dialog .e-multiple-axes-mode-container {
  padding-top: 20px;
}
.e-pivotview .e-pivotchart-type-dialog .e-multiple-axes-mode-container .e-multiple-axes-mode-text {
  padding-bottom: 5px;
}
.e-pivotview .e-pivotchart-type-dialog .e-dialog-multiple-axis,
.e-pivotview .e-pivotchart-type-dialog .e-dialog-show-legend {
  width: 49%;
}
.e-pivotview .e-pivot-format-dialog {
  /* stylelint-disable */
  max-height: 550px !important;
  max-width: 350px !important;
  /* stylelint-enable */
}
.e-pivotview .e-pivot-format-dialog .e-pivot-format-dialog-outer {
  padding: 0 20px;
}
.e-pivotview .e-pivot-format-dialog .e-pivot-format-dialog-outer .e-pivot-format-value-lable,
.e-pivotview .e-pivot-format-dialog .e-pivot-format-dialog-outer .e-pivot-format-lable,
.e-pivotview .e-pivot-format-dialog .e-pivot-format-dialog-outer .e-pivot-format-custom-lable,
.e-pivotview .e-pivot-format-dialog .e-pivot-format-dialog-outer .e-pivot-format-grouping-lable,
.e-pivotview .e-pivot-format-dialog .e-pivot-format-dialog-outer .e-pivot-format-decimal-lable {
  font-weight: 500;
}
.e-pivotview .e-pivot-format-dialog .e-pivot-format-dialog-outer .e-pivot-format-value-drop,
.e-pivotview .e-pivot-format-dialog .e-pivot-format-dialog-outer .e-pivot-format-drop,
.e-pivotview .e-pivot-format-dialog .e-pivot-format-dialog-outer .e-pivot-format-custom-text,
.e-pivotview .e-pivot-format-dialog .e-pivot-format-dialog-outer .e-pivot-format-grouping-drop,
.e-pivotview .e-pivot-format-dialog .e-pivot-format-dialog-outer .e-pivot-format-decimal-drop {
  margin-bottom: 20px;
}
.e-pivotview.sf-pivotview .e-pivot-toolbar {
  box-sizing: border-box;
}
.e-pivotview .e-pivot-toolbar {
  background: #f5f5f5;
  border-bottom: 0;
  border-style: solid;
}
.e-pivotview .e-pivot-toolbar .e-toolbar-items {
  background: #f5f5f5;
}
.e-pivotview .e-pivot-toolbar .e-toolbar-item .e-tbar-btn.e-btn .e-icons.e-btn-icon {
  font-size: 16px;
}
.e-pivotview .e-pivot-toolbar .e-pivot-toolbar-menu {
  background-color: #f5f5f5;
  border: none;
  /* stylelint-disable */
}
.e-pivotview .e-pivot-toolbar .e-pivot-toolbar-menu ul .e-menu-item {
  /* stylelint-disable */
  height: 40px !important;
  /* stylelint-enable */
  overflow: hidden;
  padding-left: 10px;
}
.e-pivotview .e-pivot-toolbar .e-pivot-toolbar-menu ul .e-menu-item .e-menu-icon {
  margin-left: 0;
  margin-right: 0;
  font-size: 16px;
}
.e-pivotview .e-pivot-toolbar .e-tab .e-tab-header .e-toolbar-item.e-active {
  margin-left: 18px !important;
}
.e-pivotview .e-pivot-toolbar .e-toolbar-item.e-template {
  padding: 0 !important;
  /* stylelint-enable */
}
.e-pivotview .e-pivot-toolbar .e-reportlist-label {
  float: left;
  font-weight: 500;
}
.e-pivotview .e-pivot-toolbar .e-reportlist-drop {
  float: right;
  margin: 0 8px;
}
.e-pivotview .e-pivot-toolbar.e-rtl .e-reportlist-label {
  float: right;
}
.e-pivotview .e-pivot-toolbar.e-rtl .e-reportlist-drop {
  float: left;
}
.e-pivotview .e-pivot-toolbar.e-rtl .e-pivot-toolbar-menu ul .e-menu-item {
  padding-left: 30px;
  padding-right: 10px;
}
.e-pivotview .e-frozenscrollbar {
  background: #f5f5f5;
}
.e-pivotview .e-headercontent .e-columnheader .e-headercell.e-leftfreeze {
  -webkit-transform: translate3d(0, 0, 0);
}

.e-pivotview-disable-icon {
  visibility: hidden;
}

.e-pivotview-report-inner {
  padding-top: 10px;
}

.e-pivotview-report-dialog {
  /* stylelint-disable */
  max-width: 350px !important;
}

.e-mdx-query-content {
  background-color: #f5f5f5;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  height: 200px;
  margin-top: 5px;
  padding: 5px 10px;
  resize: none;
  width: 100%;
}

.e-pivotview-mdx-dialog {
  max-width: 500px !important;
}

/* stylelint-enable */
.e-report-outer {
  display: inline-block;
  width: 100%;
}

.e-pivotview-report-label {
  float: left;
  margin-top: 8px;
  width: 40%;
}

/* stylelint-disable */
.e-pivotview-report-input {
  float: right;
  width: 60% !important;
}

*.e-rtl .e-pivotview-report-input {
  float: left;
}
*.e-rtl .e-pivotview-report-label {
  float: right;
}

.e-pivotview-pdf-export,
.e-pivotview-excel-export,
.e-pivotview-csv-export,
.e-pivotview-png-export,
.e-pivotview-jpeg-export,
.e-pivotview-svg-export {
  font-size: 18px !important;
}

/* stylelint-enable */
.e-contextmenu-container.e-menu-popup.e-menu-container.e-pivot-chart-menu ul .e-dialog-multiple-axis .e-label,
.e-contextmenu-container.e-menu-popup.e-menu-container.e-pivot-chart-menu ul .e-dialog-multiple-axis .e-label {
  white-space: nowrap;
}

.e-drillthrough-dialog {
  /* stylelint-disable */
  /* stylelint-enable */
}
.e-drillthrough-dialog .e-drillthrough-body-header-container {
  margin-bottom: 20px;
}
.e-drillthrough-dialog .e-drillthrough-body-header {
  color: #000;
  font-weight: 600;
}
.e-drillthrough-dialog .e-drillthrough-body-header-value {
  color: rgba(0, 0, 0, 0.6);
  margin-right: 20px;
}
.e-drillthrough-dialog .e-drillthrough-body-header-common {
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  display: inline-block;
  flex-wrap: wrap;
}

.e-bigger .e-pivotview,
.e-bigger.e-pivotview {
  /* stylelint-disable */
  /* stylelint-enable */
  /* stylelint-disable */
  /* stylelint-enable */
}
.e-bigger .e-pivotview .e-pivot-toolbar .e-pivot-toolbar-menu ul .e-menu-item,
.e-bigger.e-pivotview .e-pivot-toolbar .e-pivot-toolbar-menu ul .e-menu-item {
  /* stylelint-disable */
  height: 52px !important;
  /* stylelint-enable */
}
.e-bigger .e-pivotview .e-member-editor-dialog .e-editor-search-container .e-filter-sort .e-member-sort .e-sort-ascend-icon,
.e-bigger .e-pivotview .e-member-editor-dialog .e-editor-search-container .e-filter-sort .e-member-sort .e-sort-descend-icon,
.e-bigger.e-pivotview .e-member-editor-dialog .e-editor-search-container .e-filter-sort .e-member-sort .e-sort-ascend-icon,
.e-bigger.e-pivotview .e-member-editor-dialog .e-editor-search-container .e-filter-sort .e-member-sort .e-sort-descend-icon {
  font-size: 18px;
}
.e-bigger .e-pivotview .e-member-editor-dialog .e-editor-search-container .e-level-drop .e-caret,
.e-bigger.e-pivotview .e-member-editor-dialog .e-editor-search-container .e-level-drop .e-caret {
  font-size: 14px !important;
}
.e-bigger .e-pivotview .e-member-editor-dialog .e-dlg-content .e-member-editor-outer-container .e-member-editor-container-outer-div,
.e-bigger.e-pivotview .e-member-editor-dialog .e-dlg-content .e-member-editor-outer-container .e-member-editor-container-outer-div {
  height: 120px;
}
.e-bigger .e-pivotview .e-member-editor-dialog .e-filter-tab-container .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-bigger.e-pivotview .e-member-editor-dialog .e-filter-tab-container .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text {
  margin: 0 0 0 6px;
}
.e-bigger .e-pivotview .e-member-editor-dialog .e-filter-tab-container.e-rtl .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-bigger.e-pivotview .e-member-editor-dialog .e-filter-tab-container.e-rtl .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text {
  margin: 0 6px 0 0;
}
.e-bigger .e-pivotview .e-member-editor-dialog.e-node-limit.e-excel-filter .e-excelfilter .e-member-editor-container-outer-div,
.e-bigger.e-pivotview .e-member-editor-dialog.e-node-limit.e-excel-filter .e-excelfilter .e-member-editor-container-outer-div {
  height: 90px !important;
}
.e-bigger .e-pivotview .e-member-editor-dialog .e-excelfilter .e-member-editor-container-outer-div,
.e-bigger.e-pivotview .e-member-editor-dialog .e-excelfilter .e-member-editor-container-outer-div {
  height: 102px !important;
}
.e-bigger .e-pivotview .e-member-editor-dialog .e-label-filter,
.e-bigger .e-pivotview .e-member-editor-dialog .e-value-filter,
.e-bigger.e-pivotview .e-member-editor-dialog .e-label-filter,
.e-bigger.e-pivotview .e-member-editor-dialog .e-value-filter {
  height: 220px;
}
.e-bigger .e-pivotview .e-member-editor-dialog .e-label-filter .e-filter-text-div,
.e-bigger .e-pivotview .e-member-editor-dialog .e-value-filter .e-filter-text-div,
.e-bigger.e-pivotview .e-member-editor-dialog .e-label-filter .e-filter-text-div,
.e-bigger.e-pivotview .e-member-editor-dialog .e-value-filter .e-filter-text-div {
  font-size: 14px;
}
.e-bigger .e-pivotview .e-member-editor-dialog .e-label-filter .e-filter-option-container-1,
.e-bigger .e-pivotview .e-member-editor-dialog .e-label-filter .e-filter-option-container-2,
.e-bigger .e-pivotview .e-member-editor-dialog .e-label-filter .e-separator-div,
.e-bigger .e-pivotview .e-member-editor-dialog .e-value-filter .e-filter-option-container-1,
.e-bigger .e-pivotview .e-member-editor-dialog .e-value-filter .e-filter-option-container-2,
.e-bigger .e-pivotview .e-member-editor-dialog .e-value-filter .e-separator-div,
.e-bigger.e-pivotview .e-member-editor-dialog .e-label-filter .e-filter-option-container-1,
.e-bigger.e-pivotview .e-member-editor-dialog .e-label-filter .e-filter-option-container-2,
.e-bigger.e-pivotview .e-member-editor-dialog .e-label-filter .e-separator-div,
.e-bigger.e-pivotview .e-member-editor-dialog .e-value-filter .e-filter-option-container-1,
.e-bigger.e-pivotview .e-member-editor-dialog .e-value-filter .e-filter-option-container-2,
.e-bigger.e-pivotview .e-member-editor-dialog .e-value-filter .e-separator-div {
  padding-top: 24px;
}
.e-bigger .e-pivotview .e-member-editor-dialog .e-label-filter .e-between-text-div,
.e-bigger .e-pivotview .e-member-editor-dialog .e-value-filter .e-between-text-div,
.e-bigger.e-pivotview .e-member-editor-dialog .e-label-filter .e-between-text-div,
.e-bigger.e-pivotview .e-member-editor-dialog .e-value-filter .e-between-text-div {
  padding: 10px 0;
}
.e-bigger .e-pivotview .e-member-editor-dialog.e-excel-filter,
.e-bigger.e-pivotview .e-member-editor-dialog.e-excel-filter {
  min-width: 350px !important;
  min-height: 400px !important;
}
.e-bigger .e-pivotview .e-member-editor-dialog.e-excel-filter.e-node-limit,
.e-bigger.e-pivotview .e-member-editor-dialog.e-excel-filter.e-node-limit {
  min-height: 415px !important;
}
.e-bigger .e-pivotview .e-member-editor-dialog .e-dlg-header-content .e-clear-filter-button.e-small,
.e-bigger .e-pivotview .e-value-field-settings .e-dlg-header-content .e-clear-filter-button.e-small,
.e-bigger .e-pivotview .e-pivot-format-dialog .e-dlg-header-content .e-clear-filter-button.e-small,
.e-bigger.e-pivotview .e-member-editor-dialog .e-dlg-header-content .e-clear-filter-button.e-small,
.e-bigger.e-pivotview .e-value-field-settings .e-dlg-header-content .e-clear-filter-button.e-small,
.e-bigger.e-pivotview .e-pivot-format-dialog .e-dlg-header-content .e-clear-filter-button.e-small {
  font-size: 14px;
  top: 0;
}
.e-bigger .e-pivotview .e-value-field-settings,
.e-bigger.e-pivotview .e-value-field-settings {
  max-height: 510px !important;
}
.e-bigger .e-pivotview .e-value-field-settings .e-dlg-content,
.e-bigger.e-pivotview .e-value-field-settings .e-dlg-content {
  padding: 0;
}
.e-bigger .e-pivotview .e-pivot-format-dialog .e-pivot-format-dialog-outer,
.e-bigger.e-pivotview .e-pivot-format-dialog .e-pivot-format-dialog-outer {
  padding: 0 20px 10px;
}
.e-bigger .e-pivotview .e-group-field-settings .e-caption-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-caption-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-checkbox-wrapper .e-label {
  font-size: 14px;
}
.e-bigger .e-pivotview .e-group-field-settings .e-group-input-container,
.e-bigger .e-pivotview .e-group-field-settings .e-group-end-option-container,
.e-bigger .e-pivotview .e-group-field-settings .e-group-interval-option-container,
.e-bigger.e-pivotview .e-group-field-settings .e-group-input-container,
.e-bigger.e-pivotview .e-group-field-settings .e-group-end-option-container,
.e-bigger.e-pivotview .e-group-field-settings .e-group-interval-option-container {
  padding-top: 24px;
}
.e-bigger .e-pivotview .e-group-field-settings .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-group-inerval-option-text {
  font-size: 14px;
}
.e-bigger .e-pivotview .e-group-field-settings .e-group-start-option-container .e-checkbox-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-group-start-option-container .e-checkbox-wrapper {
  padding-top: 0;
}
.e-bigger .e-pivotview .e-value-field-settings .e-field-name-text-container,
.e-bigger.e-pivotview .e-value-field-settings .e-field-name-text-container {
  display: -ms-flexbox;
  display: flex;
}
.e-bigger .e-pivotview .e-value-field-settings .e-field-name-title,
.e-bigger .e-pivotview .e-value-field-settings .e-field-name-content,
.e-bigger.e-pivotview .e-value-field-settings .e-field-name-title,
.e-bigger.e-pivotview .e-value-field-settings .e-field-name-content {
  font-size: 14px;
  font-weight: 500;
}
.e-bigger .e-pivotview .e-value-field-settings .e-caption-input-container,
.e-bigger .e-pivotview .e-value-field-settings .e-type-option-container,
.e-bigger .e-pivotview .e-value-field-settings .e-base-field-option-container,
.e-bigger .e-pivotview .e-value-field-settings .e-base-item-option-container,
.e-bigger.e-pivotview .e-value-field-settings .e-caption-input-container,
.e-bigger.e-pivotview .e-value-field-settings .e-type-option-container,
.e-bigger.e-pivotview .e-value-field-settings .e-base-field-option-container,
.e-bigger.e-pivotview .e-value-field-settings .e-base-item-option-container {
  padding-top: 24px;
}
.e-bigger .e-pivotview .e-value-field-settings .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-type-option-text {
  font-size: 14px;
}
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content {
  padding: 0 20px 10px;
}

.e-bigger .e-pivotview-report-dialog .e-pivotview-report-label,
.e-bigger.e-pivotview-report-dialog .e-pivotview-report-label {
  margin-top: 12px;
}

/*! PivotView theme */
.e-pivotview {
  /* stylelint-enable */
  /* stylelint-disable */
  /* stylelint-enable */
  /* stylelint-disable */
  /* stylelint-enable */
  /* stylelint-disable */
  /* stylelint-enable */
  /* stylelint-enable */
}
.e-pivotview .e-pivotcell-container {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  max-height: inherit;
}
.e-pivotview .e-pivotcell-container .e-stackedheadertext.e-cellvalue,
.e-pivotview .e-pivotcell-container .e-headertext.e-cellvalue {
  -ms-flex-item-align: stretch;
      -ms-grid-row-align: stretch;
      align-self: stretch;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.e-pivotview .e-pivotcell-container .e-sortfilterdiv.e-value-sort-icon {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
}
.e-pivotview .e-gridclip .e-pivotcell-container .e-stackedheadertext.e-cellvalue,
.e-pivotview .e-gridclip .e-pivotcell-container .e-headertext.e-cellvalue {
  text-overflow: clip;
}
.e-pivotview .e-nextspan {
  display: inline-block;
  width: 18px;
}
.e-pivotview .e-lastspan {
  display: inline-block;
  padding-top: 10px;
  width: 12px;
}
.e-pivotview .e-grid.e-gridhover tr[role=row]:hover .e-rowcell:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell) {
  background-color: rgba(0, 0, 0, 0);
}
.e-pivotview .e-rowsheader .e-cellvalue {
  margin-left: 10px;
}
.e-pivotview .e-valuescontent {
  padding-right: 8px;
  text-align: right;
}
.e-pivotview .e-grid .e-rowcell {
  /* stylelint-disable */
  font-size: 13px !important;
  padding-left: 8px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.e-pivotview .e-grid .e-content-virtualtable {
  overflow-x: auto;
  scrollbar-width: none; /* firefox */
}
.e-pivotview .e-grid .e-content-virtualtable::-webkit-scrollbar {
  display: none; /* safari, chrome and edge */
}
.e-pivotview .e-gtot,
.e-pivotview .e-gtot.e-rowsheader,
.e-pivotview .e-gtot.e-columnsheader,
.e-pivotview .e-grid .e-content,
.e-pivotview .e-grid .e-gridheader {
  background: #f5f5f5;
}
.e-pivotview .e-headercell:not(.e-columnsheader) {
  background: #f5f5f5 !important;
}
.e-pivotview .e-grid .e-gridheader {
  border-bottom-width: 1px;
}
.e-pivotview .e-grid .e-gridheader table th[rowspan="1"],
.e-pivotview .e-grid .e-gridheader table th:not([rowspan]) {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  vertical-align: middle;
}
.e-pivotview .e-gtot {
  font-weight: 500;
}
.e-pivotview .e-gtot .e-cellvalue {
  color: #000 87%;
}
.e-pivotview .e-rowsheader,
.e-pivotview .e-columnsheader {
  background: #fafafa;
}
.e-pivotview .e-rowsheader .e-cellvalue {
  margin-left: 8px;
  margin-top: 5px;
}
.e-pivotview .e-pivot-conditional-empty-format {
  margin: 10px;
}
.e-pivotview .e-member-editor-dialog,
.e-pivotview .e-olap-editor-dialog {
  min-height: 350px !important;
  min-width: 320px !important;
  visibility: hidden;
}
.e-pivotview .e-member-editor-dialog.e-node-limit,
.e-pivotview .e-olap-editor-dialog.e-node-limit {
  max-height: 450px !important;
}
.e-pivotview .e-member-editor-dialog .e-toolbar .e-toolbar-items {
  margin-left: 20px;
}
.e-pivotview .e-member-editor-dialog .e-rtl.e-toolbar .e-toolbar-items {
  margin-left: 0;
  margin-right: 20px;
}
.e-pivotview .e-pivot-format-dialog .e-pivot-format-table {
  width: 100%;
}
.e-pivotview .e-toolbar .e-tbar-btn {
  background-color: transparent;
}
.e-pivotview .e-grid .e-rowsheader.e-active,
.e-pivotview .e-grid .e-rowsheader.e-cellselectionbackground,
.e-pivotview .e-grid .e-columnsheader.e-active,
.e-pivotview .e-grid .e-columnsheader.e-cellselectionbackground,
.e-pivotview .e-grid .e-rowsheader.e-active,
.e-pivotview .e-grid .e-rowsheader.e-selectionbackground,
.e-pivotview .e-grid .e-columnsheader.e-active,
.e-pivotview .e-grid .e-columnsheader.e-selectionbackground {
  background: #e0e0e0 !important;
  color: #000 !important;
}
.e-pivotview .e-grid .e-rowsheader.e-active .e-stackedheadercelldiv, .e-pivotview .e-grid .e-rowsheader.e-active .e-cellvalue,
.e-pivotview .e-grid .e-rowsheader.e-active .e-icons,
.e-pivotview .e-grid .e-rowsheader.e-cellselectionbackground .e-stackedheadercelldiv,
.e-pivotview .e-grid .e-rowsheader.e-cellselectionbackground .e-cellvalue,
.e-pivotview .e-grid .e-rowsheader.e-cellselectionbackground .e-icons,
.e-pivotview .e-grid .e-columnsheader.e-active .e-stackedheadercelldiv,
.e-pivotview .e-grid .e-columnsheader.e-active .e-cellvalue,
.e-pivotview .e-grid .e-columnsheader.e-active .e-icons,
.e-pivotview .e-grid .e-columnsheader.e-cellselectionbackground .e-stackedheadercelldiv,
.e-pivotview .e-grid .e-columnsheader.e-cellselectionbackground .e-cellvalue,
.e-pivotview .e-grid .e-columnsheader.e-cellselectionbackground .e-icons,
.e-pivotview .e-grid .e-rowsheader.e-active .e-stackedheadercelldiv,
.e-pivotview .e-grid .e-rowsheader.e-active .e-cellvalue,
.e-pivotview .e-grid .e-rowsheader.e-active .e-icons,
.e-pivotview .e-grid .e-rowsheader.e-selectionbackground .e-stackedheadercelldiv,
.e-pivotview .e-grid .e-rowsheader.e-selectionbackground .e-cellvalue,
.e-pivotview .e-grid .e-rowsheader.e-selectionbackground .e-icons,
.e-pivotview .e-grid .e-columnsheader.e-active .e-stackedheadercelldiv,
.e-pivotview .e-grid .e-columnsheader.e-active .e-cellvalue,
.e-pivotview .e-grid .e-columnsheader.e-active .e-icons,
.e-pivotview .e-grid .e-columnsheader.e-selectionbackground .e-stackedheadercelldiv,
.e-pivotview .e-grid .e-columnsheader.e-selectionbackground .e-cellvalue,
.e-pivotview .e-grid .e-columnsheader.e-selectionbackground .e-icons {
  color: #000 !important;
}
.e-pivotview .e-grid .e-rowsheader.e-active .e-headertext,
.e-pivotview .e-grid .e-rowsheader.e-cellselectionbackground .e-headertext,
.e-pivotview .e-grid .e-columnsheader.e-active .e-headertext,
.e-pivotview .e-grid .e-columnsheader.e-cellselectionbackground .e-headertext,
.e-pivotview .e-grid .e-rowsheader.e-active .e-headertext,
.e-pivotview .e-grid .e-rowsheader.e-selectionbackground .e-headertext,
.e-pivotview .e-grid .e-columnsheader.e-active .e-headertext,
.e-pivotview .e-grid .e-columnsheader.e-selectionbackground .e-headertext {
  color: #000 !important;
}
.e-pivotview .e-drop-indicator {
  display: block;
}
.e-pivotview .e-sortfilterdiv .e-value-sort-icon {
  float: right;
}
.e-pivotview .e-sortfilterdiv .e-value-sort-icon.e-value-sort-icon {
  font-size: 12px !important;
}
.e-pivotview .e-sortfilterdiv:not(.e-value-sort-icon) {
  display: none;
}
.e-pivotview .e-stackedheadercelldiv .e-sortfilterdiv, .e-pivotview .e-headercelldiv .e-sortfilterdiv {
  margin: -15px 0 !important;
}
.e-pivotview .e-stackedheadercelldiv .e-sortfilterdiv.e-value-sort-align, .e-pivotview .e-headercelldiv .e-sortfilterdiv.e-value-sort-align {
  margin: 0 !important;
}
.e-pivotview .e-stackedheadercelldiv .e-sortfilterdiv, .e-pivotview .e-stackedheadercelldiv .e-sortfilterdiv.e-value-sort-align, .e-pivotview .e-headercelldiv .e-sortfilterdiv, .e-pivotview .e-headercelldiv .e-sortfilterdiv.e-value-sort-align {
  margin: 0 !important;
  margin-left: auto !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.e-pivotview .e-rowsheader .e-sortfilterdiv {
  margin: -8px 0 !important;
}
.e-pivotview .e-rowsheader .e-sortfilterdiv.e-value-sort-align {
  margin: 0 !important;
}
.e-pivotview .e-pivotchart > svg, .e-pivotview .e-pivotchart-inner > svg {
  border: 1px solid #e0e0e0;
}
.e-pivotview .e-grid .e-icons {
  color: rgba(0, 0, 0, 0.54) !important;
}
.e-pivotview .e-grid .e-icons:hover {
  color: rgba(0, 0, 0, 0.87) !important;
}
.e-pivotview .e-grid {
  border-color: #e0e0e0;
}
.e-pivotview .e-grid .e-rowsheader {
  padding-left: 8px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
}
.e-pivotview .e-grid .e-rowsheader.e-active .e-icons {
  color: rgba(0, 0, 0, 0.54) !important;
}
.e-pivotview.sf-pivotview .e-spinner-pane.e-pivot-outer-spinner {
  z-index: 1 !important;
}
.e-pivotview.sf-pivotview .e-spinner-pane.e-pivot-outer-spinner .e-spinner-inner {
  visibility: hidden !important;
  /* stylelint-enable */
}
.e-pivotview.sf-pivotview .e-grid .e-pivot-content-loader {
  position: absolute;
  z-index: 10;
  color: #3f51b5;
  font-size: 20px;
  text-align: center;
  padding: 10%;
  pointer-events: none;
}
.e-pivotview.sf-pivotview .e-grid .e-pivot-content-loader.e-hide-loader {
  display: none;
}
.e-pivotview.sf-pivotview .e-grid .e-virtual-pivot-content {
  visibility: hidden;
}
.e-pivotview.sf-pivotview.e-device .e-pivotcell-container .e-stackedheadertext.e-cellvalue {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
}
.e-pivotview .e-grid .e-headercelldiv,
.e-pivotview .e-grid .e-columnsheader {
  /* stylelint-disable */
  padding-left: 8px !important;
}
.e-pivotview .sf-grid .e-spinner-pane {
  z-index: 1 !important;
}
.e-pivotview .e-grid .e-headercelldiv,
.e-pivotview .e-grid .e-columnsheader {
  padding-right: 8px !important;
}
.e-pivotview .e-grid .e-headercelldiv,
.e-pivotview .e-grid .e-stackedheadercelldiv {
  line-height: normal;
}
.e-pivotview .e-frozenheader > .e-table,
.e-pivotview .e-frozencontent > .e-table {
  border-left-color: #e0e0e0 !important;
  border-right-color: #e0e0e0 !important;
  border-left: 0;
  border-right-width: 1px;
}
.e-pivotview .e-leftfreeze.e-freezeleftborder {
  border-left-color: #e0e0e0 !important;
  border-right-color: #e0e0e0 !important;
  border-right-width: 1px !important;
}
.e-pivotview .e-leftfreeze.e-freezeleftborder:not(.e-gtot) {
  background: #fafafa;
}
.e-pivotview .e-stackedheadercelldiv {
  display: block;
}
.e-pivotview .e-stackedheadertext.e-cellvalue,
.e-pivotview .e-stackedheadercelldiv.e-cellvalue {
  display: inline-block;
  padding-left: 12px !important;
}
.e-pivotview .e-headercelldiv.e-cellvalue {
  margin-left: 0px;
}
.e-pivotview .e-stackedheadercelldiv {
  padding: 0 0 0 1px !important;
}
.e-pivotview .e-stackedheadercelldiv,
.e-pivotview .e-stackedheadertext,
.e-pivotview .e-headertext,
.e-pivotview .e-rowsheader .e-cellvalue {
  color: rgba(0, 0, 0, 0.6);
  font-size: 13px;
  font-weight: 500;
}
.e-pivotview .e-stackedheadercelldiv .e-hyperlinkcell,
.e-pivotview .e-stackedheadertext .e-hyperlinkcell,
.e-pivotview .e-headertext .e-hyperlinkcell,
.e-pivotview .e-rowsheader .e-cellvalue .e-hyperlinkcell {
  cursor: pointer;
}
.e-pivotview .e-valuescontent .e-cellvalue .e-hyperlinkcell {
  cursor: pointer;
}
.e-pivotview .e-valuescontent {
  opacity: 0.87 !important;
}
.e-pivotview .e-rowcell {
  opacity: 1;
  /* stylelint-disable */
  /* stylelint-enable */
}
.e-pivotview .e-rowcell .e-cellvalue {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.e-pivotview .e-grouping-bar {
  /* stylelint-disable */
  /* stylelint-enable */
}
.e-pivotview .e-grouping-bar .e-right-axis-fields {
  -ms-flex: auto;
      flex: auto;
}
.e-pivotview .e-grouping-bar .e-left-axis-fields,
.e-pivotview .e-grouping-bar .e-right-axis-fields {
  height: auto;
}
.e-pivotview .e-grouping-bar .e-left-axis-fields {
  min-width: 250px;
}
.e-pivotview .e-grouping-bar .e-axis-row,
.e-pivotview .e-grouping-bar .e-axis-column,
.e-pivotview .e-grouping-bar .e-axis-value,
.e-pivotview .e-grouping-bar .e-axis-filter,
.e-pivotview .e-grouping-bar .e-group-all-fields {
  background: #f5f5f5;
  display: -ms-flexbox;
  display: flex;
}
.e-pivotview .e-grouping-bar .e-left-axis-fields {
  border: 1px solid #e0e0e0;
  border-bottom-width: 0;
  border-right-width: 0;
}
.e-pivotview .e-grouping-bar .e-all-fields-axis {
  background: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-bottom-width: 0;
  height: 30px !important;
  min-height: 30px !important;
}
.e-pivotview .e-grouping-bar .e-all-fields-axis .e-hscroll .e-scroll-nav {
  background: #f5f5f5;
  min-height: 30px !important;
}
.e-pivotview .e-grouping-bar .e-all-fields-axis .e-hscroll .e-scroll-nav:hover {
  background-color: #bdbdbd;
}
.e-pivotview .e-grouping-bar .e-all-fields-axis .e-hscroll .e-scroll-nav:hover .e-nav-arrow {
  color: rgba(0, 0, 0, 0.87) !important;
}
.e-pivotview .e-grouping-bar .e-all-fields-axis .e-toolbar-items {
  background: #f5f5f5;
  min-height: 30px !important;
}
.e-pivotview .e-grouping-bar .e-all-fields-axis .e-toolbar-items .e-toolbar-item {
  margin: 0 !important;
  min-height: 30px !important;
  padding: 0 !important;
}
.e-pivotview .e-grouping-bar .e-right-axis-fields {
  border: 1px solid #e0e0e0;
  border-bottom-width: 0;
}
.e-pivotview .e-grouping-bar .e-right-axis-fields .e-axis-filter {
  border-bottom: 1px solid #e0e0e0;
}
.e-pivotview .e-group-values,
.e-pivotview .e-group-columns,
.e-pivotview .e-group-filters,
.e-pivotview .e-group-rows,
.e-pivotview .e-group-all-fields {
  background: #f5f5f5;
  display: inline-table;
  -ms-flex: auto;
      flex: auto;
  height: 30px;
  min-height: 30px;
  overflow: hidden;
  padding-left: 8px;
  scroll-behavior: smooth;
  vertical-align: top;
}
.e-pivotview .e-group-values.e-drag-restrict,
.e-pivotview .e-group-columns.e-drag-restrict,
.e-pivotview .e-group-filters.e-drag-restrict,
.e-pivotview .e-group-rows.e-drag-restrict,
.e-pivotview .e-group-all-fields.e-drag-restrict {
  cursor: no-drop;
}
.e-pivotview .e-group-values .e-draggable-prompt,
.e-pivotview .e-group-columns .e-draggable-prompt,
.e-pivotview .e-group-filters .e-draggable-prompt,
.e-pivotview .e-group-rows .e-draggable-prompt,
.e-pivotview .e-group-all-fields .e-draggable-prompt {
  color: #000;
  display: inline-block;
  line-height: 22px;
  margin-top: 4px;
  padding-left: 8px;
  pointer-events: none;
  position: relative;
  text-align: center;
  vertical-align: middle;
}
.e-pivotview .e-grouping-bar .e-pvt-btn-div,
.e-pivotview .e-group-rows .e-pvt-btn-div {
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 24px;
  padding: 3px;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 8px;
}
.e-pivotview .e-grouping-bar .e-pvt-btn-div .e-indent-div,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-indent-div {
  pointer-events: none;
}
.e-pivotview .e-grouping-bar .e-pvt-btn-div .e-drop-indicator,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-drop-indicator {
  pointer-events: none;
  width: 2px;
}
.e-pivotview .e-grouping-bar .e-pvt-btn-div .e-drop-indicator.e-drop-hover,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-drop-indicator.e-drop-hover {
  border-left: 1px dashed #9e9e9e;
}
.e-pivotview .e-grouping-bar .e-pivot-button,
.e-pivotview .e-group-rows .e-pivot-button {
  -ms-flex-align: center;
      align-items: center;
  background: #e0e0e0;
  border: 1px solid #e0e0e0;
  border-radius: 25px;
  box-shadow: none;
  /* stylelint-disable */
  color: #000 !important;
  /* stylelint-enable */
  cursor: default;
  display: -ms-flexbox;
  display: flex;
  font-weight: normal;
  height: 24px;
  padding: 0 8px;
  text-transform: none;
  vertical-align: bottom;
  z-index: 1;
  /* stylelint-disable */
  /* stylelint-enable */
}
.e-pivotview .e-grouping-bar .e-pivot-button.e-list-selected,
.e-pivotview .e-group-rows .e-pivot-button.e-list-selected {
  opacity: 0.5;
}
.e-pivotview .e-grouping-bar .e-pivot-button:hover,
.e-pivotview .e-group-rows .e-pivot-button:hover {
  background: #bdbdbd;
  border-color: #bdbdbd;
}
.e-pivotview .e-grouping-bar .e-pivot-button:hover .e-sort,
.e-pivotview .e-grouping-bar .e-pivot-button:hover .e-dropdown-icon,
.e-pivotview .e-grouping-bar .e-pivot-button:hover .e-pv-filter,
.e-pivotview .e-grouping-bar .e-pivot-button:hover .e-pv-filtered,
.e-pivotview .e-grouping-bar .e-pivot-button:hover .e-remove,
.e-pivotview .e-grouping-bar .e-pivot-button:hover .e-edit,
.e-pivotview .e-group-rows .e-pivot-button:hover .e-sort,
.e-pivotview .e-group-rows .e-pivot-button:hover .e-dropdown-icon,
.e-pivotview .e-group-rows .e-pivot-button:hover .e-pv-filter,
.e-pivotview .e-group-rows .e-pivot-button:hover .e-pv-filtered,
.e-pivotview .e-group-rows .e-pivot-button:hover .e-remove,
.e-pivotview .e-group-rows .e-pivot-button:hover .e-edit {
  color: rgba(0, 0, 0, 0.87);
}
.e-pivotview .e-grouping-bar .e-pivot-button.e-btn-focused,
.e-pivotview .e-group-rows .e-pivot-button.e-btn-focused {
  background-color: #9e9e9e;
  color: #000;
}
.e-pivotview .e-grouping-bar .e-pivot-button.e-btn-focused .e-sort,
.e-pivotview .e-grouping-bar .e-pivot-button.e-btn-focused .e-dropdown-icon,
.e-pivotview .e-grouping-bar .e-pivot-button.e-btn-focused .e-pv-filter,
.e-pivotview .e-grouping-bar .e-pivot-button.e-btn-focused .e-pv-filtered,
.e-pivotview .e-grouping-bar .e-pivot-button.e-btn-focused .e-remove,
.e-pivotview .e-grouping-bar .e-pivot-button.e-btn-focused .e-edit,
.e-pivotview .e-group-rows .e-pivot-button.e-btn-focused .e-sort,
.e-pivotview .e-group-rows .e-pivot-button.e-btn-focused .e-dropdown-icon,
.e-pivotview .e-group-rows .e-pivot-button.e-btn-focused .e-pv-filter,
.e-pivotview .e-group-rows .e-pivot-button.e-btn-focused .e-pv-filtered,
.e-pivotview .e-group-rows .e-pivot-button.e-btn-focused .e-remove,
.e-pivotview .e-group-rows .e-pivot-button.e-btn-focused .e-edit {
  color: rgba(0, 0, 0, 0.87);
}
.e-pivotview .e-grouping-bar .e-pivot-button:focus, .e-pivotview .e-grouping-bar .e-pivot-button:active,
.e-pivotview .e-group-rows .e-pivot-button:focus,
.e-pivotview .e-group-rows .e-pivot-button:active {
  background-color: #9e9e9e;
  color: #000;
}
.e-pivotview .e-grouping-bar .e-pivot-button:focus .e-sort,
.e-pivotview .e-grouping-bar .e-pivot-button:focus .e-dropdown-icon,
.e-pivotview .e-grouping-bar .e-pivot-button:focus .e-pv-filter,
.e-pivotview .e-grouping-bar .e-pivot-button:focus .e-pv-filtered,
.e-pivotview .e-grouping-bar .e-pivot-button:focus .e-remove,
.e-pivotview .e-grouping-bar .e-pivot-button:focus .e-edit, .e-pivotview .e-grouping-bar .e-pivot-button:active .e-sort,
.e-pivotview .e-grouping-bar .e-pivot-button:active .e-dropdown-icon,
.e-pivotview .e-grouping-bar .e-pivot-button:active .e-pv-filter,
.e-pivotview .e-grouping-bar .e-pivot-button:active .e-pv-filtered,
.e-pivotview .e-grouping-bar .e-pivot-button:active .e-remove,
.e-pivotview .e-grouping-bar .e-pivot-button:active .e-edit,
.e-pivotview .e-group-rows .e-pivot-button:focus .e-sort,
.e-pivotview .e-group-rows .e-pivot-button:focus .e-dropdown-icon,
.e-pivotview .e-group-rows .e-pivot-button:focus .e-pv-filter,
.e-pivotview .e-group-rows .e-pivot-button:focus .e-pv-filtered,
.e-pivotview .e-group-rows .e-pivot-button:focus .e-remove,
.e-pivotview .e-group-rows .e-pivot-button:focus .e-edit,
.e-pivotview .e-group-rows .e-pivot-button:active .e-sort,
.e-pivotview .e-group-rows .e-pivot-button:active .e-dropdown-icon,
.e-pivotview .e-group-rows .e-pivot-button:active .e-pv-filter,
.e-pivotview .e-group-rows .e-pivot-button:active .e-pv-filtered,
.e-pivotview .e-group-rows .e-pivot-button:active .e-remove,
.e-pivotview .e-group-rows .e-pivot-button:active .e-edit {
  color: rgba(0, 0, 0, 0.87);
}
.e-pivotview .e-grouping-bar .e-pivot-button .e-pvt-btn-content,
.e-pivotview .e-group-rows .e-pivot-button .e-pvt-btn-content {
  background: transparent;
  cursor: move;
  -ms-flex: auto;
      flex: auto;
  font-size: 13px;
  margin-right: 4px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
}
.e-pivotview .e-grouping-bar .e-pivot-button .e-pvt-btn-content.e-disable-drag,
.e-pivotview .e-group-rows .e-pivot-button .e-pvt-btn-content.e-disable-drag {
  cursor: auto;
}
.e-pivotview .e-grouping-bar .e-pivot-button .e-drag,
.e-pivotview .e-group-rows .e-pivot-button .e-drag {
  display: none;
}
.e-pivotview .e-grouping-bar .e-pivot-button .e-sort,
.e-pivotview .e-grouping-bar .e-pivot-button .e-dropdown-icon,
.e-pivotview .e-grouping-bar .e-pivot-button .e-pv-filter,
.e-pivotview .e-grouping-bar .e-pivot-button .e-pv-filtered,
.e-pivotview .e-grouping-bar .e-pivot-button .e-remove,
.e-pivotview .e-grouping-bar .e-pivot-button .e-edit,
.e-pivotview .e-group-rows .e-pivot-button .e-sort,
.e-pivotview .e-group-rows .e-pivot-button .e-dropdown-icon,
.e-pivotview .e-group-rows .e-pivot-button .e-pv-filter,
.e-pivotview .e-group-rows .e-pivot-button .e-pv-filtered,
.e-pivotview .e-group-rows .e-pivot-button .e-remove,
.e-pivotview .e-group-rows .e-pivot-button .e-edit {
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  margin-left: 4px;
  width: 18px;
}
.e-pivotview .e-grouping-bar .e-pivot-button .e-sort:hover,
.e-pivotview .e-grouping-bar .e-pivot-button .e-dropdown-icon:hover,
.e-pivotview .e-grouping-bar .e-pivot-button .e-pv-filter:hover,
.e-pivotview .e-grouping-bar .e-pivot-button .e-pv-filtered:hover,
.e-pivotview .e-grouping-bar .e-pivot-button .e-remove:hover,
.e-pivotview .e-grouping-bar .e-pivot-button .e-edit:hover,
.e-pivotview .e-group-rows .e-pivot-button .e-sort:hover,
.e-pivotview .e-group-rows .e-pivot-button .e-dropdown-icon:hover,
.e-pivotview .e-group-rows .e-pivot-button .e-pv-filter:hover,
.e-pivotview .e-group-rows .e-pivot-button .e-pv-filtered:hover,
.e-pivotview .e-group-rows .e-pivot-button .e-remove:hover,
.e-pivotview .e-group-rows .e-pivot-button .e-edit:hover {
  color: rgba(0, 0, 0, 0.87);
}
.e-pivotview .e-grouping-bar .e-pivot-button .e-sort.e-disable,
.e-pivotview .e-grouping-bar .e-pivot-button .e-dropdown-icon.e-disable,
.e-pivotview .e-grouping-bar .e-pivot-button .e-pv-filter.e-disable,
.e-pivotview .e-grouping-bar .e-pivot-button .e-pv-filtered.e-disable,
.e-pivotview .e-grouping-bar .e-pivot-button .e-remove.e-disable,
.e-pivotview .e-grouping-bar .e-pivot-button .e-edit.e-disable,
.e-pivotview .e-group-rows .e-pivot-button .e-sort.e-disable,
.e-pivotview .e-group-rows .e-pivot-button .e-dropdown-icon.e-disable,
.e-pivotview .e-group-rows .e-pivot-button .e-pv-filter.e-disable,
.e-pivotview .e-group-rows .e-pivot-button .e-pv-filtered.e-disable,
.e-pivotview .e-group-rows .e-pivot-button .e-remove.e-disable,
.e-pivotview .e-group-rows .e-pivot-button .e-edit.e-disable {
  display: none;
}
.e-pivotview .e-grouping-bar .e-pivot-button .e-sort,
.e-pivotview .e-group-rows .e-pivot-button .e-sort {
  height: 12px;
  transform: rotateX(0deg);
  transition: transform 200ms ease-in-out;
}
.e-pivotview .e-grouping-bar .e-pivot-button .e-sort.e-descend,
.e-pivotview .e-group-rows .e-pivot-button .e-sort.e-descend {
  transform: rotateX(180deg);
}
.e-pivotview .e-grouping-bar .e-pivot-button .e-dropdown-icon,
.e-pivotview .e-group-rows .e-pivot-button .e-dropdown-icon {
  margin-top: 1px;
}
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button {
  max-width: 230px;
}
.e-pivotview .e-group-filters,
.e-pivotview .e-group-columns {
  width: 100%;
}
.e-pivotview th.e-group-row .e-axis-row {
  display: inline-block;
  height: 100%;
  vertical-align: top;
  width: 100%;
}
.e-pivotview .e-group-row {
  padding: 0 !important;
}
.e-pivotview .e-group-row .e-group-rows {
  display: table;
  -ms-flex: none;
      flex: none;
  width: 100%;
}
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div {
  max-width: unset;
  width: 100%;
}
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-indent-div {
  pointer-events: none;
}
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button {
  width: 180px;
}
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button:hover {
  background-color: #bdbdbd;
}
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button:hover .e-sort,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button:hover .e-dropdown-icon,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button:hover .e-pv-filter,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button:hover .e-pv-filtered,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button:hover .e-remove,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button:hover .e-edit {
  color: rgba(0, 0, 0, 0.87) !important;
}
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button.e-btn-focused {
  background-color: #9e9e9e;
  color: #000;
}
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button.e-btn-focused .e-sort,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button.e-btn-focused .e-dropdown-icon,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button.e-btn-focused .e-pv-filter,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button.e-btn-focused .e-pv-filtered,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button.e-btn-focused .e-remove,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button.e-btn-focused .e-edit {
  color: rgba(0, 0, 0, 0.87) !important;
}
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button:focus, .e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button:active {
  background-color: #9e9e9e;
  color: #000;
}
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button:focus .e-sort,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button:focus .e-dropdown-icon,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button:focus .e-pv-filter,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button:focus .e-pv-filtered,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button:focus .e-remove,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button:focus .e-edit, .e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button:active .e-sort,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button:active .e-dropdown-icon,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button:active .e-pv-filter,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button:active .e-pv-filtered,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button:active .e-remove,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button:active .e-edit {
  color: rgba(0, 0, 0, 0.87) !important;
}
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-sort,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-remove,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-edit {
  color: rgba(0, 0, 0, 0.54) !important;
  font-size: 12px;
  margin-left: 4px !important;
  width: 18px;
}
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-sort:hover,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-dropdown-icon:hover,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-pv-filter:hover,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-pv-filtered:hover,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-remove:hover,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-edit:hover {
  color: rgba(0, 0, 0, 0.87);
}
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-sort.e-disable,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-dropdown-icon.e-disable,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-pv-filter.e-disable,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-pv-filtered.e-disable,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-remove.e-disable,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-edit.e-disable {
  display: none;
}
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-sort {
  height: 12px;
  width: 18px;
}
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-sort.e-descend {
  transform: rotateX(180deg);
}
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-dropdown-icon {
  margin-top: 1px;
}
.e-pivotview .e-axis-value {
  display: inline-block;
  height: 100%;
}
.e-pivotview .e-axis-value .e-group-values {
  height: 100%;
  padding-bottom: 0;
}
.e-pivotview .e-axis-value .e-group-chart-values {
  display: inline-block;
  height: 28px;
}
.e-pivotview .e-axis-value .e-group-chart-values-drodown {
  font-size: 13px;
  height: 25px;
}
.e-pivotview .e-axis-column .e-group-chart-columns-dropdown {
  font-size: 13px;
  height: 25px;
  margin: 0;
}
.e-pivotview .e-axis-column .e-group-chart-columns-dropdown .e-input-group-icon {
  min-height: initial;
}
.e-pivotview .e-axis-column .e-group-chart-columns-dropdown input.e-input {
  padding-bottom: 7px;
}
.e-pivotview .e-chart-grouping-bar .e-axis-value {
  height: auto !important;
  padding-left: 6px;
  border-bottom: 1px solid #e0e0e0;
}
.e-pivotview .e-chart-grouping-bar .e-axis-value .e-group-chart-multi-values {
  padding-left: 2px;
}
.e-pivotview .e-chart-grouping-bar .e-axis-value .e-group-chart-values-drodown {
  margin: 0;
}
.e-pivotview .e-chart-grouping-bar .e-axis-value .e-group-chart-values-drodown .e-input-group-icon {
  min-height: initial;
}
.e-pivotview .e-chart-grouping-bar .e-axis-value .e-group-chart-values-drodown input.e-input {
  padding-bottom: 7px;
}
.e-pivotview .e-icons:not(.e-frame):not(.e-btn-icon):not(.e-sort):not(.e-dropdown-icon):not(.e-pv-filter):not(.e-pv-filtered):not(.e-remove):not(.e-edit):not(.e-drag):not(.e-selected-option-icon):not(.e-nav-arrow) {
  height: auto;
  width: auto;
}
.e-pivotview .e-collapse::before {
  font-size: 10px;
}
.e-pivotview .e-expand,
.e-pivotview .e-collapse {
  cursor: pointer;
  display: inline-block;
  height: 18px;
  text-align: center;
  width: 18px;
}
.e-pivotview th .e-expand,
.e-pivotview th .e-collapse {
  font-size: 10px !important;
  margin-left: 0;
}
.e-pivotview .e-expand::before {
  font-size: 10px;
}
.e-pivotview .e-pivot-pager {
  padding: 4px 12px;
}
.e-pivotview .e-pivot-pager.sf-pager .e-pivot-pager-info-container.e-pagercontainer {
  font-size: unset;
}
.e-pivotview .e-pivot-pager.e-pivot-pager-bottom {
  border-top: 0;
}
.e-pivotview .e-pivot-pager.e-pivot-pager-top {
  border-bottom: 0;
}
.e-pivotview .e-pivot-pager .e-pivotview-vseparator {
  height: auto;
  border-left: 1px solid #e4e4e4;
  margin: 8px 16px;
}
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
}
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv,
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  width: 50%;
  /* stylelint-enable */
}
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-icon-first,
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-icon-prev,
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-icon-next,
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-icon-last,
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-icon-first,
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-icon-prev,
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-icon-next,
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-icon-last {
  font-size: 12px;
  padding: 9px;
  /* stylelint-disable */
  margin: 0 !important;
}
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-text-div,
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-text-div {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
}
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-row-pagerString,
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-column-pagerString,
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-row-pagerString,
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-column-pagerString {
  color: #9e9e9e;
  font-size: 12px;
  overflow: hidden;
  text-align: initial;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 50%;
}
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-row-size,
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-column-size,
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-row-size,
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-column-size {
  color: #424242;
  font-size: 12px;
  overflow: hidden;
  text-align: end;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 50%;
}
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-text-div-1,
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-text-div-1 {
  -ms-flex: none;
      flex: none;
}
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-text-div-1 .e-pagercontainer,
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-text-div-1 .e-pagercontainer {
  margin-top: 5px;
  margin: 5px 0 0 0;
}
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-text-div-1 .e-pagercontainer.e-pivot-pager-info-container, .e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-text-div-1 .e-pagercontainer.e-pager-dropdown,
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-text-div-1 .e-pagercontainer.e-pivot-pager-info-container,
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-text-div-1 .e-pagercontainer.e-pager-dropdown {
  border-style: none;
  vertical-align: top;
}
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-text-div-1 .e-pager-dropdown,
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-text-div-1 .e-pager-dropdown {
  float: right;
}
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivot-row-of-string-maindiv,
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivot-column-of-string-maindiv,
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivot-row-of-string-maindiv,
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivot-column-of-string-maindiv {
  line-height: 2.4em;
}
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivot-row-of-string-maindiv .e-pivotview-row-pagerNumber,
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivot-row-of-string-maindiv .e-pivotview-column-pagerNumber,
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivot-column-of-string-maindiv .e-pivotview-row-pagerNumber,
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivot-column-of-string-maindiv .e-pivotview-column-pagerNumber,
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivot-row-of-string-maindiv .e-pivotview-row-pagerNumber,
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivot-row-of-string-maindiv .e-pivotview-column-pagerNumber,
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivot-column-of-string-maindiv .e-pivotview-row-pagerNumber,
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivot-column-of-string-maindiv .e-pivotview-column-pagerNumber {
  font-weight: 400;
}
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivot-row-pager-settings,
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivot-column-pager-settings,
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivot-row-pager-settings,
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivot-column-pager-settings {
  -ms-flex: none;
      flex: none;
}
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivot-row-pager-settings.e-pivot-pager-axis-container,
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivot-column-pager-settings.e-pivot-pager-axis-container,
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivot-row-pager-settings.e-pivot-pager-axis-container,
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivot-column-pager-settings.e-pivot-pager-axis-container {
  display: inline-table;
}
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivot-row-pager-settings .e-numeric,
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivot-column-pager-settings .e-numeric,
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivot-row-pager-settings .e-numeric,
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivot-column-pager-settings .e-numeric {
  margin: 0 8px 0 20px;
  vertical-align: top;
}
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-row-maindiv,
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-column-maindiv {
  -ms-flex-direction: row;
      flex-direction: row;
  width: 100%;
}
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-row-maindiv .e-pagercontainer,
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-column-maindiv .e-pagercontainer {
  margin-top: 0;
}
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-row-maindiv .e-pagercontainer.e-pivot-pager-info-container, .e-pivotview .e-pivot-pager .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-row-maindiv .e-pagercontainer.e-pager-dropdown,
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-column-maindiv .e-pagercontainer.e-pivot-pager-info-container,
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-column-maindiv .e-pagercontainer.e-pager-dropdown {
  border-style: none;
}
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-row-maindiv .e-pivotview-row-pagerString,
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-row-maindiv .e-pivotview-column-pagerString,
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-column-maindiv .e-pivotview-row-pagerString,
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-column-maindiv .e-pivotview-column-pagerString {
  -ms-flex: auto;
      flex: auto;
  font-size: 14px;
  line-height: 2.4em;
  text-align: center;
}
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-row-maindiv .e-pivotview-text-div-1 .e-pivotview-row-size,
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-row-maindiv .e-pivotview-text-div-1 .e-pivotview-column-size,
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-column-maindiv .e-pivotview-text-div-1 .e-pivotview-row-size,
.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-column-maindiv .e-pivotview-text-div-1 .e-pivotview-column-size {
  font-size: 14px;
  line-height: 2.4em;
  padding: 0 14px 0 0;
  width: inherit;
}
.e-pivotview .e-pivot-pager.e-compact-view .e-pivotview-vseparator {
  margin-top: 9px;
}
.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv,
.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv {
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-direction: row;
      flex-direction: row;
}
.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-text-div,
.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-text-div-1,
.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-text-div,
.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-text-div-1 {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  width: 50%;
}
.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-text-div .e-pagercontainer,
.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-text-div-1 .e-pagercontainer,
.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-text-div .e-pagercontainer,
.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-text-div-1 .e-pagercontainer {
  margin-top: 0;
  margin-top: 6px;
}
.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-text-div .e-pivotview-row-size,
.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-text-div .e-pivotview-column-size,
.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-text-div-1 .e-pivotview-row-size,
.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-text-div-1 .e-pivotview-column-size,
.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-text-div .e-pivotview-row-size,
.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-text-div .e-pivotview-column-size,
.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-text-div-1 .e-pivotview-row-size,
.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-text-div-1 .e-pivotview-column-size {
  -ms-flex: auto;
      flex: auto;
  font-size: 14px;
  line-height: 2.4em;
  padding: 0 14px 0 0;
  text-align: end;
  width: inherit;
}
.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-text-div .e-pivotview-row-pagerString,
.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-text-div .e-pivotview-column-pagerString,
.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-text-div-1 .e-pivotview-row-pagerString,
.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-text-div-1 .e-pivotview-column-pagerString,
.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-text-div .e-pivotview-row-pagerString,
.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-text-div .e-pivotview-column-pagerString,
.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-text-div-1 .e-pivotview-row-pagerString,
.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-text-div-1 .e-pivotview-column-pagerString {
  font-size: 14px;
  line-height: 2.4em;
  min-width: 50px;
  text-align: initial;
  width: inherit;
}
.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-text-div .e-pivot-row-pager-settings,
.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-text-div .e-pivot-column-pager-settings,
.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-text-div-1 .e-pivot-row-pager-settings,
.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-text-div-1 .e-pivot-column-pager-settings,
.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-text-div .e-pivot-row-pager-settings,
.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-text-div .e-pivot-column-pager-settings,
.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-text-div-1 .e-pivot-row-pager-settings,
.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-text-div-1 .e-pivot-column-pager-settings {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
}
.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-text-div-1 .e-pagercontainer,
.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-text-div-1 .e-pagercontainer {
  margin-bottom: 0;
}
.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-row-maindiv,
.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-column-maindiv {
  width: 100%;
}
.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-row-maindiv .e-pagercontainer,
.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-column-maindiv .e-pagercontainer {
  margin-top: 0;
}
.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-row-maindiv .e-pivotview-row-pagerString,
.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-row-maindiv .e-pivotview-column-pagerString,
.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-column-maindiv .e-pivotview-row-pagerString,
.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-column-maindiv .e-pivotview-column-pagerString {
  text-align: center;
  width: 100%;
}
.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-row-maindiv .e-pivotview-text-div-1,
.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-column-maindiv .e-pivotview-text-div-1 {
  width: auto;
}
.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv:not(.e-pivot-pager-single-maindiv) .e-pivot-pager-row-maindiv.e-page-size-disable .e-pivotview-text-div,
.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv:not(.e-pivot-pager-single-maindiv) .e-pivot-pager-column-maindiv.e-page-size-disable .e-pivotview-text-div {
  width: 50%;
}
.e-pivotview .e-pivot-pager.e-compact-view.e-rtl .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-text-div .e-pivot-row-pager-settings,
.e-pivotview .e-pivot-pager.e-compact-view.e-rtl .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-text-div .e-pivot-column-pager-settings,
.e-pivotview .e-pivot-pager.e-compact-view.e-rtl .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-text-div-1 .e-pivot-row-pager-settings,
.e-pivotview .e-pivot-pager.e-compact-view.e-rtl .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-text-div-1 .e-pivot-column-pager-settings,
.e-pivotview .e-pivot-pager.e-compact-view.e-rtl .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-text-div .e-pivot-row-pager-settings,
.e-pivotview .e-pivot-pager.e-compact-view.e-rtl .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-text-div .e-pivot-column-pager-settings,
.e-pivotview .e-pivot-pager.e-compact-view.e-rtl .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-text-div-1 .e-pivot-row-pager-settings,
.e-pivotview .e-pivot-pager.e-compact-view.e-rtl .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-text-div-1 .e-pivot-column-pager-settings {
  padding: 0 14px 0 0;
}
.e-pivotview .e-pivot-pager.e-compact-view.e-rtl .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-text-div .e-pivotview-row-size,
.e-pivotview .e-pivot-pager.e-compact-view.e-rtl .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-text-div .e-pivotview-column-size,
.e-pivotview .e-pivot-pager.e-compact-view.e-rtl .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-text-div-1 .e-pivotview-row-size,
.e-pivotview .e-pivot-pager.e-compact-view.e-rtl .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-text-div-1 .e-pivotview-column-size,
.e-pivotview .e-pivot-pager.e-compact-view.e-rtl .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-text-div .e-pivotview-row-size,
.e-pivotview .e-pivot-pager.e-compact-view.e-rtl .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-text-div .e-pivotview-column-size,
.e-pivotview .e-pivot-pager.e-compact-view.e-rtl .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-text-div-1 .e-pivotview-row-size,
.e-pivotview .e-pivot-pager.e-compact-view.e-rtl .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-text-div-1 .e-pivotview-column-size {
  padding: 0 0 0 14px;
}
.e-pivotview .e-pivot-pager.e-device {
  /* stylelint-disable */
  /* stylelint-enable */
}
.e-pivotview .e-pivot-pager.e-device .e-pivotview-hseparator {
  border-top: 1px solid #e4e4e4;
  margin: 8px 12px;
  width: auto;
}
.e-pivotview .e-pivot-pager.e-device .e-pivot-pager-maindiv {
  -ms-flex-direction: column;
      flex-direction: column;
}
.e-pivotview .e-pivot-pager.e-device .e-pivot-pager-maindiv .e-pagercontainer {
  display: inline-block;
}
.e-pivotview .e-pivot-pager.e-device .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv,
.e-pivotview .e-pivot-pager.e-device .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv {
  width: 100%;
}
.e-pivotview .e-pivot-pager.e-device .e-pivot-pager-maindiv:not(.e-pivot-pager-single-maindiv) .e-pagercontainer {
  margin-top: 6px;
}
@media (max-width: 400px) {
  .e-pivotview .e-pivot-pager.e-device .e-pivotview-row-size,
  .e-pivotview .e-pivot-pager.e-device .e-pivotview-column-size,
  .e-pivotview .e-pivot-pager.e-device .e-pivotview-row-pagerString,
  .e-pivotview .e-pivot-pager.e-device .e-pivotview-column-pagerString {
    min-width: 10px !important;
  }
}
.e-pivotview .e-pivot-pager.e-rtl .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivot-row-pager-settings .e-numeric,
.e-pivotview .e-pivot-pager.e-rtl .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivot-column-pager-settings .e-numeric,
.e-pivotview .e-pivot-pager.e-rtl .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivot-row-pager-settings .e-numeric,
.e-pivotview .e-pivot-pager.e-rtl .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivot-column-pager-settings .e-numeric {
  margin: 0 20px 0 8px;
}
.e-pivotview .e-pivot-pager.e-rtl .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pager-dropdown,
.e-pivotview .e-pivot-pager.e-rtl .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pager-dropdown {
  float: left;
}
.e-pivotview .e-pivot-pager.e-rtl .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-row-maindiv .e-pivotview-text-div-1 .e-pivotview-row-size,
.e-pivotview .e-pivot-pager.e-rtl .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-row-maindiv .e-pivotview-text-div-1 .e-pivotview-column-size,
.e-pivotview .e-pivot-pager.e-rtl .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-column-maindiv .e-pivotview-text-div-1 .e-pivotview-row-size,
.e-pivotview .e-pivot-pager.e-rtl .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-column-maindiv .e-pivotview-text-div-1 .e-pivotview-column-size {
  padding: 0 0 0 14px;
}
@media (max-width: 769px) {
  .e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pagercontainer {
    display: inline-block;
  }
}
.e-pivotview.e-rtl {
  /* stylelint-disable */
  /* stylelint-enable */
  /* stylelint-disable */
  /* stylelint-enable */
}
.e-pivotview.e-rtl .e-expand::before,
.e-pivotview.e-rtl .e-collapse::before {
  margin-left: 0;
}
.e-pivotview.e-rtl .e-grid .e-rowsheader {
  padding-right: 8px !important;
}
.e-pivotview.e-rtl .e-grid .e-columnsheader {
  padding-right: 8px !important;
}
.e-pivotview.e-rtl .e-grid .e-headercelldiv,
.e-pivotview.e-rtl .e-grid .e-columnsheader {
  padding-right: 8px !important;
}
.e-pivotview.e-rtl .e-frozenheader > .e-table,
.e-pivotview.e-rtl .e-frozencontent > .e-table {
  border-left-width: 1px;
  border-right: 0;
}
.e-pivotview.e-rtl .e-leftfreeze.e-freezeleftborder {
  border-left-width: 1px;
  border-right: 0;
}
.e-pivotview.e-rtl .e-rowsheader .e-cellvalue {
  margin-left: 0;
  margin-right: 7px;
}
.e-pivotview.e-rtl .e-valuescontent {
  text-align: left;
}
.e-pivotview.e-rtl .e-group-values,
.e-pivotview.e-rtl .e-group-columns,
.e-pivotview.e-rtl .e-group-filters,
.e-pivotview.e-rtl .e-group-all-fields {
  padding-left: 0;
  padding-right: 8px;
}
.e-pivotview.e-rtl .e-group-rows .e-pvt-btn-div,
.e-pivotview.e-rtl .e-grouping-bar .e-pvt-btn-div {
  padding-left: 8px;
  padding-right: 0;
}
.e-pivotview.e-rtl .e-group-rows .e-pvt-btn-div .e-pivot-button .e-pvt-btn-content,
.e-pivotview.e-rtl .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-pvt-btn-content {
  margin-left: 4px;
  text-align: right;
}
.e-pivotview.e-rtl .e-group-rows .e-pvt-btn-div .e-pivot-button .e-sort,
.e-pivotview.e-rtl .e-group-rows .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-pivotview.e-rtl .e-group-rows .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-pivotview.e-rtl .e-group-rows .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-pivotview.e-rtl .e-group-rows .e-pvt-btn-div .e-pivot-button .e-remove,
.e-pivotview.e-rtl .e-group-rows .e-pvt-btn-div .e-pivot-button .e-edit,
.e-pivotview.e-rtl .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-sort,
.e-pivotview.e-rtl .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-pivotview.e-rtl .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-pivotview.e-rtl .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-pivotview.e-rtl .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-remove,
.e-pivotview.e-rtl .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-edit {
  padding-left: 0;
  padding-right: 4px;
}
.e-pivotview.e-rtl .e-grouping-bar .e-left-axis-fields {
  border-left-width: 0;
  border-right-width: 1px;
}
.e-pivotview.e-rtl .e-grouping-bar .e-right-axis-fields {
  border-left-width: 1px;
}
.e-pivotview.e-rtl .e-group-row .e-group-rows {
  padding-left: 0;
  padding-right: 8px;
}
.e-pivotview.e-rtl .e-group-row .e-group-rows .e-pvt-btn-div {
  /* stylelint-disable */
  padding-left: 8px;
  padding-right: 0;
  /* stylelint-enable */
}
.e-pivotview.e-rtl .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-pvt-btn-content {
  margin-left: 4px;
  margin-right: 0;
  text-align: right;
}
.e-pivotview.e-rtl .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-sort,
.e-pivotview.e-rtl .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-pivotview.e-rtl .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-pivotview.e-rtl .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-pivotview.e-rtl .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-remove,
.e-pivotview.e-rtl .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-edit {
  padding-left: 0;
  padding-right: 7px;
}
.e-pivotview.e-rtl .e-stackedheadertext.e-cellvalue,
.e-pivotview.e-rtl .e-stackedheadercelldiv.e-cellvalue {
  padding-right: 12px !important;
}
.e-pivotview.e-rtl .e-sortfilterdiv {
  float: left;
}
.e-pivotview.e-rtl th.e-headercell:first-child:not(.e-firstcell):not(.e-headercell.e-frozen-left-border) {
  border-left-width: 1px !important;
}
.e-pivotview.e-device .e-grouping-bar {
  /* stylelint-disable */
}
.e-pivotview.e-device .e-grouping-bar .e-pivot-button {
  line-height: 1em !important;
}
.e-pivotview.e-device .e-grouping-bar .e-group-all-fields .e-pvt-btn-div .e-pivot-button {
  max-width: 155px;
}
.e-pivotview.e-device .e-grouping-bar .e-group-filters .e-pvt-btn-div .e-pivot-button,
.e-pivotview.e-device .e-grouping-bar .e-group-values .e-pvt-btn-div .e-pivot-button {
  max-width: 155px;
}
.e-pivotview.e-device .e-grouping-bar .e-group-columns .e-pvt-btn-div .e-pivot-button {
  max-width: 200px;
}
.e-pivotview.e-device .e-drillthrough-dialog {
  width: 100% !important;
}
.e-pivotview.e-device .e-group-row .e-group-rows .e-pvt-btn-div .e-indent-div {
  width: 0 !important;
}
.e-pivotview.e-device .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button {
  width: 155px;
}
.e-pivotview.e-device .e-scrollbar .e-movablescrollbar {
  min-height: 1px !important;
  visibility: hidden;
}
.e-pivotview .e-component-disable {
  display: none !important;
}
.e-pivotview .e-pivotchart.e-chart {
  overflow: visible !important;
}

.e-pivottooltipwrap,
.e-pivottooltip-container {
  opacity: 1;
}

.e-drillthrough-dialog {
  visibility: hidden;
}

.e-pivottooltip {
  border-radius: 4px;
  padding: 3px 4px;
  text-align: initial;
}

.e-pivottooltip p {
  color: #fff;
  display: inline-block;
  font-family: Roboto;
  font-size: 14px;
  line-height: 21px;
  margin: 0;
}

.e-pivottooltip p.e-tooltipcontent {
  margin-left: 5px;
}

.e-pivottooltiptemplate.e-tooltip-wrap {
  border: medium none transparent;
  max-width: none;
  min-width: 0;
  opacity: 1;
}

.e-pivottooltiptemplate .e-tip-content {
  overflow-x: visible;
  padding: unset;
  word-break: normal;
}

.e-rtl .e-pivottooltip p.e-tooltipcontent {
  margin-left: 0;
  margin-right: 5px;
}

.e-bigger .e-pivotview,
.e-bigger.e-pivotview {
  /* stylelint-disable */
  /* stylelint-disable */
}
.e-bigger .e-pivotview .e-chart-grouping-bar .e-axis-value .e-group-chart-values-drodown,
.e-bigger.e-pivotview .e-chart-grouping-bar .e-axis-value .e-group-chart-values-drodown {
  margin: 0;
}
.e-bigger .e-pivotview .e-chart-grouping-bar .e-axis-value .e-group-chart-values-drodown .e-input-group-icon,
.e-bigger.e-pivotview .e-chart-grouping-bar .e-axis-value .e-group-chart-values-drodown .e-input-group-icon {
  min-height: initial;
}
.e-bigger .e-pivotview .e-chart-grouping-bar .e-axis-value .e-group-chart-values-drodown input.e-input,
.e-bigger.e-pivotview .e-chart-grouping-bar .e-axis-value .e-group-chart-values-drodown input.e-input {
  padding-bottom: 10px;
  padding-top: 0;
}
.e-bigger .e-pivotview .e-chart-grouping-bar .e-axis-column .e-group-chart-columns-dropdown,
.e-bigger.e-pivotview .e-chart-grouping-bar .e-axis-column .e-group-chart-columns-dropdown {
  margin: 0;
}
.e-bigger .e-pivotview .e-chart-grouping-bar .e-axis-column .e-group-chart-columns-dropdown .e-input-group-icon,
.e-bigger.e-pivotview .e-chart-grouping-bar .e-axis-column .e-group-chart-columns-dropdown .e-input-group-icon {
  min-height: initial;
}
.e-bigger .e-pivotview .e-chart-grouping-bar .e-axis-column .e-group-chart-columns-dropdown input.e-input,
.e-bigger.e-pivotview .e-chart-grouping-bar .e-axis-column .e-group-chart-columns-dropdown input.e-input {
  padding-bottom: 10px;
  padding-top: 0;
}
.e-bigger .e-pivotview .e-grid .e-rowsheader,
.e-bigger.e-pivotview .e-grid .e-rowsheader {
  padding-left: 8px !important;
}
.e-bigger .e-pivotview .e-grid .e-headercelldiv,
.e-bigger.e-pivotview .e-grid .e-headercelldiv {
  padding-left: 8px !important;
}
.e-bigger .e-pivotview .e-grid .e-columnsheader,
.e-bigger.e-pivotview .e-grid .e-columnsheader {
  padding-left: 8px !important;
}
.e-bigger .e-pivotview .e-grid .e-gridheader .e-headercell .e-rhandler,
.e-bigger.e-pivotview .e-grid .e-gridheader .e-headercell .e-rhandler {
  height: 100% !important;
}
.e-bigger .e-pivotview.e-device .e-grid.e-grid-min-height .e-rowcell,
.e-bigger .e-pivotview.e-device .e-grid.e-grid-min-height .e-rowcell:first-child,
.e-bigger .e-pivotview.e-device .e-grid.e-grid-min-height .e-rowcell:last-child,
.e-bigger.e-pivotview.e-device .e-grid.e-grid-min-height .e-rowcell,
.e-bigger.e-pivotview.e-device .e-grid.e-grid-min-height .e-rowcell:first-child,
.e-bigger.e-pivotview.e-device .e-grid.e-grid-min-height .e-rowcell:last-child {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.e-bigger .e-pivotview .e-stackedheadercelldiv,
.e-bigger .e-pivotview .e-stackedheadertext,
.e-bigger .e-pivotview .e-headertext,
.e-bigger .e-pivotview .e-rowsheader .e-cellvalue,
.e-bigger.e-pivotview .e-stackedheadercelldiv,
.e-bigger.e-pivotview .e-stackedheadertext,
.e-bigger.e-pivotview .e-headertext,
.e-bigger.e-pivotview .e-rowsheader .e-cellvalue {
  font-size: 13px !important;
}
.e-bigger .e-pivotview .e-bigger .e-stackedheadercelldiv,
.e-bigger.e-pivotview .e-bigger .e-stackedheadercelldiv {
  padding-left: 16px !important;
}
.e-bigger .e-pivotview .e-grouping-bar,
.e-bigger.e-pivotview .e-grouping-bar {
  /* stylelint-enable */
}
.e-bigger .e-pivotview .e-grouping-bar .e-all-fields-axis,
.e-bigger.e-pivotview .e-grouping-bar .e-all-fields-axis {
  height: 36px !important;
  min-height: 36px !important;
}
.e-bigger .e-pivotview .e-grouping-bar .e-all-fields-axis .e-hscroll .e-scroll-nav,
.e-bigger .e-pivotview .e-grouping-bar .e-all-fields-axis .e-toolbar-items,
.e-bigger .e-pivotview .e-grouping-bar .e-all-fields-axis .e-toolbar-item,
.e-bigger.e-pivotview .e-grouping-bar .e-all-fields-axis .e-hscroll .e-scroll-nav,
.e-bigger.e-pivotview .e-grouping-bar .e-all-fields-axis .e-toolbar-items,
.e-bigger.e-pivotview .e-grouping-bar .e-all-fields-axis .e-toolbar-item {
  min-height: 36px !important;
}
.e-bigger .e-pivotview .e-grouping-bar .e-pvt-btn-div,
.e-bigger.e-pivotview .e-grouping-bar .e-pvt-btn-div {
  height: 30px;
}
.e-bigger .e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button,
.e-bigger.e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button {
  height: 30px;
  padding: 0 12px;
}
.e-bigger .e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-pvt-btn-content,
.e-bigger.e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-pvt-btn-content {
  font-size: 13px;
  margin-right: 8px;
}
.e-bigger .e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger .e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-bigger .e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-bigger .e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-bigger .e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-remove,
.e-bigger .e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-edit,
.e-bigger.e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger.e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-bigger.e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-bigger.e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-bigger.e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-remove,
.e-bigger.e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-edit {
  /* stylelint-disable */
  font-size: 14px !important;
  height: 14px !important;
  /* stylelint-enable */
  width: 20px;
}
.e-bigger .e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger.e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-sort {
  height: 14px;
}
.e-bigger .e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-bigger.e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-dropdown-icon {
  margin-top: 1px;
}
.e-bigger .e-pivotview .e-grouping-bar .e-group-filters,
.e-bigger .e-pivotview .e-grouping-bar .e-group-values,
.e-bigger .e-pivotview .e-grouping-bar .e-group-columns,
.e-bigger .e-pivotview .e-grouping-bar .e-group-rows,
.e-bigger .e-pivotview .e-grouping-bar .e-group-all-fields,
.e-bigger.e-pivotview .e-grouping-bar .e-group-filters,
.e-bigger.e-pivotview .e-grouping-bar .e-group-values,
.e-bigger.e-pivotview .e-grouping-bar .e-group-columns,
.e-bigger.e-pivotview .e-grouping-bar .e-group-rows,
.e-bigger.e-pivotview .e-grouping-bar .e-group-all-fields {
  height: 36px;
  min-height: 36px;
  padding-left: 10px;
}
.e-bigger .e-pivotview .e-grouping-bar .e-group-values,
.e-bigger.e-pivotview .e-grouping-bar .e-group-values {
  height: 100%;
}
.e-bigger .e-pivotview .e-grouping-bar .e-left-axis-fields .e-axis-nav-div .e-left-nav-container,
.e-bigger .e-pivotview .e-grouping-bar .e-left-axis-fields .e-axis-nav-div .e-right-nav-container,
.e-bigger .e-pivotview .e-grouping-bar .e-right-axis-fields .e-axis-nav-div .e-left-nav-container,
.e-bigger .e-pivotview .e-grouping-bar .e-right-axis-fields .e-axis-nav-div .e-right-nav-container,
.e-bigger.e-pivotview .e-grouping-bar .e-left-axis-fields .e-axis-nav-div .e-left-nav-container,
.e-bigger.e-pivotview .e-grouping-bar .e-left-axis-fields .e-axis-nav-div .e-right-nav-container,
.e-bigger.e-pivotview .e-grouping-bar .e-right-axis-fields .e-axis-nav-div .e-left-nav-container,
.e-bigger.e-pivotview .e-grouping-bar .e-right-axis-fields .e-axis-nav-div .e-right-nav-container {
  width: 24px;
}
.e-bigger .e-pivotview .e-group-rows,
.e-bigger.e-pivotview .e-group-rows {
  padding-left: 10px;
}
.e-bigger .e-pivotview .e-group-rows .e-pvt-btn-div,
.e-bigger.e-pivotview .e-group-rows .e-pvt-btn-div {
  height: 30px;
}
.e-bigger .e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button,
.e-bigger.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button {
  height: 30px;
  line-height: 1em;
  padding: 0 12px;
}
.e-bigger .e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-pvt-btn-content,
.e-bigger.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-pvt-btn-content {
  font-size: 13px;
  margin-right: 8px;
}
.e-bigger .e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger .e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-bigger .e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-bigger .e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-bigger .e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-remove,
.e-bigger .e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-edit,
.e-bigger.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-bigger.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-bigger.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-bigger.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-remove,
.e-bigger.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-edit {
  /* stylelint-disable */
  font-size: 14px !important;
  height: 14px !important;
  /* stylelint-enable */
  width: 20px;
}
.e-bigger .e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-sort {
  height: 14px;
}
.e-bigger .e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-bigger.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-dropdown-icon {
  margin-top: 1px;
}
.e-bigger .e-pivotview .e-stackedheadercelldiv,
.e-bigger .e-pivotview .e-stackedheadertext,
.e-bigger .e-pivotview .e-headertext,
.e-bigger .e-pivotview .e-rowsheader .e-cellvalue,
.e-bigger.e-pivotview .e-stackedheadercelldiv,
.e-bigger.e-pivotview .e-stackedheadertext,
.e-bigger.e-pivotview .e-headertext,
.e-bigger.e-pivotview .e-rowsheader .e-cellvalue {
  color: rgba(0, 0, 0, 0.6) !important;
  font-size: 13px;
  font-weight: 500 !important;
}
.e-bigger .e-pivotview .e-grid .e-columnsheader,
.e-bigger.e-pivotview .e-grid .e-columnsheader {
  padding-right: 16px !important;
}
.e-bigger .e-pivotview .e-rowcell,
.e-bigger.e-pivotview .e-rowcell {
  font-size: 13px !important;
}
.e-bigger .e-pivotview.e-rtl,
.e-bigger.e-pivotview.e-rtl {
  /* stylelint-enable */
}
.e-bigger .e-pivotview.e-rtl .e-grid .e-rowsheader,
.e-bigger.e-pivotview.e-rtl .e-grid .e-rowsheader {
  padding-right: 10px !important;
}
.e-bigger .e-pivotview.e-rtl .e-grid .e-columnsheader,
.e-bigger.e-pivotview.e-rtl .e-grid .e-columnsheader {
  padding-right: 16px !important;
}
.e-bigger .e-pivotview.e-rtl .e-grid .e-headercelldiv,
.e-bigger .e-pivotview.e-rtl .e-grid .e-columnsheader,
.e-bigger.e-pivotview.e-rtl .e-grid .e-headercelldiv,
.e-bigger.e-pivotview.e-rtl .e-grid .e-columnsheader {
  padding-right: 8px !important;
}
.e-bigger .e-pivotview.e-rtl .e-grouping-bar .e-group-rows,
.e-bigger .e-pivotview.e-rtl .e-grouping-bar .e-group-columns,
.e-bigger .e-pivotview.e-rtl .e-grouping-bar .e-group-values,
.e-bigger .e-pivotview.e-rtl .e-grouping-bar .e-group-filters,
.e-bigger .e-pivotview.e-rtl .e-grouping-bar .e-group-all-fields,
.e-bigger.e-pivotview.e-rtl .e-grouping-bar .e-group-rows,
.e-bigger.e-pivotview.e-rtl .e-grouping-bar .e-group-columns,
.e-bigger.e-pivotview.e-rtl .e-grouping-bar .e-group-values,
.e-bigger.e-pivotview.e-rtl .e-grouping-bar .e-group-filters,
.e-bigger.e-pivotview.e-rtl .e-grouping-bar .e-group-all-fields {
  padding-left: 0;
  padding-right: 10px;
}
.e-bigger .e-pivotview.e-rtl .e-group-row .e-group-rows,
.e-bigger.e-pivotview.e-rtl .e-group-row .e-group-rows {
  padding-left: 0;
  padding-right: 10px;
}
.e-bigger .e-pivotview.e-rtl .e-stackedheadertext.e-cellvalue,
.e-bigger .e-pivotview.e-rtl .e-stackedheadercelldiv.e-cellvalue,
.e-bigger.e-pivotview.e-rtl .e-stackedheadertext.e-cellvalue,
.e-bigger.e-pivotview.e-rtl .e-stackedheadercelldiv.e-cellvalue {
  padding-right: 16px !important;
}
.e-bigger .e-pivotview .e-pivot-pager,
.e-bigger .e-pivotview .e-bigger .e-pivotview, .e-bigger .e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger,
.e-bigger.e-pivotview .e-pivot-pager,
.e-bigger.e-pivotview .e-bigger .e-pivotview,
.e-bigger.e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger {
  padding: 4px 16px;
}
.e-bigger .e-pivotview .e-pivot-pager .e-pivotview-vseparator,
.e-bigger .e-pivotview .e-bigger .e-pivotview .e-pivotview-vseparator, .e-bigger .e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivotview-vseparator,
.e-bigger.e-pivotview .e-pivot-pager .e-pivotview-vseparator,
.e-bigger.e-pivotview .e-bigger .e-pivotview .e-pivotview-vseparator,
.e-bigger.e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivotview-vseparator {
  margin: 8px 20px;
}
.e-bigger .e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-icon-first,
.e-bigger .e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-icon-prev,
.e-bigger .e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-icon-next,
.e-bigger .e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-icon-last,
.e-bigger .e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-icon-first,
.e-bigger .e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-icon-prev,
.e-bigger .e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-icon-next,
.e-bigger .e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-icon-last,
.e-bigger .e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-icon-first,
.e-bigger .e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-icon-prev,
.e-bigger .e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-icon-next,
.e-bigger .e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-icon-last,
.e-bigger .e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-icon-first,
.e-bigger .e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-icon-prev,
.e-bigger .e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-icon-next,
.e-bigger .e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-icon-last, .e-bigger .e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-icon-first,
.e-bigger .e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-icon-prev,
.e-bigger .e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-icon-next,
.e-bigger .e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-icon-last,
.e-bigger .e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-icon-first,
.e-bigger .e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-icon-prev,
.e-bigger .e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-icon-next,
.e-bigger .e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-icon-last,
.e-bigger.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-icon-first,
.e-bigger.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-icon-prev,
.e-bigger.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-icon-next,
.e-bigger.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-icon-last,
.e-bigger.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-icon-first,
.e-bigger.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-icon-prev,
.e-bigger.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-icon-next,
.e-bigger.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-icon-last,
.e-bigger.e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-icon-first,
.e-bigger.e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-icon-prev,
.e-bigger.e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-icon-next,
.e-bigger.e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-icon-last,
.e-bigger.e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-icon-first,
.e-bigger.e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-icon-prev,
.e-bigger.e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-icon-next,
.e-bigger.e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-icon-last,
.e-bigger.e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-icon-first,
.e-bigger.e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-icon-prev,
.e-bigger.e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-icon-next,
.e-bigger.e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-icon-last,
.e-bigger.e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-icon-first,
.e-bigger.e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-icon-prev,
.e-bigger.e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-icon-next,
.e-bigger.e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-icon-last {
  font-size: 14px;
  padding: 9px;
}
.e-bigger .e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivot-row-of-string-maindiv,
.e-bigger .e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivot-column-of-string-maindiv,
.e-bigger .e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivot-row-of-string-maindiv,
.e-bigger .e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivot-column-of-string-maindiv,
.e-bigger .e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivot-row-of-string-maindiv,
.e-bigger .e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivot-column-of-string-maindiv,
.e-bigger .e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivot-row-of-string-maindiv,
.e-bigger .e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivot-column-of-string-maindiv, .e-bigger .e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivot-row-of-string-maindiv,
.e-bigger .e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivot-column-of-string-maindiv,
.e-bigger .e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivot-row-of-string-maindiv,
.e-bigger .e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivot-column-of-string-maindiv,
.e-bigger.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivot-row-of-string-maindiv,
.e-bigger.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivot-column-of-string-maindiv,
.e-bigger.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivot-row-of-string-maindiv,
.e-bigger.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivot-column-of-string-maindiv,
.e-bigger.e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivot-row-of-string-maindiv,
.e-bigger.e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivot-column-of-string-maindiv,
.e-bigger.e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivot-row-of-string-maindiv,
.e-bigger.e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivot-column-of-string-maindiv,
.e-bigger.e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivot-row-of-string-maindiv,
.e-bigger.e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivot-column-of-string-maindiv,
.e-bigger.e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivot-row-of-string-maindiv,
.e-bigger.e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivot-column-of-string-maindiv {
  line-height: 2.8em;
}
.e-bigger .e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-row-pagerString,
.e-bigger .e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-column-pagerString,
.e-bigger .e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-row-size,
.e-bigger .e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-column-size,
.e-bigger .e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-row-pagerString,
.e-bigger .e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-column-pagerString,
.e-bigger .e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-row-size,
.e-bigger .e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-column-size,
.e-bigger .e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-row-pagerString,
.e-bigger .e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-column-pagerString,
.e-bigger .e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-row-size,
.e-bigger .e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-column-size,
.e-bigger .e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-row-pagerString,
.e-bigger .e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-column-pagerString,
.e-bigger .e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-row-size,
.e-bigger .e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-column-size, .e-bigger .e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-row-pagerString,
.e-bigger .e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-column-pagerString,
.e-bigger .e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-row-size,
.e-bigger .e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-column-size,
.e-bigger .e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-row-pagerString,
.e-bigger .e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-column-pagerString,
.e-bigger .e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-row-size,
.e-bigger .e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-column-size,
.e-bigger.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-row-pagerString,
.e-bigger.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-column-pagerString,
.e-bigger.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-row-size,
.e-bigger.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-column-size,
.e-bigger.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-row-pagerString,
.e-bigger.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-column-pagerString,
.e-bigger.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-row-size,
.e-bigger.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-column-size,
.e-bigger.e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-row-pagerString,
.e-bigger.e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-column-pagerString,
.e-bigger.e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-row-size,
.e-bigger.e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-column-size,
.e-bigger.e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-row-pagerString,
.e-bigger.e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-column-pagerString,
.e-bigger.e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-row-size,
.e-bigger.e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-column-size,
.e-bigger.e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-row-pagerString,
.e-bigger.e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-column-pagerString,
.e-bigger.e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-row-size,
.e-bigger.e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-column-size,
.e-bigger.e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-row-pagerString,
.e-bigger.e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-column-pagerString,
.e-bigger.e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-row-size,
.e-bigger.e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-column-size {
  font-size: 14px;
}
.e-bigger .e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-text-div-1 .e-pagercontainer,
.e-bigger .e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-text-div-1 .e-pagercontainer,
.e-bigger .e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-text-div-1 .e-pagercontainer,
.e-bigger .e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-text-div-1 .e-pagercontainer, .e-bigger .e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-text-div-1 .e-pagercontainer,
.e-bigger .e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-text-div-1 .e-pagercontainer,
.e-bigger.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-text-div-1 .e-pagercontainer,
.e-bigger.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-text-div-1 .e-pagercontainer,
.e-bigger.e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-text-div-1 .e-pagercontainer,
.e-bigger.e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-text-div-1 .e-pagercontainer,
.e-bigger.e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-text-div-1 .e-pagercontainer,
.e-bigger.e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-text-div-1 .e-pagercontainer {
  margin-top: 6px;
}
.e-bigger .e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivot-row-pager-settings .e-numeric,
.e-bigger .e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivot-column-pager-settings .e-numeric,
.e-bigger .e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivot-row-pager-settings .e-numeric,
.e-bigger .e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivot-column-pager-settings .e-numeric,
.e-bigger .e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivot-row-pager-settings .e-numeric,
.e-bigger .e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivot-column-pager-settings .e-numeric,
.e-bigger .e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivot-row-pager-settings .e-numeric,
.e-bigger .e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivot-column-pager-settings .e-numeric, .e-bigger .e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivot-row-pager-settings .e-numeric,
.e-bigger .e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivot-column-pager-settings .e-numeric,
.e-bigger .e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivot-row-pager-settings .e-numeric,
.e-bigger .e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivot-column-pager-settings .e-numeric,
.e-bigger.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivot-row-pager-settings .e-numeric,
.e-bigger.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivot-column-pager-settings .e-numeric,
.e-bigger.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivot-row-pager-settings .e-numeric,
.e-bigger.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivot-column-pager-settings .e-numeric,
.e-bigger.e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivot-row-pager-settings .e-numeric,
.e-bigger.e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivot-column-pager-settings .e-numeric,
.e-bigger.e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivot-row-pager-settings .e-numeric,
.e-bigger.e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivot-column-pager-settings .e-numeric,
.e-bigger.e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivot-row-pager-settings .e-numeric,
.e-bigger.e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivot-column-pager-settings .e-numeric,
.e-bigger.e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivot-row-pager-settings .e-numeric,
.e-bigger.e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivot-column-pager-settings .e-numeric {
  margin: -1px 12px 0 20px;
}
.e-bigger .e-pivotview .e-pivot-pager .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-row-maindiv .e-pagercontainer,
.e-bigger .e-pivotview .e-pivot-pager .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-column-maindiv .e-pagercontainer,
.e-bigger .e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-row-maindiv .e-pagercontainer,
.e-bigger .e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-column-maindiv .e-pagercontainer, .e-bigger .e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-row-maindiv .e-pagercontainer,
.e-bigger .e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-column-maindiv .e-pagercontainer,
.e-bigger.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-row-maindiv .e-pagercontainer,
.e-bigger.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-column-maindiv .e-pagercontainer,
.e-bigger.e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-row-maindiv .e-pagercontainer,
.e-bigger.e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-column-maindiv .e-pagercontainer,
.e-bigger.e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-row-maindiv .e-pagercontainer,
.e-bigger.e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-column-maindiv .e-pagercontainer {
  margin-top: 0;
}
.e-bigger .e-pivotview .e-pivot-pager .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-row-maindiv .e-pivotview-row-pagerString,
.e-bigger .e-pivotview .e-pivot-pager .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-row-maindiv .e-pivotview-column-pagerString,
.e-bigger .e-pivotview .e-pivot-pager .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-row-maindiv .e-pivotview-row-size,
.e-bigger .e-pivotview .e-pivot-pager .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-row-maindiv .e-pivotview-column-size,
.e-bigger .e-pivotview .e-pivot-pager .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-column-maindiv .e-pivotview-row-pagerString,
.e-bigger .e-pivotview .e-pivot-pager .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-column-maindiv .e-pivotview-column-pagerString,
.e-bigger .e-pivotview .e-pivot-pager .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-column-maindiv .e-pivotview-row-size,
.e-bigger .e-pivotview .e-pivot-pager .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-column-maindiv .e-pivotview-column-size,
.e-bigger .e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-row-maindiv .e-pivotview-row-pagerString,
.e-bigger .e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-row-maindiv .e-pivotview-column-pagerString,
.e-bigger .e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-row-maindiv .e-pivotview-row-size,
.e-bigger .e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-row-maindiv .e-pivotview-column-size,
.e-bigger .e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-column-maindiv .e-pivotview-row-pagerString,
.e-bigger .e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-column-maindiv .e-pivotview-column-pagerString,
.e-bigger .e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-column-maindiv .e-pivotview-row-size,
.e-bigger .e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-column-maindiv .e-pivotview-column-size, .e-bigger .e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-row-maindiv .e-pivotview-row-pagerString,
.e-bigger .e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-row-maindiv .e-pivotview-column-pagerString,
.e-bigger .e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-row-maindiv .e-pivotview-row-size,
.e-bigger .e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-row-maindiv .e-pivotview-column-size,
.e-bigger .e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-column-maindiv .e-pivotview-row-pagerString,
.e-bigger .e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-column-maindiv .e-pivotview-column-pagerString,
.e-bigger .e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-column-maindiv .e-pivotview-row-size,
.e-bigger .e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-column-maindiv .e-pivotview-column-size,
.e-bigger.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-row-maindiv .e-pivotview-row-pagerString,
.e-bigger.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-row-maindiv .e-pivotview-column-pagerString,
.e-bigger.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-row-maindiv .e-pivotview-row-size,
.e-bigger.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-row-maindiv .e-pivotview-column-size,
.e-bigger.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-column-maindiv .e-pivotview-row-pagerString,
.e-bigger.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-column-maindiv .e-pivotview-column-pagerString,
.e-bigger.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-column-maindiv .e-pivotview-row-size,
.e-bigger.e-pivotview .e-pivot-pager .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-column-maindiv .e-pivotview-column-size,
.e-bigger.e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-row-maindiv .e-pivotview-row-pagerString,
.e-bigger.e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-row-maindiv .e-pivotview-column-pagerString,
.e-bigger.e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-row-maindiv .e-pivotview-row-size,
.e-bigger.e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-row-maindiv .e-pivotview-column-size,
.e-bigger.e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-column-maindiv .e-pivotview-row-pagerString,
.e-bigger.e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-column-maindiv .e-pivotview-column-pagerString,
.e-bigger.e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-column-maindiv .e-pivotview-row-size,
.e-bigger.e-pivotview .e-bigger .e-pivotview .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-column-maindiv .e-pivotview-column-size,
.e-bigger.e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-row-maindiv .e-pivotview-row-pagerString,
.e-bigger.e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-row-maindiv .e-pivotview-column-pagerString,
.e-bigger.e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-row-maindiv .e-pivotview-row-size,
.e-bigger.e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-row-maindiv .e-pivotview-column-size,
.e-bigger.e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-column-maindiv .e-pivotview-row-pagerString,
.e-bigger.e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-column-maindiv .e-pivotview-column-pagerString,
.e-bigger.e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-column-maindiv .e-pivotview-row-size,
.e-bigger.e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger .e-pivot-pager-maindiv.e-pivot-pager-single-maindiv .e-pivot-pager-column-maindiv .e-pivotview-column-size {
  font-size: 16px;
}
.e-bigger .e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pagercontainer,
.e-bigger .e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pagercontainer,
.e-bigger .e-pivotview .e-bigger .e-pivotview.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pagercontainer,
.e-bigger .e-pivotview .e-bigger .e-pivotview.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pagercontainer, .e-bigger .e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pagercontainer,
.e-bigger .e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pagercontainer,
.e-bigger.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pagercontainer,
.e-bigger.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pagercontainer,
.e-bigger.e-pivotview .e-bigger .e-pivotview.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pagercontainer,
.e-bigger.e-pivotview .e-bigger .e-pivotview.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pagercontainer,
.e-bigger.e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pagercontainer,
.e-bigger.e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pagercontainer {
  margin-top: 0;
  margin-top: 6px;
}
.e-bigger .e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-row-pagerString,
.e-bigger .e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-column-pagerString,
.e-bigger .e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-row-size,
.e-bigger .e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-column-size,
.e-bigger .e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-row-pagerString,
.e-bigger .e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-column-pagerString,
.e-bigger .e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-row-size,
.e-bigger .e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-column-size,
.e-bigger .e-pivotview .e-bigger .e-pivotview.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-row-pagerString,
.e-bigger .e-pivotview .e-bigger .e-pivotview.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-column-pagerString,
.e-bigger .e-pivotview .e-bigger .e-pivotview.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-row-size,
.e-bigger .e-pivotview .e-bigger .e-pivotview.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-column-size,
.e-bigger .e-pivotview .e-bigger .e-pivotview.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-row-pagerString,
.e-bigger .e-pivotview .e-bigger .e-pivotview.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-column-pagerString,
.e-bigger .e-pivotview .e-bigger .e-pivotview.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-row-size,
.e-bigger .e-pivotview .e-bigger .e-pivotview.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-column-size, .e-bigger .e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-row-pagerString,
.e-bigger .e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-column-pagerString,
.e-bigger .e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-row-size,
.e-bigger .e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-column-size,
.e-bigger .e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-row-pagerString,
.e-bigger .e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-column-pagerString,
.e-bigger .e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-row-size,
.e-bigger .e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-column-size,
.e-bigger.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-row-pagerString,
.e-bigger.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-column-pagerString,
.e-bigger.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-row-size,
.e-bigger.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-column-size,
.e-bigger.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-row-pagerString,
.e-bigger.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-column-pagerString,
.e-bigger.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-row-size,
.e-bigger.e-pivotview .e-pivot-pager.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-column-size,
.e-bigger.e-pivotview .e-bigger .e-pivotview.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-row-pagerString,
.e-bigger.e-pivotview .e-bigger .e-pivotview.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-column-pagerString,
.e-bigger.e-pivotview .e-bigger .e-pivotview.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-row-size,
.e-bigger.e-pivotview .e-bigger .e-pivotview.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-column-size,
.e-bigger.e-pivotview .e-bigger .e-pivotview.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-row-pagerString,
.e-bigger.e-pivotview .e-bigger .e-pivotview.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-column-pagerString,
.e-bigger.e-pivotview .e-bigger .e-pivotview.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-row-size,
.e-bigger.e-pivotview .e-bigger .e-pivotview.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-column-size,
.e-bigger.e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-row-pagerString,
.e-bigger.e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-column-pagerString,
.e-bigger.e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-row-size,
.e-bigger.e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivotview-column-size,
.e-bigger.e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-row-pagerString,
.e-bigger.e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-column-pagerString,
.e-bigger.e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-row-size,
.e-bigger.e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger.e-compact-view .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivotview-column-size {
  font-size: 16px;
}
.e-bigger .e-pivotview .e-pivot-pager.e-rtl .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivot-row-pager-settings .e-numeric,
.e-bigger .e-pivotview .e-pivot-pager.e-rtl .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivot-column-pager-settings .e-numeric,
.e-bigger .e-pivotview .e-pivot-pager.e-rtl .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivot-row-pager-settings .e-numeric,
.e-bigger .e-pivotview .e-pivot-pager.e-rtl .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivot-column-pager-settings .e-numeric,
.e-bigger .e-pivotview .e-bigger .e-pivotview.e-rtl .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivot-row-pager-settings .e-numeric,
.e-bigger .e-pivotview .e-bigger .e-pivotview.e-rtl .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivot-column-pager-settings .e-numeric,
.e-bigger .e-pivotview .e-bigger .e-pivotview.e-rtl .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivot-row-pager-settings .e-numeric,
.e-bigger .e-pivotview .e-bigger .e-pivotview.e-rtl .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivot-column-pager-settings .e-numeric, .e-bigger .e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger.e-rtl .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivot-row-pager-settings .e-numeric,
.e-bigger .e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger.e-rtl .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivot-column-pager-settings .e-numeric,
.e-bigger .e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger.e-rtl .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivot-row-pager-settings .e-numeric,
.e-bigger .e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger.e-rtl .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivot-column-pager-settings .e-numeric,
.e-bigger.e-pivotview .e-pivot-pager.e-rtl .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivot-row-pager-settings .e-numeric,
.e-bigger.e-pivotview .e-pivot-pager.e-rtl .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivot-column-pager-settings .e-numeric,
.e-bigger.e-pivotview .e-pivot-pager.e-rtl .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivot-row-pager-settings .e-numeric,
.e-bigger.e-pivotview .e-pivot-pager.e-rtl .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivot-column-pager-settings .e-numeric,
.e-bigger.e-pivotview .e-bigger .e-pivotview.e-rtl .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivot-row-pager-settings .e-numeric,
.e-bigger.e-pivotview .e-bigger .e-pivotview.e-rtl .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivot-column-pager-settings .e-numeric,
.e-bigger.e-pivotview .e-bigger .e-pivotview.e-rtl .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivot-row-pager-settings .e-numeric,
.e-bigger.e-pivotview .e-bigger .e-pivotview.e-rtl .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivot-column-pager-settings .e-numeric,
.e-bigger.e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger.e-rtl .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivot-row-pager-settings .e-numeric,
.e-bigger.e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger.e-rtl .e-pivot-pager-maindiv .e-pivot-pager-row-maindiv .e-pivot-column-pager-settings .e-numeric,
.e-bigger.e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger.e-rtl .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivot-row-pager-settings .e-numeric,
.e-bigger.e-pivotview .e-bigger.e-pivotview.e-pivot-pager.e-bigger.e-rtl .e-pivot-pager-maindiv .e-pivot-pager-column-maindiv .e-pivot-column-pager-settings .e-numeric {
  margin: -1px 12px 0 20px;
}

.e-content-placeholder.e-pivotview.e-placeholder-pivotview {
  background-size: 100% 200px;
}

/*! component's theme wise override definitions and variables */
/*! component's common definitions and variables */
/*! field-list component icons */
.e-pivotfieldlist .e-select-table::before {
  content: "\e434";
}

.e-pivot-formatting-dialog .e-format-delete-icon::before {
  content: "\ea7f";
}
.e-pivot-formatting-dialog .e-add-icon::before {
  content: "\e823";
}
.e-pivot-formatting-dialog .e-colorpicker-wrapper .e-format-back-color + .e-split-btn-wrapper .e-split-btn .e-selected-color::before,
.e-pivot-formatting-dialog .e-colorpicker-container .e-format-back-color + .e-split-btn-wrapper .e-split-btn .e-selected-color::before {
  content: "\e35c";
}
.e-pivot-formatting-dialog .e-colorpicker-wrapper .e-format-font-color + .e-split-btn-wrapper .e-split-btn .e-selected-color::before,
.e-pivot-formatting-dialog .e-colorpicker-container .e-format-font-color + .e-split-btn-wrapper .e-split-btn .e-selected-color::before {
  content: "\e35f";
}

.e-level-options .e-selected-level-icon::before {
  content: "\e614";
}

.e-pivot-calc-dialog-div {
  /* stylelint-disable */
  /* stylelint-enable */
}
.e-pivot-calc-dialog-div .e-drag::before {
  content: "\e330";
}
.e-pivot-calc-dialog-div .e-pivot-all-field-title-wrapper .e-info.e-icons::before,
.e-pivot-calc-dialog-div .e-pivot-all-field-title-container .e-info.e-icons::before {
  content: "\e607";
}
.e-pivot-calc-dialog-div .e-list-item .e-text-content .e-edited.e-icons::before {
  content: "\e7a3";
}
.e-pivot-calc-dialog-div .e-list-item .e-text-content .e-edit.e-icons::before {
  content: "\e891";
}
.e-pivot-calc-dialog-div .e-list-item .e-text-content .e-remove-report.e-icons::before {
  content: "\e706";
}
.e-pivot-calc-dialog-div .e-remove-report.e-icons::before {
  content: "\e706";
}
.e-pivot-calc-dialog-div .e-list-item .e-text-content .e-format.e-icons::before {
  content: "\e22f";
}
.e-pivot-calc-dialog-div .e-measureGroupCDB-icon::before {
  content: "\ea76" !important;
}
.e-pivot-calc-dialog-div .e-measure-icon::before {
  content: "\ea76" !important;
}
.e-pivot-calc-dialog-div .e-folderCDB-icon::before {
  content: "\e60b" !important;
}
.e-pivot-calc-dialog-div .e-folderCDB-open-icon::before {
  content: "\e65f" !important;
}
.e-pivot-calc-dialog-div .e-dimensionCDB-icon::before {
  content: "\e65b" !important;
}
.e-pivot-calc-dialog-div .e-kpiCDB-icon::before {
  content: "\e664" !important;
}
.e-pivot-calc-dialog-div .e-kpiGoal-icon::before {
  content: "\e664" !important;
}
.e-pivot-calc-dialog-div .e-kpiStatus-icon::before {
  content: "\e664" !important;
}
.e-pivot-calc-dialog-div .e-kpiTrend-icon::before {
  content: "\e664" !important;
}
.e-pivot-calc-dialog-div .e-kpiValue-icon::before {
  content: "\e664" !important;
}
.e-pivot-calc-dialog-div .e-namedSetCDB-icon::before {
  content: "\e65d" !important;
}
.e-pivot-calc-dialog-div .e-hierarchyCDB-icon::before {
  content: "\e663" !important;
}
.e-pivot-calc-dialog-div .e-attributeCDB-icon::before {
  content: "\e65c" !important;
}
.e-pivot-calc-dialog-div .e-hierarchy-level-0-icon::before {
  content: "\e65a" !important;
}
.e-pivot-calc-dialog-div .e-hierarchy-level-1-icon::before {
  content: "\e65a" !important;
}
.e-pivot-calc-dialog-div .e-hierarchy-level-2-icon::before {
  content: "\e662" !important;
}
.e-pivot-calc-dialog-div .e-hierarchy-level-3-icon::before {
  content: "\e65e" !important;
}
.e-pivot-calc-dialog-div .e-hierarchy-level-4-icon::before {
  content: "\e660" !important;
}
.e-pivot-calc-dialog-div .e-hierarchy-level-5-icon::before {
  content: "\e661" !important;
}
.e-pivot-calc-dialog-div .e-calcMemberGroupCDB::before {
  content: "\e729" !important;
}
.e-pivot-calc-dialog-div .e-calc-measure-icon::before {
  content: "\ea76" !important;
}
.e-pivot-calc-dialog-div .e-calc-dimension-icon::before {
  content: "\e65b" !important;
}
.e-pivot-calc-dialog-div .e-sort-none::before {
  content: "\e666" !important;
}
.e-pivot-calc-dialog-div .e-sort-ascend::before {
  content: "\e667" !important;
}
.e-pivot-calc-dialog-div .e-sort-descend::before {
  content: "\e665" !important;
}

.e-pivotfieldlist-wrapper,
.e-pivotfieldlist-container {
  /* stylelint-disable */
  /* stylelint-enable */
}
.e-pivotfieldlist-wrapper .e-field-list-back-icon::before,
.e-pivotfieldlist-container .e-field-list-back-icon::before {
  content: "\e977";
}
.e-pivotfieldlist-wrapper .e-sort::before,
.e-pivotfieldlist-container .e-sort::before {
  content: "\e840";
}
.e-pivotfieldlist-wrapper .e-pv-filter::before,
.e-pivotfieldlist-container .e-pv-filter::before {
  content: "\e909";
}
.e-pivotfieldlist-wrapper .e-pv-filtered::before,
.e-pivotfieldlist-container .e-pv-filtered::before {
  color: #e3165b;
  content: "\ebb3";
}
.e-pivotfieldlist-wrapper .e-drag::before,
.e-pivotfieldlist-container .e-drag::before {
  content: "\e330";
}
.e-pivotfieldlist-wrapper .e-add-icon::before,
.e-pivotfieldlist-container .e-add-icon::before {
  content: "\e823";
}
.e-pivotfieldlist-wrapper .e-remove::before,
.e-pivotfieldlist-container .e-remove::before {
  content: "\e208";
}
.e-pivotfieldlist-wrapper .e-axis-rows::before,
.e-pivotfieldlist-container .e-axis-rows::before {
  content: "\ea75";
}
.e-pivotfieldlist-wrapper .e-axis-columns::before,
.e-pivotfieldlist-container .e-axis-columns::before {
  content: "\ea74";
}
.e-pivotfieldlist-wrapper .e-axis-values::before,
.e-pivotfieldlist-container .e-axis-values::before {
  content: "\ea76";
}
.e-pivotfieldlist-wrapper .e-axis-filters::before,
.e-pivotfieldlist-container .e-axis-filters::before {
  content: "\e909";
}
.e-pivotfieldlist-wrapper .e-selected-option-icon::before,
.e-pivotfieldlist-container .e-selected-option-icon::before {
  content: "\ea84";
}
.e-pivotfieldlist-wrapper .e-clear-filter-icon::before,
.e-pivotfieldlist-container .e-clear-filter-icon::before {
  content: "\ea82";
}
.e-pivotfieldlist-wrapper .e-dropdown-icon::before,
.e-pivotfieldlist-container .e-dropdown-icon::before {
  content: "\e83d";
}
.e-pivotfieldlist-wrapper .e-measureGroupCDB-icon::before,
.e-pivotfieldlist-container .e-measureGroupCDB-icon::before {
  content: "\ea76" !important;
}
.e-pivotfieldlist-wrapper .e-measure-icon::before,
.e-pivotfieldlist-container .e-measure-icon::before {
  content: "\ea76" !important;
}
.e-pivotfieldlist-wrapper .e-folderCDB-icon::before,
.e-pivotfieldlist-container .e-folderCDB-icon::before {
  content: "\e60b" !important;
}
.e-pivotfieldlist-wrapper .e-folderCDB-open-icon::before,
.e-pivotfieldlist-container .e-folderCDB-open-icon::before {
  content: "\e65f" !important;
}
.e-pivotfieldlist-wrapper .e-dimensionCDB-icon::before,
.e-pivotfieldlist-container .e-dimensionCDB-icon::before {
  content: "\e65b" !important;
}
.e-pivotfieldlist-wrapper .e-kpiCDB-icon::before,
.e-pivotfieldlist-container .e-kpiCDB-icon::before {
  content: "\e664" !important;
}
.e-pivotfieldlist-wrapper .e-kpiGoal-icon::before,
.e-pivotfieldlist-container .e-kpiGoal-icon::before {
  content: "\e664" !important;
}
.e-pivotfieldlist-wrapper .e-kpiStatus-icon::before,
.e-pivotfieldlist-container .e-kpiStatus-icon::before {
  content: "\e664" !important;
}
.e-pivotfieldlist-wrapper .e-kpiTrend-icon::before,
.e-pivotfieldlist-container .e-kpiTrend-icon::before {
  content: "\e664" !important;
}
.e-pivotfieldlist-wrapper .e-kpiValue-icon::before,
.e-pivotfieldlist-container .e-kpiValue-icon::before {
  content: "\e664" !important;
}
.e-pivotfieldlist-wrapper .e-namedSetCDB-icon::before,
.e-pivotfieldlist-container .e-namedSetCDB-icon::before {
  content: "\e65d" !important;
}
.e-pivotfieldlist-wrapper .e-hierarchyCDB-icon::before,
.e-pivotfieldlist-container .e-hierarchyCDB-icon::before {
  content: "\e663" !important;
}
.e-pivotfieldlist-wrapper .e-attributeCDB-icon::before,
.e-pivotfieldlist-container .e-attributeCDB-icon::before {
  content: "\e65c" !important;
}
.e-pivotfieldlist-wrapper .e-hierarchy-level-0-icon::before,
.e-pivotfieldlist-container .e-hierarchy-level-0-icon::before {
  content: "\e65a" !important;
}
.e-pivotfieldlist-wrapper .e-hierarchy-level-1-icon::before,
.e-pivotfieldlist-container .e-hierarchy-level-1-icon::before {
  content: "\e65a" !important;
}
.e-pivotfieldlist-wrapper .e-hierarchy-level-2-icon::before,
.e-pivotfieldlist-container .e-hierarchy-level-2-icon::before {
  content: "\e662" !important;
}
.e-pivotfieldlist-wrapper .e-hierarchy-level-3-icon::before,
.e-pivotfieldlist-container .e-hierarchy-level-3-icon::before {
  content: "\e65e" !important;
}
.e-pivotfieldlist-wrapper .e-hierarchy-level-4-icon::before,
.e-pivotfieldlist-container .e-hierarchy-level-4-icon::before {
  content: "\e660" !important;
}
.e-pivotfieldlist-wrapper .e-hierarchy-level-5-icon::before,
.e-pivotfieldlist-container .e-hierarchy-level-5-icon::before {
  content: "\e661" !important;
}
.e-pivotfieldlist-wrapper .e-calcMemberGroupCDB::before,
.e-pivotfieldlist-container .e-calcMemberGroupCDB::before {
  content: "\e729" !important;
}
.e-pivotfieldlist-wrapper .e-calc-measure-icon::before,
.e-pivotfieldlist-container .e-calc-measure-icon::before {
  content: "\ea76" !important;
}
.e-pivotfieldlist-wrapper .e-calc-dimension-icon::before,
.e-pivotfieldlist-container .e-calc-dimension-icon::before {
  content: "\e65b" !important;
}
.e-pivotfieldlist-wrapper .e-sort-none::before,
.e-pivotfieldlist-container .e-sort-none::before {
  content: "\e666" !important;
}
.e-pivotfieldlist-wrapper .e-sort-ascend::before,
.e-pivotfieldlist-container .e-sort-ascend::before {
  content: "\e667" !important;
}
.e-pivotfieldlist-wrapper .e-field-list-search-icon::before,
.e-pivotfieldlist-container .e-field-list-search-icon::before {
  content: "\e993" !important;
}
.e-pivotfieldlist-wrapper .e-sort-descend::before,
.e-pivotfieldlist-container .e-sort-descend::before {
  content: "\e665" !important;
}
.e-pivotfieldlist-wrapper .e-pivot-calc-outer-div .e-pivot-accord .e-edited.e-icons::before,
.e-pivotfieldlist-container .e-pivot-calc-outer-div .e-pivot-accord .e-edited.e-icons::before {
  content: "\e7a3";
}
.e-pivotfieldlist-wrapper .e-pivot-calc-outer-div .e-pivot-accord .e-edit.e-icons::before,
.e-pivotfieldlist-container .e-pivot-calc-outer-div .e-pivot-accord .e-edit.e-icons::before {
  content: "\e891";
}
.e-pivotfieldlist-wrapper .e-pivot-calc-outer-div .e-pivot-accord .e-remove-report.e-icons::before,
.e-pivotfieldlist-container .e-pivot-calc-outer-div .e-pivot-accord .e-remove-report.e-icons::before {
  content: "\e706";
}
.e-pivotfieldlist-wrapper .e-pivot-button .e-edit::before,
.e-pivotfieldlist-container .e-pivot-button .e-edit::before {
  content: "\e891";
}
.e-pivotfieldlist-wrapper .e-sort-ascend-icon::before,
.e-pivotfieldlist-container .e-sort-ascend-icon::before {
  content: "\e734";
}
.e-pivotfieldlist-wrapper .e-sort-descend-icon::before,
.e-pivotfieldlist-container .e-sort-descend-icon::before {
  content: "\e733";
}

/*! field-list theme */
.e-pivotfieldlist .e-disable {
  display: none;
}
.e-pivotfieldlist .e-hide {
  visibility: hidden;
}

.e-pivot-formatting-dialog {
  /* stylelint-disable */
  max-height: 480px !important;
  width: 628px !important;
  /* stylelint-enable */
}
.e-pivot-formatting-dialog .e-format-table tr:nth-child(odd) td {
  padding-bottom: 4px;
}
.e-pivot-formatting-dialog .e-format-delete-icon {
  font-weight: normal;
}
.e-pivot-formatting-dialog .e-format-delete-icon:hover {
  font-weight: 600;
}
.e-pivot-formatting-dialog .e-format-table tr:nth-child(even) td {
  padding-bottom: 14px;
  padding-right: 18px;
  white-space: nowrap;
}
.e-pivot-formatting-dialog .e-format-value-span {
  vertical-align: bottom;
}
.e-pivot-formatting-dialog .e-format-value1,
.e-pivot-formatting-dialog .e-format-value2,
.e-pivot-formatting-dialog .e-format-value-span {
  margin-right: 10px;
}
.e-pivot-formatting-dialog .e-format-font-color-picker {
  margin-left: 0;
  margin-right: 16px;
}
.e-pivot-formatting-dialog .e-format-inner-div {
  padding-left: 14px;
  padding-top: 14px;
  width: 579px;
}
.e-pivot-formatting-dialog .e-format-outer-div {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin-bottom: 14px;
}
.e-pivot-formatting-dialog .e-format-condition-button {
  float: left;
}
.e-pivot-formatting-dialog .e-format-delete-button {
  background-color: transparent;
  border-color: transparent;
  float: right;
  padding-right: 12px;
  padding-top: 12px;
}
.e-pivot-formatting-dialog .e-format-delete-button:hover,
.e-pivot-formatting-dialog .e-format-delete-button:focus,
.e-pivot-formatting-dialog .e-format-delete-button:active {
  background-color: transparent;
  border-color: transparent;
  box-shadow: 0 0 0 transparent;
}
.e-pivot-formatting-dialog .e-format-delete-button:hover .e-format-delete-icon,
.e-pivot-formatting-dialog .e-format-delete-button:focus .e-format-delete-icon,
.e-pivot-formatting-dialog .e-format-delete-button:active .e-format-delete-icon {
  font-weight: 600;
}
.e-pivot-formatting-dialog .e-format-delete-icon {
  font-size: 10px;
}
.e-pivot-formatting-dialog .e-format-label,
.e-pivot-formatting-dialog .e-format-value-label {
  color: #000;
  font-size: 13px;
  font-weight: 500;
  opacity: 0.87;
}
.e-pivot-formatting-dialog .e-format-value-preview {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  height: 28px;
  /* stylelint-disable */
  margin: 0 !important;
  /* stylelint-enable */
  padding-left: 6px;
  padding-top: 6px;
  width: 80px;
}
.e-pivot-formatting-dialog .e-format-color-picker .e-split-btn-wrapper .e-split-btn .e-selected-color {
  background: none;
  margin: 0 2px;
  width: 14px;
}
.e-pivot-formatting-dialog .e-format-color-picker .e-split-btn-wrapper .e-split-btn {
  pointer-events: none;
}
.e-pivot-formatting-dialog .e-format-color-picker .e-split-btn-wrapper .e-split-btn .e-selected-color {
  background: none;
  margin: 0 2px;
  width: 14px;
}
.e-pivot-formatting-dialog .e-split-preview,
.e-pivot-formatting-dialog .e-tip-transparent {
  border: 1px solid #e0e0e0;
  /* stylelint-disable */
  border-radius: 0 !important;
  /* stylelint-enable */
  height: 40%;
  left: -3px;
  position: absolute;
  top: 16px;
  width: 150%;
}
.e-pivot-formatting-dialog.e-device {
  /* stylelint-disable */
  min-width: 320px !important;
  width: 100% !important;
  /* stylelint-enable */
}
.e-pivot-formatting-dialog.e-device .e-format-inner-div {
  width: 100% !important;
}
.e-pivot-formatting-dialog.e-device .e-format-condition-button .e-add-icon {
  margin-left: 0 !important;
}
.e-pivot-formatting-dialog.e-device .e-format-value1,
.e-pivot-formatting-dialog.e-device .e-format-value2,
.e-pivot-formatting-dialog.e-device .e-format-value-span {
  margin-right: 2px;
}
.e-pivot-formatting-dialog.e-device .e-format-color-picker {
  width: 33%;
}
.e-pivot-formatting-dialog.e-device .e-format-delete-button {
  font-size: 12px;
  padding-right: 16px;
  padding-top: 16px;
}
.e-pivot-formatting-dialog.e-device .e-format-apply-button,
.e-pivot-formatting-dialog.e-device .e-format-cancel-button {
  max-width: 40%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.e-pivot-formatting-dialog.e-rtl .e-format-font-color-picker {
  margin-left: 16px;
  margin-right: 0;
}
.e-pivot-formatting-dialog.e-rtl .e-format-condition-button {
  float: right;
}
.e-pivot-formatting-dialog.e-rtl .e-format-condition-button .e-add-icon {
  margin-left: auto;
}
.e-pivot-formatting-dialog.e-rtl .e-format-inner-div {
  padding-left: 0;
  padding-right: 14px;
}
.e-pivot-formatting-dialog.e-rtl .e-format-delete-button {
  float: left;
  padding-left: 12px;
}
.e-pivot-formatting-dialog.e-rtl.e-device .e-format-font-color-picker {
  margin-right: 0;
}

.e-bigger.e-pivot-formatting-dialog,
.e-bigger .e-pivot-formatting-dialog {
  /* stylelint-disable */
  width: 680px !important;
  /* stylelint-enable */
  /* stylelint-disable */
  /* stylelint-enable */
}
.e-bigger.e-pivot-formatting-dialog .e-format-label,
.e-bigger.e-pivot-formatting-dialog .e-format-value-label,
.e-bigger .e-pivot-formatting-dialog .e-format-label,
.e-bigger .e-pivot-formatting-dialog .e-format-value-label {
  font-size: 14px;
}
.e-bigger.e-pivot-formatting-dialog .e-format-value1, .e-bigger.e-pivot-formatting-dialog .e-format-value2,
.e-bigger .e-pivot-formatting-dialog .e-format-value1,
.e-bigger .e-pivot-formatting-dialog .e-format-value2 {
  margin-bottom: 3px !important;
}
.e-bigger.e-pivot-formatting-dialog .e-format-inner-div,
.e-bigger .e-pivot-formatting-dialog .e-format-inner-div {
  padding-left: 16px;
  padding-top: 16px;
  width: 656px;
}
.e-bigger.e-pivot-formatting-dialog .e-format-outer-div,
.e-bigger .e-pivot-formatting-dialog .e-format-outer-div {
  margin-bottom: 16px;
}
.e-bigger.e-pivot-formatting-dialog .e-format-table tr:nth-child(odd) td,
.e-bigger .e-pivot-formatting-dialog .e-format-table tr:nth-child(odd) td {
  padding-bottom: 12px;
}
.e-bigger.e-pivot-formatting-dialog .e-format-table tr:nth-child(even) td,
.e-bigger .e-pivot-formatting-dialog .e-format-table tr:nth-child(even) td {
  padding-bottom: 16px;
  padding-right: 20px;
}
.e-bigger.e-pivot-formatting-dialog .e-format-font-color-picker,
.e-bigger .e-pivot-formatting-dialog .e-format-font-color-picker {
  margin-left: 0;
  margin-right: 20px;
}
.e-bigger.e-pivot-formatting-dialog.e-device,
.e-bigger .e-pivot-formatting-dialog.e-device {
  /* stylelint-disable */
  min-width: 320px !important;
  width: 100% !important;
  /* stylelint-enable */
}
.e-bigger.e-pivot-formatting-dialog.e-device .e-format-color-picker,
.e-bigger .e-pivot-formatting-dialog.e-device .e-format-color-picker {
  width: 33%;
}
.e-bigger.e-pivot-formatting-dialog.e-device .e-format-delete-button,
.e-bigger .e-pivot-formatting-dialog.e-device .e-format-delete-button {
  font-size: 12px;
  padding-right: 16px;
  padding-top: 16px;
}
.e-bigger.e-pivot-formatting-dialog.e-rtl .e-format-font-color-picker,
.e-bigger .e-pivot-formatting-dialog.e-rtl .e-format-font-color-picker {
  margin-left: 20px;
  margin-right: 0;
}

.e-pivot-calc-dialog-div {
  /* stylelint-disable */
  max-height: 600px !important;
  /* stylelint-enable */
  min-width: 290px;
  /* stylelint-disable */
  /* stylelint-enable */
  /* stylelint-disable */
  /* stylelint-enable */
}
.e-pivot-calc-dialog-div .e-dlg-header-content,
.e-pivot-calc-dialog-div .e-footer-content {
  border: 0;
}
.e-pivot-calc-dialog-div .e-dlg-content {
  padding-bottom: 18px;
}
.e-pivot-calc-dialog-div .e-dlg-content .e-pivot-calc-custom-format-div {
  margin-top: 15px;
}
.e-pivot-calc-dialog-div .e-pivot-all-field-title,
.e-pivot-calc-dialog-div .e-pivot-field-name-title,
.e-pivot-calc-dialog-div .e-pivot-formula-title,
.e-pivot-calc-dialog-div .e-olap-hierarchy-title,
.e-pivot-calc-dialog-div .e-pivot-format-title,
.e-pivot-calc-dialog-div .e-olap-member-title {
  color: rgba(0, 0, 0, 0.54);
  font-size: 13px;
  margin-bottom: 4px;
  margin-top: 12px;
  overflow: hidden;
  padding: 4px 0;
  padding-bottom: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.e-pivot-calc-dialog-div .e-treeview .e-sibling {
  display: none;
}
.e-pivot-calc-dialog-div .e-treeview .e-list-selected {
  opacity: 0.5;
}
.e-pivot-calc-dialog-div .e-treeview ul {
  overflow: hidden;
}
.e-pivot-calc-dialog-div .e-treeview ul li {
  padding: 0;
}
.e-pivot-calc-dialog-div .e-treeview ul li .e-list-text {
  font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif", "-apple-system", "BlinkMacSystemFont";
}
.e-pivot-calc-dialog-div .e-treeview ul li:not(.e-active) .e-list-icon {
  color: rgba(0, 0, 0, 0.54);
}
.e-pivot-calc-dialog-div .e-treeview ul li .e-list-icon {
  color: rgba(0, 0, 0, 0.54);
  /* stylelint-disable */
  float: right !important;
  margin: 0 12px 0 0;
  /* stylelint-enable */
}
.e-pivot-calc-dialog-div .e-treeview ul .e-fullrow {
  cursor: default;
}
.e-pivot-calc-dialog-div .e-treeview ul .e-list-item .e-text-content {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  padding-left: 0;
  padding-right: 0;
  vertical-align: middle;
}
.e-pivot-calc-dialog-div .e-treeview .e-list-item div.e-icons {
  cursor: pointer;
  font-size: 14px;
  height: auto;
  opacity: 0.87;
  padding: 0 4px;
  pointer-events: auto;
  width: auto;
}
.e-pivot-calc-dialog-div .e-treeview .e-list-item div.e-icons.e-format {
  float: right;
  font-size: 20px;
  height: auto;
  margin-right: 12px;
  padding: 0;
}
.e-pivot-calc-dialog-div .e-treeview .e-list-item div.e-iconspace {
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  height: 24px;
  margin-right: 10px;
  opacity: 0.87;
  padding: 0 4px;
  pointer-events: auto;
  width: 24px;
}
.e-pivot-calc-dialog-div .e-treeview .e-list-item:not(.e-active) span.e-icons {
  color: #bdbdbd;
}
.e-pivot-calc-dialog-div .e-treeview .e-list-item span.e-icons {
  color: #bdbdbd;
  cursor: move;
  display: inline-block;
  font-size: 14px;
  margin-right: 4px;
  pointer-events: auto;
  width: 20px;
}
.e-pivot-calc-dialog-div .e-dlg-header {
  font-size: 18px;
  font-weight: 500;
  opacity: 0.87;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 74%;
}
.e-pivot-calc-dialog-div .e-contextmenu {
  background-color: #fff;
  border-radius: 4px;
}
.e-pivot-calc-dialog-div .e-contextmenu .e-menu-item {
  color: #000;
  font-size: 14px;
  height: 48px;
  opacity: 0.87;
}
.e-pivot-calc-dialog-div.e-rtl .e-treeview .e-list-item div.e-icons {
  height: 24px;
  width: 24px;
}
.e-pivot-calc-dialog-div.e-rtl .e-treeview .e-list-item div.e-edit.e-icons,
.e-pivot-calc-dialog-div.e-rtl .e-treeview .e-list-item div.e-edited.e-icons,
.e-pivot-calc-dialog-div.e-rtl .e-treeview .e-list-item div.e-remove-report.e-icons {
  transform: rotate(0deg);
  margin: 3px 0 0 0;
}
.e-pivot-calc-dialog-div.e-rtl .e-treeview ul {
  margin-right: 0;
}
.e-pivot-calc-dialog-div.e-rtl .e-treeview ul li {
  margin-left: 0;
  margin-right: -40px;
}
.e-pivot-calc-dialog-div.e-rtl .e-treeview ul li .e-text-content {
  padding: 0 24px 0 0;
  display: -ms-flexbox;
  display: flex;
}
.e-pivot-calc-dialog-div .e-pivot-calc-input.e-empty-field::-webkit-input-placeholder {
  color: #f44336 !important;
  font-weight: 600 !important;
}
.e-pivot-calc-dialog-div .e-pivot-calc-input.e-empty-field:-moz-placeholder {
  color: #f44336 !important;
  font-weight: 600 !important;
}
.e-pivot-calc-dialog-div .e-pivot-calc-input.e-empty-field::-moz-placeholder {
  color: #f44336 !important;
  font-weight: 600 !important;
}
.e-pivot-calc-dialog-div .e-pivot-calc-input.e-empty-field:-ms-input-placeholder {
  color: #f44336 !important;
  font-weight: 600 !important;
}
.e-pivot-calc-dialog-div .e-pivot-formula {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  color: #000;
  font-size: 13px;
  height: 70px;
  padding: 5px 10px;
  resize: none;
  width: 100%;
}
.e-pivot-calc-dialog-div .e-pivot-formula.e-copy-drop {
  cursor: copy;
}
.e-pivot-calc-dialog-div .e-list-parent .e-list-text {
  min-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.e-pivot-calc-dialog-div .e-pivot-treeview-outer {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  height: 120px;
  overflow: auto;
}
.e-pivot-calc-dialog-div.e-olap-calc-dialog-div {
  min-width: 575px;
}
.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div {
  display: -ms-flexbox;
  display: flex;
}
.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div {
  height: 465px;
  width: 50%;
}
.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer {
  height: 100%;
  margin-top: 0;
  overflow: hidden;
}
.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-pivot-treeview-outer-div {
  display: inline-block;
  height: 436px;
  overflow: auto;
  width: 100% !important;
}
.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-treeview {
  display: inline-table;
  height: 100%;
  width: 100%;
  min-width: 265px;
}
.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-treeview ul .e-fullrow {
  cursor: move;
}
.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-treeview ul .e-list-text {
  margin-bottom: 0;
  overflow: unset;
  width: 100%;
}
.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-treeview ul .e-calcfieldmember .e-list-text {
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-treeview ul .e-list-item .e-text-content {
  margin: 0;
  padding: 0 0 0 24px;
}
.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-treeview ul .e-remove-report.e-list-icon {
  cursor: pointer;
  font-size: 13px;
  margin-top: 3px;
  padding-left: 0px;
  padding-right: 0px;
}
.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-treeview ul .e-list-icon {
  cursor: auto;
  font-size: 14px;
  width: auto;
}
.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-treeview ul .e-list-icon.e-edit {
  cursor: pointer;
  padding: 7px !important;
}
.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-treeview ul .e-list-icon.e-edited {
  cursor: pointer;
  padding: 7px !important;
}
.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-treeview ul .e-list-item div.e-icons {
  padding: 0;
  height: auto;
  float: none !important;
}
.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-treeview ul li {
  margin-left: 0;
  margin-right: 0;
}
.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-treeview.e-rtl ul .e-list-icon {
  transform: rotateX(0deg) rotateY(180deg);
}
.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-treeview.e-rtl ul .e-list-item .e-text-content {
  padding: 0 24px 0 0;
}
.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-treeview.e-rtl ul li {
  margin-left: 0;
  margin-right: 0;
}
.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-treeview ul:first-child {
  padding-left: 10px;
  padding-right: 10px;
}
.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-pivot-all-field-title {
  color: rgba(0, 0, 0, 0.54);
  -ms-flex: auto;
      flex: auto;
  margin-top: 0;
  padding-bottom: 4px;
  padding-left: 15px;
  padding-right: 15px;
}
.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-pivot-all-field-title-container {
  background: #f5f5f5;
  color: rgba(0, 0, 0, 0.54);
  display: -ms-flexbox;
  display: flex;
  font-size: 13px;
}
.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-pivot-all-field-title-container .e-info {
  padding: 7px !important;
}
.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-pivot-all-field-title-container .e-info:hover {
  color: #e3165b;
}
.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-pivot-calculated-div {
  -ms-flex: auto;
      flex: auto;
  margin-left: 18px;
}
.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-pivot-calculated-div .e-pivot-formula {
  height: 100px;
}
.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-pivot-calculated-div .e-pivot-calc-custom-format-div {
  margin-top: 15px;
}
.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-pivot-calculated-div .e-pivot-formula-title,
.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-pivot-calculated-div .e-pivot-field-name-title,
.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-pivot-calculated-div .e-olap-hierarchy-title,
.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-pivot-calculated-div .e-pivot-format-title,
.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-pivot-calculated-div .e-olap-member-title {
  padding-left: 0;
  padding-right: 0;
}
.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-pivot-calculated-div .e-pivot-field-name-title {
  margin-top: 0;
  padding-top: 0;
}
.e-pivot-calc-dialog-div.e-olap-calc-dialog-div.e-dialog {
  max-height: 700px !important;
}
.e-pivot-calc-dialog-div.e-olap-calc-dialog-div.e-dialog .e-calc-clear-btn {
  float: left;
  margin: 0;
}
.e-pivot-calc-dialog-div.e-olap-calc-dialog-div.e-rtl .e-pivot-calc-outer-div .e-pivot-calculated-div {
  margin-left: 0;
  margin-right: 18px;
}
.e-pivot-calc-dialog-div.e-olap-calc-dialog-div.e-rtl.e-dialog .e-calc-clear-btn {
  float: right;
}

.e-bigger.e-pivot-calc-dialog-div,
.e-bigger .e-pivot-calc-dialog-div {
  /* stylelint-disable */
  max-height: 660px !important;
  min-width: 401px !important;
  /* stylelint-enable */
}
.e-bigger.e-pivot-calc-dialog-div .e-pivot-all-field-title,
.e-bigger.e-pivot-calc-dialog-div .e-pivot-field-name-title,
.e-bigger.e-pivot-calc-dialog-div .e-pivot-formula-title,
.e-bigger.e-pivot-calc-dialog-div .e-olap-hierarchy-title,
.e-bigger.e-pivot-calc-dialog-div .e-pivot-format-title,
.e-bigger.e-pivot-calc-dialog-div .e-olap-member-title,
.e-bigger .e-pivot-calc-dialog-div .e-pivot-all-field-title,
.e-bigger .e-pivot-calc-dialog-div .e-pivot-field-name-title,
.e-bigger .e-pivot-calc-dialog-div .e-pivot-formula-title,
.e-bigger .e-pivot-calc-dialog-div .e-olap-hierarchy-title,
.e-bigger .e-pivot-calc-dialog-div .e-pivot-format-title,
.e-bigger .e-pivot-calc-dialog-div .e-olap-member-title {
  font-size: 14px;
  padding: 6px 0;
  padding-bottom: 0;
}
.e-bigger.e-pivot-calc-dialog-div .e-treeview ul li,
.e-bigger .e-pivot-calc-dialog-div .e-treeview ul li {
  padding: 2px 0;
}
.e-bigger.e-pivot-calc-dialog-div .e-treeview .e-list-item div.e-icons,
.e-bigger .e-pivot-calc-dialog-div .e-treeview .e-list-item div.e-icons {
  font-size: 16px;
}
.e-bigger.e-pivot-calc-dialog-div .e-treeview .e-list-item .e-remove-report,
.e-bigger.e-pivot-calc-dialog-div .e-treeview .e-list-item .e-edit,
.e-bigger.e-pivot-calc-dialog-div .e-treeview .e-list-item .e-edited,
.e-bigger .e-pivot-calc-dialog-div .e-treeview .e-list-item .e-remove-report,
.e-bigger .e-pivot-calc-dialog-div .e-treeview .e-list-item .e-edit,
.e-bigger .e-pivot-calc-dialog-div .e-treeview .e-list-item .e-edited {
  /* stylelint-disable */
  margin: 0 12px 0 0 !important;
  /* stylelint-enable */
}
.e-bigger.e-pivot-calc-dialog-div .e-treeview .e-list-item span.e-icons,
.e-bigger .e-pivot-calc-dialog-div .e-treeview .e-list-item span.e-icons {
  font-size: 16px;
}
.e-bigger.e-pivot-calc-dialog-div .e-pivot-formula,
.e-bigger .e-pivot-calc-dialog-div .e-pivot-formula {
  font-size: 13px;
}
.e-bigger.e-pivot-calc-dialog-div .e-footer-content,
.e-bigger .e-pivot-calc-dialog-div .e-footer-content {
  padding-right: 8px;
  padding-top: 8px;
}
.e-bigger.e-pivot-calc-dialog-div.e-olap-calc-dialog-div,
.e-bigger .e-pivot-calc-dialog-div.e-olap-calc-dialog-div {
  /* stylelint-disable */
  max-height: 750px !important;
  min-width: 580px !important;
  /* stylelint-enable */
}
.e-bigger.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div,
.e-bigger .e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div {
  height: 520px;
  width: 283px;
}
.e-bigger.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-pivot-treeview-outer-div,
.e-bigger .e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-pivot-treeview-outer-div {
  height: 485px;
  width: 325px;
}
.e-bigger.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-pivot-all-field-title,
.e-bigger .e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-pivot-all-field-title {
  padding-bottom: 6px;
}
.e-bigger.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-pivot-all-field-title-container,
.e-bigger .e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-pivot-all-field-title-container {
  font-size: 14px;
}
.e-bigger.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-treeview ul .e-list-icon,
.e-bigger .e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-treeview ul .e-list-icon {
  font-size: 16px;
}
.e-bigger.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-pivot-calculated-div,
.e-bigger .e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-pivot-calculated-div {
  margin-left: 24px;
}
.e-bigger.e-pivot-calc-dialog-div.e-olap-calc-dialog-div.e-rtl .e-pivot-calc-outer-div .e-pivot-calculated-div,
.e-bigger .e-pivot-calc-dialog-div.e-olap-calc-dialog-div.e-rtl .e-pivot-calc-outer-div .e-pivot-calculated-div {
  margin-left: 0;
  margin-right: 24px;
}

.e-pivotfieldlist-container .e-adaptive-field-list-dialog,
.e-pivotfieldlist-container .e-member-editor-dialog {
  /* stylelint-disable */
  max-width: 400px !important;
  min-width: 320px !important;
  /* stylelint-enable */
  /* stylelint-disable */
  /* stylelint-enable */
}
.e-pivotfieldlist-container .e-adaptive-field-list-dialog.e-node-limit,
.e-pivotfieldlist-container .e-member-editor-dialog.e-node-limit {
  max-height: 450px !important;
}
.e-pivotfieldlist-container .e-adaptive-field-list-dialog .e-member-prompt,
.e-pivotfieldlist-container .e-member-editor-dialog .e-member-prompt {
  padding: 25px 20px;
  text-align: center;
  width: 100%;
}
.e-pivotfieldlist-container .e-adaptive-field-list-dialog .e-editor-search-container,
.e-pivotfieldlist-container .e-member-editor-dialog .e-editor-search-container {
  display: -ms-flexbox;
  display: flex;
  padding: 0 20px 10px;
}
.e-pivotfieldlist-container .e-adaptive-field-list-dialog .e-editor-search-container .e-clear-icon-hide,
.e-pivotfieldlist-container .e-member-editor-dialog .e-editor-search-container .e-clear-icon-hide {
  visibility: hidden;
}
.e-pivotfieldlist-container .e-adaptive-field-list-dialog .e-select-all-container ul li .e-text-content > .e-icon-expandable,
.e-pivotfieldlist-container .e-member-editor-dialog .e-select-all-container ul li .e-text-content > .e-icon-expandable {
  display: none;
}
.e-pivotfieldlist-container .e-adaptive-field-list-dialog .e-member-editor-container-outer-div,
.e-pivotfieldlist-container .e-member-editor-dialog .e-member-editor-container-outer-div {
  display: inline-block;
  height: 150px;
  max-width: 400px;
  overflow: auto;
  width: 100%;
}
.e-pivotfieldlist-container .e-adaptive-field-list-dialog .e-member-editor-container,
.e-pivotfieldlist-container .e-member-editor-dialog .e-member-editor-container {
  display: inline-table;
  width: 100%;
}
.e-pivotfieldlist-container .e-adaptive-field-list-dialog .e-member-editor-container.e-rtl ul .e-list-icon,
.e-pivotfieldlist-container .e-member-editor-dialog .e-member-editor-container.e-rtl ul .e-list-icon {
  transform: rotateX(0deg) rotateY(180deg);
}
.e-pivotfieldlist-container .e-adaptive-field-list-dialog:not(.e-member-editor-dialog):not(.e-olap-field-list-tree) ul,
.e-pivotfieldlist-container .e-member-editor-dialog:not(.e-member-editor-dialog):not(.e-olap-field-list-tree) ul {
  padding-left: 48px;
}
.e-pivotfieldlist-container .e-adaptive-field-list-dialog:not(.e-member-editor-dialog):not(.e-olap-field-list-tree) ul li:not(.e-pivot-parent):not(.e-pivot-child) .e-text-content,
.e-pivotfieldlist-container .e-member-editor-dialog:not(.e-member-editor-dialog):not(.e-olap-field-list-tree) ul li:not(.e-pivot-parent):not(.e-pivot-child) .e-text-content {
  padding-left: 0;
  margin-left: 15px;
}
.e-pivotfieldlist-container .e-adaptive-field-list-dialog .e-member-editor-container ul,
.e-pivotfieldlist-container .e-member-editor-dialog .e-member-editor-container ul {
  /* stylelint-disable */
  margin-left: -35px;
  overflow: auto !important;
  /* stylelint-enable */
}
.e-pivotfieldlist-container .e-adaptive-field-list-dialog .e-member-editor-container ul li .e-list-text,
.e-pivotfieldlist-container .e-member-editor-dialog .e-member-editor-container ul li .e-list-text {
  font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif", "-apple-system", "BlinkMacSystemFont";
}
.e-pivotfieldlist-container .e-adaptive-field-list-dialog .e-member-editor-container ul li .e-list-icon,
.e-pivotfieldlist-container .e-member-editor-dialog .e-member-editor-container ul li .e-list-icon {
  margin: 0;
}
.e-pivotfieldlist-container .e-adaptive-field-list-dialog .e-disable,
.e-pivotfieldlist-container .e-member-editor-dialog .e-disable {
  display: none !important;
}
.e-pivotfieldlist-container .e-adaptive-field-list-dialog.e-rtl:not(.e-member-editor-dialog):not(.e-olap-field-list-tree) ul,
.e-pivotfieldlist-container .e-member-editor-dialog.e-rtl:not(.e-member-editor-dialog):not(.e-olap-field-list-tree) ul {
  padding-right: 48px;
}
.e-pivotfieldlist-container .e-adaptive-field-list-dialog.e-rtl:not(.e-member-editor-dialog):not(.e-olap-field-list-tree) ul li:not(.e-pivot-parent):not(.e-pivot-child) .e-text-content,
.e-pivotfieldlist-container .e-member-editor-dialog.e-rtl:not(.e-member-editor-dialog):not(.e-olap-field-list-tree) ul li:not(.e-pivot-parent):not(.e-pivot-child) .e-text-content {
  padding-right: 0;
}
.e-pivotfieldlist-container .e-adaptive-field-list-dialog.e-rtl .e-member-editor-outer-container ul,
.e-pivotfieldlist-container .e-member-editor-dialog.e-rtl .e-member-editor-outer-container ul {
  margin-left: 0;
  margin-right: -35px;
}
.e-pivotfieldlist-container .e-adaptive-field-list-dialog.e-olap-editor-dialog .e-member-editor-outer-container .e-member-editor-container ul,
.e-pivotfieldlist-container .e-member-editor-dialog.e-olap-editor-dialog .e-member-editor-outer-container .e-member-editor-container ul {
  margin-left: 0;
}
.e-pivotfieldlist-container .e-adaptive-field-list-dialog.e-olap-editor-dialog .e-member-editor-outer-container .e-treeview ul:first-child,
.e-pivotfieldlist-container .e-member-editor-dialog.e-olap-editor-dialog .e-member-editor-outer-container .e-treeview ul:first-child {
  padding-left: 10px;
  padding-right: 10px;
}
.e-pivotfieldlist-container .e-adaptive-field-list-dialog.e-olap-editor-dialog .e-member-editor-outer-container .e-editor-search-container,
.e-pivotfieldlist-container .e-member-editor-dialog.e-olap-editor-dialog .e-member-editor-outer-container .e-editor-search-container {
  display: -ms-flexbox;
  display: flex;
}
.e-pivotfieldlist-container .e-adaptive-field-list-dialog.e-olap-editor-dialog.e-rtl .e-member-editor-outer-container ul,
.e-pivotfieldlist-container .e-member-editor-dialog.e-olap-editor-dialog.e-rtl .e-member-editor-outer-container ul {
  margin-right: 0;
}
.e-pivotfieldlist-container .e-member-editor-dialog .e-toolbar .e-toolbar-items {
  margin-left: 20px;
}
.e-pivotfieldlist-container .e-member-editor-dialog .e-clear-filter-button {
  float: left;
  /* stylelint-disable */
  margin: 0 !important;
  /* stylelint-enable */
}
.e-pivotfieldlist-container .e-member-editor-dialog .e-clear-filter-button.e-disable {
  display: none;
}
.e-pivotfieldlist-container .e-member-editor-dialog .e-editor-label-container {
  -ms-flex-align: center;
      align-items: center;
  background: #f5f5f5;
  /* stylelint-disable */
  display: -ms-flexbox !important;
  display: flex !important;
  /* stylelint-enable */
  height: 40px;
  -ms-flex-pack: center;
      justify-content: center;
  opacity: 56%;
}
.e-pivotfieldlist-container .e-member-editor-dialog .e-editor-label-container .e-editor-label {
  color: #000;
  font: 500 13px Roboto;
  margin-bottom: 0;
  opacity: 0.87;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.e-pivotfieldlist-container .e-member-editor-dialog .e-filter-sort {
  box-shadow: none;
  margin-left: 10px;
}
.e-pivotfieldlist-container .e-member-editor-dialog .e-filter-sort .e-member-sort .e-sort-ascend-icon,
.e-pivotfieldlist-container .e-member-editor-dialog .e-filter-sort .e-member-sort .e-sort-descend-icon {
  font-size: 16px;
}
.e-pivotfieldlist-container .e-member-editor-dialog .e-select-all-container ul {
  margin-left: -35px;
  overflow: hidden;
}
.e-pivotfieldlist-container .e-member-editor-dialog .e-select-all-container ul li .e-list-text {
  font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif", "-apple-system", "BlinkMacSystemFont";
}
.e-pivotfieldlist-container .e-member-editor-dialog .e-select-all-container ul li .e-list-icon {
  margin: 0;
}
.e-pivotfieldlist-container .e-member-editor-dialog .e-filter-tab-container {
  border: 0;
}
.e-pivotfieldlist-container .e-member-editor-dialog .e-filter-tab-container .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text {
  margin: 0 0 0 6px;
}
.e-pivotfieldlist-container .e-member-editor-dialog .e-filter-tab-container .e-tab-header .e-text-wrap .e-tab-icon {
  height: fit-content;
}
.e-pivotfieldlist-container .e-member-editor-dialog .e-filter-tab-container .e-tab-header .e-text-wrap .e-tab-icon::before {
  font-size: 14px;
  /* stylelint-disable */
  top: 0 !important;
  /* stylelint-enable */
}
.e-pivotfieldlist-container .e-member-editor-dialog .e-filter-tab-container.e-rtl .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text {
  margin: 0 6px 0 0;
}
.e-pivotfieldlist-container .e-member-editor-dialog .e-excelfilter {
  padding-top: 20px;
  /* stylelint-disable */
}
.e-pivotfieldlist-container .e-member-editor-dialog .e-excelfilter .e-member-editor-container-outer-div {
  height: 150px !important;
}
.e-pivotfieldlist-container .e-member-editor-dialog.e-node-limit .e-excelfilter .e-member-editor-container-outer-div {
  height: 145px !important;
  /* stylelint-enable */
}
.e-pivotfieldlist-container .e-member-editor-dialog .e-label-filter,
.e-pivotfieldlist-container .e-member-editor-dialog .e-value-filter {
  height: 248px;
  overflow: auto;
  padding: 20px;
  padding-bottom: 0;
  /* stylelint-disable */
}
.e-pivotfieldlist-container .e-member-editor-dialog .e-label-filter .e-filter-text-div,
.e-pivotfieldlist-container .e-member-editor-dialog .e-value-filter .e-filter-text-div {
  color: rgba(0, 0, 0, 0.54) !important;
  /* stylelint-enable */
  font-size: 13px;
  font-weight: 500;
}
.e-pivotfieldlist-container .e-member-editor-dialog .e-label-filter .e-filter-option-container-1,
.e-pivotfieldlist-container .e-member-editor-dialog .e-label-filter .e-filter-option-container-2,
.e-pivotfieldlist-container .e-member-editor-dialog .e-label-filter .e-separator-div,
.e-pivotfieldlist-container .e-member-editor-dialog .e-value-filter .e-filter-option-container-1,
.e-pivotfieldlist-container .e-member-editor-dialog .e-value-filter .e-filter-option-container-2,
.e-pivotfieldlist-container .e-member-editor-dialog .e-value-filter .e-separator-div {
  padding-top: 20px;
}
.e-pivotfieldlist-container .e-member-editor-dialog .e-label-filter .e-between-text-div,
.e-pivotfieldlist-container .e-member-editor-dialog .e-value-filter .e-between-text-div {
  padding: 8px 0;
}
.e-pivotfieldlist-container .e-member-editor-dialog .e-label-filter .e-disable,
.e-pivotfieldlist-container .e-member-editor-dialog .e-value-filter .e-disable {
  display: none;
}
.e-pivotfieldlist-container .e-member-editor-dialog.e-rtl .e-toolbar .e-toolbar-items {
  margin-left: 0;
  margin-right: 20px;
}
.e-pivotfieldlist-container .e-member-editor-dialog.e-rtl .e-member-editor-outer-container .e-editor-search-container .e-filter-sort {
  margin-left: 0;
  margin-right: 10px;
}
.e-pivotfieldlist-container .e-member-editor-dialog.e-rtl .e-footer-content .e-clear-filter-button {
  float: right;
}
.e-pivotfieldlist-container .e-member-editor-dialog.e-olap-editor-dialog {
  /* stylelint-disable */
}
.e-pivotfieldlist-container .e-member-editor-dialog.e-olap-editor-dialog .e-member-editor-outer-container .e-select-all-container ul {
  margin-left: 0;
}
.e-pivotfieldlist-container .e-member-editor-dialog.e-olap-editor-dialog .e-member-editor-outer-container .e-editor-search-container .e-level-drop {
  margin: 0 5px;
}
.e-pivotfieldlist-container .e-member-editor-dialog.e-olap-editor-dialog .e-member-editor-outer-container .e-editor-search-container .e-level-drop .e-caret {
  font-size: 14px;
}
.e-pivotfieldlist-container .e-member-editor-dialog.e-olap-editor-dialog .e-member-editor-outer-container .e-editor-search-container .e-level-drop li .e-disabled {
  opacity: 0.5;
  pointer-events: none;
}
.e-pivotfieldlist-container .e-member-editor-dialog.e-olap-editor-dialog.e-rtl .e-member-editor-outer-container .e-editor-search-container .e-filter-sort {
  margin-left: 0;
  margin-right: 10px;
}
.e-pivotfieldlist-container .e-member-editor-dialog .e-member-editor-outer-container:not(.e-excelfilter) .e-editor-search-container {
  padding-top: 4px !important;
}
.e-pivotfieldlist-container .e-adaptive-field-list-dialog ul:first-child {
  min-height: 170px;
  overflow: auto;
}
.e-pivotfieldlist-container .e-adaptive-field-list-dialog .e-member-editor-container ul li .e-calc-measure-icon,
.e-pivotfieldlist-container .e-adaptive-field-list-dialog .e-member-editor-container ul li .e-calc-dimension-icon,
.e-pivotfieldlist-container .e-adaptive-field-list-dialog .e-member-editor-container ul li .e-attributeCDB-icon,
.e-pivotfieldlist-container .e-adaptive-field-list-dialog .e-member-editor-container ul li .e-hierarchyCDB-icon,
.e-pivotfieldlist-container .e-adaptive-field-list-dialog .e-member-editor-container ul li .e-level-members,
.e-pivotfieldlist-container .e-adaptive-field-list-dialog .e-member-editor-container ul li .e-namedSetCDB-icon,
.e-pivotfieldlist-container .e-adaptive-field-list-dialog .e-member-editor-container ul li .e-measure-icon,
.e-pivotfieldlist-container .e-adaptive-field-list-dialog .e-member-editor-container ul li .e-kpiGoal-icon,
.e-pivotfieldlist-container .e-adaptive-field-list-dialog .e-member-editor-container ul li .e-kpiStatus-icon,
.e-pivotfieldlist-container .e-adaptive-field-list-dialog .e-member-editor-container ul li .e-kpiTrend-icon,
.e-pivotfieldlist-container .e-adaptive-field-list-dialog .e-member-editor-container ul li .e-kpiValue-icon {
  margin-left: 10px;
}
.e-pivotfieldlist-container .e-adaptive-field-list-dialog.e-olap-editor-dialog.e-rtl .e-member-editor-outer-container ul li .e-calc-measure-icon,
.e-pivotfieldlist-container .e-adaptive-field-list-dialog.e-olap-editor-dialog.e-rtl .e-member-editor-outer-container ul li .e-calc-dimension-icon,
.e-pivotfieldlist-container .e-adaptive-field-list-dialog.e-olap-editor-dialog.e-rtl .e-member-editor-outer-container ul li .e-attributeCDB-icon,
.e-pivotfieldlist-container .e-adaptive-field-list-dialog.e-olap-editor-dialog.e-rtl .e-member-editor-outer-container ul li .e-hierarchyCDB-icon,
.e-pivotfieldlist-container .e-adaptive-field-list-dialog.e-olap-editor-dialog.e-rtl .e-member-editor-outer-container ul li .e-level-members,
.e-pivotfieldlist-container .e-adaptive-field-list-dialog.e-olap-editor-dialog.e-rtl .e-member-editor-outer-container ul li .e-namedSetCDB-icon,
.e-pivotfieldlist-container .e-adaptive-field-list-dialog.e-olap-editor-dialog.e-rtl .e-member-editor-outer-container ul li .e-measure-icon,
.e-pivotfieldlist-container .e-adaptive-field-list-dialog.e-olap-editor-dialog.e-rtl .e-member-editor-outer-container ul li .e-kpiGoal-icon,
.e-pivotfieldlist-container .e-adaptive-field-list-dialog.e-olap-editor-dialog.e-rtl .e-member-editor-outer-container ul li .e-kpiStatus-icon,
.e-pivotfieldlist-container .e-adaptive-field-list-dialog.e-olap-editor-dialog.e-rtl .e-member-editor-outer-container ul li .e-kpiTrend-icon,
.e-pivotfieldlist-container .e-adaptive-field-list-dialog.e-olap-editor-dialog.e-rtl .e-member-editor-outer-container ul li .e-kpiValue-icon {
  margin-left: 0;
  margin-right: 10px;
}
.e-pivotfieldlist-container .e-value-field-settings {
  max-width: 400px !important;
  min-width: 300px !important;
}
.e-pivotfieldlist-container .e-value-field-settings .e-dlg-content {
  overflow: auto !important;
}
.e-pivotfieldlist-container .e-value-field-settings .e-value-field-div-content {
  padding: 0 20px 10px;
}
.e-pivotfieldlist-container .e-value-field-settings .e-value-field-div-content .e-field-option-container .e-field-name-text-container {
  display: -ms-flexbox;
  display: flex;
}
.e-pivotfieldlist-container .e-value-field-settings .e-value-field-div-content .e-field-option-container .e-field-name-text-container .e-field-name-title,
.e-pivotfieldlist-container .e-value-field-settings .e-value-field-div-content .e-field-option-container .e-field-name-text-container .e-field-name-content {
  font-size: 13px;
  font-weight: 500;
}
.e-pivotfieldlist-container .e-value-field-settings .e-value-field-div-content .e-field-option-container .e-field-name-text-container .e-field-name-title {
  -ms-flex: none;
      flex: none;
  white-space: pre;
}
.e-pivotfieldlist-container .e-value-field-settings .e-value-field-div-content .e-field-option-container .e-field-name-text-container .e-field-name-content {
  -ms-flex: auto;
      flex: auto;
  width: 100%;
}
.e-pivotfieldlist-container .e-value-field-settings .e-value-field-div-content .e-field-option-container .e-caption-input-container,
.e-pivotfieldlist-container .e-value-field-settings .e-value-field-div-content .e-field-option-container .e-type-option-container,
.e-pivotfieldlist-container .e-value-field-settings .e-value-field-div-content .e-field-option-container .e-base-field-option-container,
.e-pivotfieldlist-container .e-value-field-settings .e-value-field-div-content .e-field-option-container .e-base-item-option-container {
  padding-top: 20px;
}
.e-pivotfieldlist-container .e-value-field-settings .e-value-field-div-content .e-field-option-container .e-caption-input-container .e-base-field-option-text,
.e-pivotfieldlist-container .e-value-field-settings .e-value-field-div-content .e-field-option-container .e-caption-input-container .e-base-item-option-text,
.e-pivotfieldlist-container .e-value-field-settings .e-value-field-div-content .e-field-option-container .e-caption-input-container .e-type-option-text,
.e-pivotfieldlist-container .e-value-field-settings .e-value-field-div-content .e-field-option-container .e-type-option-container .e-base-field-option-text,
.e-pivotfieldlist-container .e-value-field-settings .e-value-field-div-content .e-field-option-container .e-type-option-container .e-base-item-option-text,
.e-pivotfieldlist-container .e-value-field-settings .e-value-field-div-content .e-field-option-container .e-type-option-container .e-type-option-text,
.e-pivotfieldlist-container .e-value-field-settings .e-value-field-div-content .e-field-option-container .e-base-field-option-container .e-base-field-option-text,
.e-pivotfieldlist-container .e-value-field-settings .e-value-field-div-content .e-field-option-container .e-base-field-option-container .e-base-item-option-text,
.e-pivotfieldlist-container .e-value-field-settings .e-value-field-div-content .e-field-option-container .e-base-field-option-container .e-type-option-text,
.e-pivotfieldlist-container .e-value-field-settings .e-value-field-div-content .e-field-option-container .e-base-item-option-container .e-base-field-option-text,
.e-pivotfieldlist-container .e-value-field-settings .e-value-field-div-content .e-field-option-container .e-base-item-option-container .e-base-item-option-text,
.e-pivotfieldlist-container .e-value-field-settings .e-value-field-div-content .e-field-option-container .e-base-item-option-container .e-type-option-text {
  font-size: 13px;
  font-weight: 500;
  width: 100%;
}
.e-pivotfieldlist-container .e-value-field-settings .e-value-field-div-content .e-field-option-container .e-caption-input-container .e-caption-input-text,
.e-pivotfieldlist-container .e-value-field-settings .e-value-field-div-content .e-field-option-container .e-type-option-container .e-caption-input-text,
.e-pivotfieldlist-container .e-value-field-settings .e-value-field-div-content .e-field-option-container .e-base-field-option-container .e-caption-input-text,
.e-pivotfieldlist-container .e-value-field-settings .e-value-field-div-content .e-field-option-container .e-base-item-option-container .e-caption-input-text {
  font-size: 13px;
}
.e-pivotfieldlist-container .e-adaptive-field-list-dialog,
.e-pivotfieldlist-container .e-member-editor-dialog,
.e-pivotfieldlist-container .e-value-field-settings {
  border-radius: 4px;
}
.e-pivotfieldlist-container .e-adaptive-field-list-dialog .e-dlg-header-content,
.e-pivotfieldlist-container .e-adaptive-field-list-dialog .e-footer-content,
.e-pivotfieldlist-container .e-member-editor-dialog .e-dlg-header-content,
.e-pivotfieldlist-container .e-member-editor-dialog .e-footer-content,
.e-pivotfieldlist-container .e-value-field-settings .e-dlg-header-content,
.e-pivotfieldlist-container .e-value-field-settings .e-footer-content {
  border-radius: 4px;
}
.e-pivotfieldlist-container .e-adaptive-field-list-dialog .e-dlg-header-content,
.e-pivotfieldlist-container .e-member-editor-dialog .e-dlg-header-content,
.e-pivotfieldlist-container .e-value-field-settings .e-dlg-header-content {
  border: 0;
  font-weight: normal;
}
.e-pivotfieldlist-container .e-adaptive-field-list-dialog .e-footer-content,
.e-pivotfieldlist-container .e-member-editor-dialog .e-footer-content,
.e-pivotfieldlist-container .e-value-field-settings .e-footer-content {
  border-top: 1px solid #e0e0e0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}
.e-pivotfieldlist-container .e-adaptive-field-list-dialog .e-dlg-content,
.e-pivotfieldlist-container .e-member-editor-dialog .e-dlg-content,
.e-pivotfieldlist-container .e-value-field-settings .e-dlg-content {
  overflow: hidden;
  padding: 0 !important;
  /* stylelint-enable */
}
.e-pivotfieldlist-container .e-adaptive-field-list-dialog .e-dlg-content .e-empty-field::-webkit-input-placeholder,
.e-pivotfieldlist-container .e-member-editor-dialog .e-dlg-content .e-empty-field::-webkit-input-placeholder,
.e-pivotfieldlist-container .e-value-field-settings .e-dlg-content .e-empty-field::-webkit-input-placeholder {
  color: #f44336 !important;
  font-weight: 600 !important;
}
.e-pivotfieldlist-container .e-adaptive-field-list-dialog .e-dlg-content .e-empty-field:-moz-placeholder,
.e-pivotfieldlist-container .e-member-editor-dialog .e-dlg-content .e-empty-field:-moz-placeholder,
.e-pivotfieldlist-container .e-value-field-settings .e-dlg-content .e-empty-field:-moz-placeholder {
  color: #f44336 !important;
  font-weight: 600 !important;
}
.e-pivotfieldlist-container .e-adaptive-field-list-dialog .e-dlg-content .e-empty-field::-moz-placeholder,
.e-pivotfieldlist-container .e-member-editor-dialog .e-dlg-content .e-empty-field::-moz-placeholder,
.e-pivotfieldlist-container .e-value-field-settings .e-dlg-content .e-empty-field::-moz-placeholder {
  color: #f44336 !important;
  font-weight: 600 !important;
}
.e-pivotfieldlist-container .e-adaptive-field-list-dialog .e-dlg-content .e-empty-field:-ms-input-placeholder,
.e-pivotfieldlist-container .e-member-editor-dialog .e-dlg-content .e-empty-field:-ms-input-placeholder,
.e-pivotfieldlist-container .e-value-field-settings .e-dlg-content .e-empty-field:-ms-input-placeholder {
  color: #f44336 !important;
  font-weight: 600 !important;
}
.e-pivotfieldlist-container .e-adaptive-field-list-dialog .e-dlg-content .e-member-editor-outer-container .e-member-editor-container-outer-div {
  height: 200px;
}

.e-field-list-tree.e-drag-item.e-treeview,
.e-pivot-calc.e-drag-item.e-treeview {
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  height: 28px;
  line-height: 1em;
  min-width: 100px;
  padding: 5px 10px;
}
.e-field-list-tree.e-drag-item.e-treeview .e-text-content,
.e-pivot-calc.e-drag-item.e-treeview .e-text-content {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  padding: 0;
  text-align: center;
  width: 100%;
}
.e-field-list-tree.e-drag-item.e-treeview .e-text-content .e-list-text,
.e-pivot-calc.e-drag-item.e-treeview .e-text-content .e-list-text {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  padding: 0;
  width: 100%;
}
.e-field-list-tree.e-drag-item.e-treeview .e-text-content > .e-icons,
.e-field-list-tree.e-drag-item.e-treeview .e-checkbox-wrapper,
.e-field-list-tree.e-drag-item.e-treeview .e-list-icon,
.e-pivot-calc.e-drag-item.e-treeview .e-text-content > .e-icons,
.e-pivot-calc.e-drag-item.e-treeview .e-checkbox-wrapper,
.e-pivot-calc.e-drag-item.e-treeview .e-list-icon {
  display: none;
}
.e-field-list-tree.e-drag-item.e-treeview.e-rtl .e-text-content,
.e-pivot-calc.e-drag-item.e-treeview.e-rtl .e-text-content {
  padding-right: 0;
}
.e-field-list-tree.e-drag-item.e-treeview.e-drag-restrict,
.e-pivot-calc.e-drag-item.e-treeview.e-drag-restrict {
  /* stylelint-disable */
  /* stylelint-enable */
}
.e-field-list-tree.e-drag-item.e-treeview.e-drag-restrict .e-text-content,
.e-pivot-calc.e-drag-item.e-treeview.e-drag-restrict .e-text-content {
  padding-left: 24px;
}
.e-field-list-tree.e-drag-item.e-treeview.e-drag-restrict .e-text-content .e-list-text,
.e-pivot-calc.e-drag-item.e-treeview.e-drag-restrict .e-text-content .e-list-text {
  padding: 0;
}
.e-field-list-tree.e-drag-item.e-treeview.e-drag-restrict .e-no-drop,
.e-pivot-calc.e-drag-item.e-treeview.e-drag-restrict .e-no-drop {
  display: inline-block !important;
  line-height: 0em;
}
.e-field-list-tree.e-drag-item.e-treeview.e-drag-restrict .e-no-drop::before,
.e-pivot-calc.e-drag-item.e-treeview.e-drag-restrict .e-no-drop::before {
  float: left;
  font-size: 14px;
  padding: 5px 0;
}
.e-field-list-tree.e-drag-item.e-treeview.e-drag-restrict.e-rtl .e-text-content,
.e-pivot-calc.e-drag-item.e-treeview.e-drag-restrict.e-rtl .e-text-content {
  padding-left: 0;
  padding-right: 24px;
}
.e-field-list-tree.e-drag-item.e-treeview.e-drag-restrict.e-rtl .e-text-content .e-icons,
.e-pivot-calc.e-drag-item.e-treeview.e-drag-restrict.e-rtl .e-text-content .e-icons {
  transform: rotateY(180deg);
}

.e-bigger .e-field-list-tree.e-drag-item.e-treeview,
.e-bigger .e-pivot-calc.e-drag-item.e-treeview,
.e-bigger.e-field-list-tree.e-drag-item.e-treeview,
.e-bigger.e-pivot-calc.e-drag-item.e-treeview {
  height: 36px;
  padding: 6px 12px;
}
.e-bigger .e-field-list-tree.e-drag-item.e-treeview.e-drag-restrict .e-no-drop::before,
.e-bigger .e-pivot-calc.e-drag-item.e-treeview.e-drag-restrict .e-no-drop::before,
.e-bigger.e-field-list-tree.e-drag-item.e-treeview.e-drag-restrict .e-no-drop::before,
.e-bigger.e-pivot-calc.e-drag-item.e-treeview.e-drag-restrict .e-no-drop::before {
  padding: 4px;
}

/* stylelint-disable */
.e-button-drag-clone {
  background: #fff !important;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif", "-apple-system", "BlinkMacSystemFont";
  height: 28px;
  line-height: 1em;
  min-width: 100px;
  overflow: hidden;
  padding: 5px 10px;
  text-align: center;
  width: auto;
  z-index: 100000;
}
.e-button-drag-clone .e-text-content {
  width: 100%;
}

.e-bigger .e-button-drag-clone,
.e-bigger.e-button-drag-clone {
  height: 36px !important;
  line-height: 1.5em;
  padding: 6px 12px;
}

.e-pivot-error-dialog {
  max-width: 400px !important;
  width: auto !important;
}

/* stylelint-enable */
.e-bigger .e-pivotfieldlist-container,
.e-bigger.e-pivotfieldlist-container {
  /* stylelint-disable */
  /* stylelint-enable */
}
.e-bigger .e-pivotfieldlist-container .e-member-editor-dialog .e-editor-search-container .e-sort-ascend-icon,
.e-bigger .e-pivotfieldlist-container .e-member-editor-dialog .e-editor-search-container .e-sort-descend-icon,
.e-bigger.e-pivotfieldlist-container .e-member-editor-dialog .e-editor-search-container .e-sort-ascend-icon,
.e-bigger.e-pivotfieldlist-container .e-member-editor-dialog .e-editor-search-container .e-sort-descend-icon {
  font-size: 18px;
}
.e-bigger .e-pivotfieldlist-container .e-member-editor-dialog .e-editor-search-container .e-level-drop .e-caret,
.e-bigger.e-pivotfieldlist-container .e-member-editor-dialog .e-editor-search-container .e-level-drop .e-caret {
  font-size: 16px !important;
}
.e-bigger .e-pivotfieldlist-container .e-member-editor-dialog .e-member-editor-outer-container ul li .e-calc-measure-icon,
.e-bigger .e-pivotfieldlist-container .e-member-editor-dialog .e-member-editor-outer-container ul li .e-calc-dimension-icon,
.e-bigger .e-pivotfieldlist-container .e-member-editor-dialog .e-member-editor-outer-container ul li .e-attributeCDB-icon,
.e-bigger .e-pivotfieldlist-container .e-member-editor-dialog .e-member-editor-outer-container ul li .e-hierarchyCDB-icon,
.e-bigger .e-pivotfieldlist-container .e-member-editor-dialog .e-member-editor-outer-container ul li .e-level-members,
.e-bigger .e-pivotfieldlist-container .e-member-editor-dialog .e-member-editor-outer-container ul li .e-namedSetCDB-icon,
.e-bigger .e-pivotfieldlist-container .e-member-editor-dialog .e-member-editor-outer-container ul li .e-measure-icon,
.e-bigger .e-pivotfieldlist-container .e-member-editor-dialog .e-member-editor-outer-container ul li .e-kpiGoal-icon,
.e-bigger .e-pivotfieldlist-container .e-member-editor-dialog .e-member-editor-outer-container ul li .e-kpiStatus-icon,
.e-bigger .e-pivotfieldlist-container .e-member-editor-dialog .e-member-editor-outer-container ul li .e-kpiTrend-icon,
.e-bigger .e-pivotfieldlist-container .e-member-editor-dialog .e-member-editor-outer-container ul li .e-kpiValue-icon,
.e-bigger.e-pivotfieldlist-container .e-member-editor-dialog .e-member-editor-outer-container ul li .e-calc-measure-icon,
.e-bigger.e-pivotfieldlist-container .e-member-editor-dialog .e-member-editor-outer-container ul li .e-calc-dimension-icon,
.e-bigger.e-pivotfieldlist-container .e-member-editor-dialog .e-member-editor-outer-container ul li .e-attributeCDB-icon,
.e-bigger.e-pivotfieldlist-container .e-member-editor-dialog .e-member-editor-outer-container ul li .e-hierarchyCDB-icon,
.e-bigger.e-pivotfieldlist-container .e-member-editor-dialog .e-member-editor-outer-container ul li .e-level-members,
.e-bigger.e-pivotfieldlist-container .e-member-editor-dialog .e-member-editor-outer-container ul li .e-namedSetCDB-icon,
.e-bigger.e-pivotfieldlist-container .e-member-editor-dialog .e-member-editor-outer-container ul li .e-measure-icon,
.e-bigger.e-pivotfieldlist-container .e-member-editor-dialog .e-member-editor-outer-container ul li .e-kpiGoal-icon,
.e-bigger.e-pivotfieldlist-container .e-member-editor-dialog .e-member-editor-outer-container ul li .e-kpiStatus-icon,
.e-bigger.e-pivotfieldlist-container .e-member-editor-dialog .e-member-editor-outer-container ul li .e-kpiTrend-icon,
.e-bigger.e-pivotfieldlist-container .e-member-editor-dialog .e-member-editor-outer-container ul li .e-kpiValue-icon {
  margin-left: 10px;
}
.e-bigger .e-pivotfieldlist-container .e-member-editor-dialog.e-rtl .e-member-editor-outer-container ul li .e-calc-measure-icon,
.e-bigger .e-pivotfieldlist-container .e-member-editor-dialog.e-rtl .e-member-editor-outer-container ul li .e-calc-dimension-icon,
.e-bigger .e-pivotfieldlist-container .e-member-editor-dialog.e-rtl .e-member-editor-outer-container ul li .e-attributeCDB-icon,
.e-bigger .e-pivotfieldlist-container .e-member-editor-dialog.e-rtl .e-member-editor-outer-container ul li .e-hierarchyCDB-icon,
.e-bigger .e-pivotfieldlist-container .e-member-editor-dialog.e-rtl .e-member-editor-outer-container ul li .e-level-members,
.e-bigger .e-pivotfieldlist-container .e-member-editor-dialog.e-rtl .e-member-editor-outer-container ul li .e-namedSetCDB-icon,
.e-bigger .e-pivotfieldlist-container .e-member-editor-dialog.e-rtl .e-member-editor-outer-container ul li .e-measure-icon,
.e-bigger .e-pivotfieldlist-container .e-member-editor-dialog.e-rtl .e-member-editor-outer-container ul li .e-kpiGoal-icon,
.e-bigger .e-pivotfieldlist-container .e-member-editor-dialog.e-rtl .e-member-editor-outer-container ul li .e-kpiStatus-icon,
.e-bigger .e-pivotfieldlist-container .e-member-editor-dialog.e-rtl .e-member-editor-outer-container ul li .e-kpiTrend-icon,
.e-bigger .e-pivotfieldlist-container .e-member-editor-dialog.e-rtl .e-member-editor-outer-container ul li .e-kpiValue-icon,
.e-bigger.e-pivotfieldlist-container .e-member-editor-dialog.e-rtl .e-member-editor-outer-container ul li .e-calc-measure-icon,
.e-bigger.e-pivotfieldlist-container .e-member-editor-dialog.e-rtl .e-member-editor-outer-container ul li .e-calc-dimension-icon,
.e-bigger.e-pivotfieldlist-container .e-member-editor-dialog.e-rtl .e-member-editor-outer-container ul li .e-attributeCDB-icon,
.e-bigger.e-pivotfieldlist-container .e-member-editor-dialog.e-rtl .e-member-editor-outer-container ul li .e-hierarchyCDB-icon,
.e-bigger.e-pivotfieldlist-container .e-member-editor-dialog.e-rtl .e-member-editor-outer-container ul li .e-level-members,
.e-bigger.e-pivotfieldlist-container .e-member-editor-dialog.e-rtl .e-member-editor-outer-container ul li .e-namedSetCDB-icon,
.e-bigger.e-pivotfieldlist-container .e-member-editor-dialog.e-rtl .e-member-editor-outer-container ul li .e-measure-icon,
.e-bigger.e-pivotfieldlist-container .e-member-editor-dialog.e-rtl .e-member-editor-outer-container ul li .e-kpiGoal-icon,
.e-bigger.e-pivotfieldlist-container .e-member-editor-dialog.e-rtl .e-member-editor-outer-container ul li .e-kpiStatus-icon,
.e-bigger.e-pivotfieldlist-container .e-member-editor-dialog.e-rtl .e-member-editor-outer-container ul li .e-kpiTrend-icon,
.e-bigger.e-pivotfieldlist-container .e-member-editor-dialog.e-rtl .e-member-editor-outer-container ul li .e-kpiValue-icon {
  margin-left: 10px;
}
.e-bigger .e-pivotfieldlist-container .e-member-editor-dialog .e-dlg-content .e-member-editor-outer-container:not(.e-excelfilter) .e-member-editor-container-outer-div,
.e-bigger.e-pivotfieldlist-container .e-member-editor-dialog .e-dlg-content .e-member-editor-outer-container:not(.e-excelfilter) .e-member-editor-container-outer-div {
  height: 120px;
}
.e-bigger .e-pivotfieldlist-container .e-member-editor-dialog,
.e-bigger.e-pivotfieldlist-container .e-member-editor-dialog {
  /* stylelint-disable */
}
.e-bigger .e-pivotfieldlist-container .e-member-editor-dialog .e-filter-tab-container .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-bigger.e-pivotfieldlist-container .e-member-editor-dialog .e-filter-tab-container .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text {
  margin: 0 0 0 6px;
}
.e-bigger .e-pivotfieldlist-container .e-member-editor-dialog .e-filter-tab-container.e-rtl .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-bigger.e-pivotfieldlist-container .e-member-editor-dialog .e-filter-tab-container.e-rtl .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text {
  margin: 0 6px 0 0;
}
.e-bigger .e-pivotfieldlist-container .e-member-editor-dialog.e-node-limit.e-excel-filter .e-excelfilter .e-member-editor-container-outer-div,
.e-bigger.e-pivotfieldlist-container .e-member-editor-dialog.e-node-limit.e-excel-filter .e-excelfilter .e-member-editor-container-outer-div {
  height: 90px !important;
}
.e-bigger .e-pivotfieldlist-container .e-member-editor-dialog .e-excelfilter .e-member-editor-container-outer-div,
.e-bigger.e-pivotfieldlist-container .e-member-editor-dialog .e-excelfilter .e-member-editor-container-outer-div {
  height: 100px !important;
}
.e-bigger .e-pivotfieldlist-container .e-member-editor-dialog .e-label-filter,
.e-bigger .e-pivotfieldlist-container .e-member-editor-dialog .e-value-filter,
.e-bigger.e-pivotfieldlist-container .e-member-editor-dialog .e-label-filter,
.e-bigger.e-pivotfieldlist-container .e-member-editor-dialog .e-value-filter {
  height: 220px;
}
.e-bigger .e-pivotfieldlist-container .e-member-editor-dialog .e-label-filter .e-filter-text-div,
.e-bigger .e-pivotfieldlist-container .e-member-editor-dialog .e-value-filter .e-filter-text-div,
.e-bigger.e-pivotfieldlist-container .e-member-editor-dialog .e-label-filter .e-filter-text-div,
.e-bigger.e-pivotfieldlist-container .e-member-editor-dialog .e-value-filter .e-filter-text-div {
  font-size: 14px;
}
.e-bigger .e-pivotfieldlist-container .e-member-editor-dialog .e-label-filter .e-filter-option-container-1,
.e-bigger .e-pivotfieldlist-container .e-member-editor-dialog .e-label-filter .e-filter-option-container-2,
.e-bigger .e-pivotfieldlist-container .e-member-editor-dialog .e-label-filter .e-separator-div,
.e-bigger .e-pivotfieldlist-container .e-member-editor-dialog .e-value-filter .e-filter-option-container-1,
.e-bigger .e-pivotfieldlist-container .e-member-editor-dialog .e-value-filter .e-filter-option-container-2,
.e-bigger .e-pivotfieldlist-container .e-member-editor-dialog .e-value-filter .e-separator-div,
.e-bigger.e-pivotfieldlist-container .e-member-editor-dialog .e-label-filter .e-filter-option-container-1,
.e-bigger.e-pivotfieldlist-container .e-member-editor-dialog .e-label-filter .e-filter-option-container-2,
.e-bigger.e-pivotfieldlist-container .e-member-editor-dialog .e-label-filter .e-separator-div,
.e-bigger.e-pivotfieldlist-container .e-member-editor-dialog .e-value-filter .e-filter-option-container-1,
.e-bigger.e-pivotfieldlist-container .e-member-editor-dialog .e-value-filter .e-filter-option-container-2,
.e-bigger.e-pivotfieldlist-container .e-member-editor-dialog .e-value-filter .e-separator-div {
  padding-top: 24px;
}
.e-bigger .e-pivotfieldlist-container .e-member-editor-dialog .e-label-filter .e-between-text-div,
.e-bigger .e-pivotfieldlist-container .e-member-editor-dialog .e-value-filter .e-between-text-div,
.e-bigger.e-pivotfieldlist-container .e-member-editor-dialog .e-label-filter .e-between-text-div,
.e-bigger.e-pivotfieldlist-container .e-member-editor-dialog .e-value-filter .e-between-text-div {
  padding: 10px 0;
}
.e-bigger .e-pivotfieldlist-container .e-member-editor-dialog.e-excel-filter,
.e-bigger.e-pivotfieldlist-container .e-member-editor-dialog.e-excel-filter {
  min-width: 350px !important;
  min-height: 400px !important;
}
.e-bigger .e-pivotfieldlist-container .e-member-editor-dialog.e-excel-filter.e-node-limit,
.e-bigger.e-pivotfieldlist-container .e-member-editor-dialog.e-excel-filter.e-node-limit {
  min-height: 415px !important;
}
.e-bigger .e-pivotfieldlist-container .e-adaptive-field-list-dialog .e-dlg-header-content .e-clear-filter-button.e-small,
.e-bigger .e-pivotfieldlist-container .e-member-editor-dialog .e-dlg-header-content .e-clear-filter-button.e-small,
.e-bigger .e-pivotfieldlist-container .e-value-field-settings .e-dlg-header-content .e-clear-filter-button.e-small,
.e-bigger.e-pivotfieldlist-container .e-adaptive-field-list-dialog .e-dlg-header-content .e-clear-filter-button.e-small,
.e-bigger.e-pivotfieldlist-container .e-member-editor-dialog .e-dlg-header-content .e-clear-filter-button.e-small,
.e-bigger.e-pivotfieldlist-container .e-value-field-settings .e-dlg-header-content .e-clear-filter-button.e-small {
  font-size: 14px;
  top: 0;
}
.e-bigger .e-pivotfieldlist-container .e-value-field-settings .e-value-field-div-content,
.e-bigger.e-pivotfieldlist-container .e-value-field-settings .e-value-field-div-content {
  padding: 0 20px 10px;
}
.e-bigger .e-pivotfieldlist-container .e-value-field-settings .e-field-name-text-container,
.e-bigger.e-pivotfieldlist-container .e-value-field-settings .e-field-name-text-container {
  display: -ms-flexbox;
  display: flex;
}
.e-bigger .e-pivotfieldlist-container .e-value-field-settings .e-field-name-text-container .e-field-name-title,
.e-bigger .e-pivotfieldlist-container .e-value-field-settings .e-field-name-text-container .e-field-name-content,
.e-bigger.e-pivotfieldlist-container .e-value-field-settings .e-field-name-text-container .e-field-name-title,
.e-bigger.e-pivotfieldlist-container .e-value-field-settings .e-field-name-text-container .e-field-name-content {
  font-size: 14px;
}
.e-bigger .e-pivotfieldlist-container .e-value-field-settings .e-caption-input-container,
.e-bigger .e-pivotfieldlist-container .e-value-field-settings .e-type-option-container,
.e-bigger .e-pivotfieldlist-container .e-value-field-settings .e-base-field-option-container,
.e-bigger .e-pivotfieldlist-container .e-value-field-settings .e-base-item-option-container,
.e-bigger.e-pivotfieldlist-container .e-value-field-settings .e-caption-input-container,
.e-bigger.e-pivotfieldlist-container .e-value-field-settings .e-type-option-container,
.e-bigger.e-pivotfieldlist-container .e-value-field-settings .e-base-field-option-container,
.e-bigger.e-pivotfieldlist-container .e-value-field-settings .e-base-item-option-container {
  padding-top: 24px;
}
.e-bigger .e-pivotfieldlist-container .e-value-field-settings .e-caption-input-container .e-base-field-option-text,
.e-bigger .e-pivotfieldlist-container .e-value-field-settings .e-caption-input-container .e-base-item-option-text,
.e-bigger .e-pivotfieldlist-container .e-value-field-settings .e-caption-input-container .e-type-option-text,
.e-bigger .e-pivotfieldlist-container .e-value-field-settings .e-caption-input-container .e-caption-input-text,
.e-bigger .e-pivotfieldlist-container .e-value-field-settings .e-type-option-container .e-base-field-option-text,
.e-bigger .e-pivotfieldlist-container .e-value-field-settings .e-type-option-container .e-base-item-option-text,
.e-bigger .e-pivotfieldlist-container .e-value-field-settings .e-type-option-container .e-type-option-text,
.e-bigger .e-pivotfieldlist-container .e-value-field-settings .e-type-option-container .e-caption-input-text,
.e-bigger .e-pivotfieldlist-container .e-value-field-settings .e-base-field-option-container .e-base-field-option-text,
.e-bigger .e-pivotfieldlist-container .e-value-field-settings .e-base-field-option-container .e-base-item-option-text,
.e-bigger .e-pivotfieldlist-container .e-value-field-settings .e-base-field-option-container .e-type-option-text,
.e-bigger .e-pivotfieldlist-container .e-value-field-settings .e-base-field-option-container .e-caption-input-text,
.e-bigger .e-pivotfieldlist-container .e-value-field-settings .e-base-item-option-container .e-base-field-option-text,
.e-bigger .e-pivotfieldlist-container .e-value-field-settings .e-base-item-option-container .e-base-item-option-text,
.e-bigger .e-pivotfieldlist-container .e-value-field-settings .e-base-item-option-container .e-type-option-text,
.e-bigger .e-pivotfieldlist-container .e-value-field-settings .e-base-item-option-container .e-caption-input-text,
.e-bigger.e-pivotfieldlist-container .e-value-field-settings .e-caption-input-container .e-base-field-option-text,
.e-bigger.e-pivotfieldlist-container .e-value-field-settings .e-caption-input-container .e-base-item-option-text,
.e-bigger.e-pivotfieldlist-container .e-value-field-settings .e-caption-input-container .e-type-option-text,
.e-bigger.e-pivotfieldlist-container .e-value-field-settings .e-caption-input-container .e-caption-input-text,
.e-bigger.e-pivotfieldlist-container .e-value-field-settings .e-type-option-container .e-base-field-option-text,
.e-bigger.e-pivotfieldlist-container .e-value-field-settings .e-type-option-container .e-base-item-option-text,
.e-bigger.e-pivotfieldlist-container .e-value-field-settings .e-type-option-container .e-type-option-text,
.e-bigger.e-pivotfieldlist-container .e-value-field-settings .e-type-option-container .e-caption-input-text,
.e-bigger.e-pivotfieldlist-container .e-value-field-settings .e-base-field-option-container .e-base-field-option-text,
.e-bigger.e-pivotfieldlist-container .e-value-field-settings .e-base-field-option-container .e-base-item-option-text,
.e-bigger.e-pivotfieldlist-container .e-value-field-settings .e-base-field-option-container .e-type-option-text,
.e-bigger.e-pivotfieldlist-container .e-value-field-settings .e-base-field-option-container .e-caption-input-text,
.e-bigger.e-pivotfieldlist-container .e-value-field-settings .e-base-item-option-container .e-base-field-option-text,
.e-bigger.e-pivotfieldlist-container .e-value-field-settings .e-base-item-option-container .e-base-item-option-text,
.e-bigger.e-pivotfieldlist-container .e-value-field-settings .e-base-item-option-container .e-type-option-text,
.e-bigger.e-pivotfieldlist-container .e-value-field-settings .e-base-item-option-container .e-caption-input-text {
  font-size: 14px;
}

/*! field-list theme */
.e-pivotfieldlist .e-member-editor-dialog,
.e-pivotfieldlist .e-olap-editor-dialog {
  visibility: hidden;
}
.e-pivotfieldlist .e-select-table {
  background: #fafafa;
  border: 0.5px solid rgba(0, 0, 0, 0.12);
  box-shadow: -1px 1px 4px 0 rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.54);
  font-size: 17px;
  height: 31px;
  max-height: 31px;
  max-width: 31px;
  min-height: 31px;
  min-width: 31px;
  padding: 8px;
  position: relative;
  width: 31px;
  z-index: 1000;
}
.e-pivotfieldlist .e-select-table.e-fieldlist-left {
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.12);
}
.e-pivotfieldlist .e-select-table:focus, .e-pivotfieldlist .e-select-table:active {
  background-color: #e0e0e0;
}
.e-pivotfieldlist .e-select-table:hover {
  background-color: #e0e0e0;
}

/* stylelint-disable */
*.e-rtl .e-select-table {
  padding: 8px 5px;
}

.e-bigger .e-pivotfieldlist .e-select-table,
.e-bigger.e-pivotfieldlist .e-select-table {
  font-size: 20px;
  height: 36px;
  max-height: 36px;
  max-width: 36px;
  min-height: 36px;
  min-width: 36px;
}
.e-bigger .e-pivotfieldlist .e-member-editor-dialog,
.e-bigger .e-pivotfieldlist .e-olap-editor-dialog,
.e-bigger.e-pivotfieldlist .e-member-editor-dialog,
.e-bigger.e-pivotfieldlist .e-olap-editor-dialog {
  max-height: 440px !important;
  min-width: 370px !important;
}
.e-bigger .e-pivotfieldlist .e-member-editor-dialog .e-member-editor-container-outer-div,
.e-bigger .e-pivotfieldlist .e-olap-editor-dialog .e-member-editor-container-outer-div,
.e-bigger.e-pivotfieldlist .e-member-editor-dialog .e-member-editor-container-outer-div,
.e-bigger.e-pivotfieldlist .e-olap-editor-dialog .e-member-editor-container-outer-div {
  min-height: 75px !important;
}
.e-bigger .e-pivotfieldlist .e-member-editor-dialog .e-member-editor-container-outer-div .e-list-parent .e-ul,
.e-bigger .e-pivotfieldlist .e-olap-editor-dialog .e-member-editor-container-outer-div .e-list-parent .e-ul,
.e-bigger.e-pivotfieldlist .e-member-editor-dialog .e-member-editor-container-outer-div .e-list-parent .e-ul,
.e-bigger.e-pivotfieldlist .e-olap-editor-dialog .e-member-editor-container-outer-div .e-list-parent .e-ul {
  height: auto;
}

.e-pivotfieldlist-container {
  font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif", "-apple-system", "BlinkMacSystemFont";
  font-size: 12px;
  max-width: 700px;
  min-width: 300px;
  /* stylelint-enable */
}
.e-pivotfieldlist-container.sf-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-list {
  display: block;
  overflow-y: auto;
}
.e-pivotfieldlist-container.sf-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-list.e-olap-field-list-tree.e-treeview ul:first-child {
  padding-right: 0;
}
.e-pivotfieldlist-container.sf-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-list.e-olap-field-list-tree.e-treeview li.e-no-checkbox > .e-text-content > .e-checkbox-wrapper, .e-pivotfieldlist-container.sf-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-list.e-olap-field-list-tree.e-treeview li .e-measure-icon {
  display: none;
}
.e-pivotfieldlist-container.sf-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-list.e-olap-field-list-tree.e-treeview li.e-rtl ul:first-child {
  padding-left: 0;
  padding-right: 10px;
}
.e-pivotfieldlist-container.sf-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-list li .e-drag {
  padding: revert !important;
  margin: revert !important;
  width: auto !important;
  height: auto !important;
}
.e-pivotfieldlist-container.sf-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-list li .e-draggable {
  display: contents;
}
.e-pivotfieldlist-container.sf-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-list li .e-text-content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}
.e-pivotfieldlist-container.sf-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-list li .e-list-text {
  text-overflow: ellipsis;
  overflow-x: hidden;
  width: 100%;
  white-space: nowrap;
}
.e-pivotfieldlist-container.sf-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-list.e-treeview:not(.e-rtl) .e-list-text {
  padding-right: revert;
}
.e-pivotfieldlist-container.sf-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-list.e-treeview.e-rtl .e-list-text {
  padding-left: revert;
}
.e-pivotfieldlist-container.sf-pivotfieldlist-container .e-adaptive-field-list-dialog .e-member-editor-container li.e-no-checkbox > .e-text-content > .e-checkbox-wrapper, .e-pivotfieldlist-container.sf-pivotfieldlist-container .e-adaptive-field-list-dialog .e-member-editor-container li .e-measure-icon {
  display: none;
}
.e-pivotfieldlist-container .e-layout-footer {
  display: -ms-flexbox;
  display: flex;
  padding: 10px 2px 2px;
}
.e-pivotfieldlist-container .e-layout-footer .e-button-layout {
  -ms-flex: auto;
      flex: auto;
  text-align: end;
}
.e-pivotfieldlist-container .e-layout-footer .e-button-layout .e-defer-apply-button {
  margin-right: 10px;
}
.e-pivotfieldlist-container .e-layout-footer .e-button-layout .e-cancel-btn,
.e-pivotfieldlist-container .e-layout-footer .e-button-layout .e-defer-apply-button {
  min-width: fit-content;
}
.e-pivotfieldlist-container .e-layout-footer .e-cancel-btn,
.e-pivotfieldlist-container .e-layout-footer .e-defer-apply-button,
.e-pivotfieldlist-container .e-layout-footer .e-checkbox-layout {
  max-width: 25%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.e-pivotfieldlist-container .e-layout-footer .e-checkbox-layout .e-label {
  padding-right: 10px;
}
.e-pivotfieldlist-container .e-layout-footer .e-checkbox-layout {
  max-width: 45%;
  overflow: visible;
}
.e-pivotfieldlist-container .e-checkbox-layout {
  float: left;
  margin-top: 6px;
}
.e-pivotfieldlist-container.e-rtl .e-layout-footer .e-defer-apply-button {
  margin-left: 10px;
}
.e-pivotfieldlist-container.e-rtl .e-checkbox-layout {
  float: right;
}
.e-pivotfieldlist-container.e-dialog {
  max-height: 550px !important;
}
.e-pivotfieldlist-container.e-dialog .e-layout-footer {
  padding: 0;
}
.e-pivotfieldlist-container.e-dialog .e-checkbox-layout {
  margin-left: 10px;
}
.e-pivotfieldlist-container.e-dialog .e-button-layout {
  margin-right: 10px;
}
.e-pivotfieldlist-container .e-dlg-header-content,
.e-pivotfieldlist-container .e-footer-content {
  border: 0;
}
.e-pivotfieldlist-container .e-field-list-title {
  display: -ms-flexbox;
  display: flex;
  font-size: 18px;
  min-height: 25px;
  padding: 0 2px 20px;
  width: 100%;
}
.e-pivotfieldlist-container .e-field-list-title .e-title-content {
  -ms-flex: auto;
      flex: auto;
}
.e-pivotfieldlist-container .e-title-header {
  display: -ms-flexbox;
  display: flex;
  padding: 2px;
  width: 100%;
}
.e-pivotfieldlist-container .e-title-header .e-title-content {
  -ms-flex: auto;
      flex: auto;
}
.e-pivotfieldlist-container .e-field-list-container {
  display: -ms-flexbox;
  display: flex;
  height: 342px;
  padding: 1px;
  position: relative;
}
.e-pivotfieldlist-container .e-field-list-container .e-field-table {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  display: inline-block;
  overflow: hidden;
  width: 30%;
}
.e-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-list-tree .e-sibling {
  display: none;
}
.e-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-header,
.e-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-list {
  width: 100%;
}
.e-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-header {
  background: #f5f5f5;
  color: rgba(0, 0, 0, 0.54);
  font-size: 13px;
  font-weight: normal;
  overflow: hidden;
  padding: 0 0 0 15px;
  text-align: left;
  text-overflow: ellipsis;
  word-break: break-all;
}
.e-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-header-container {
  -ms-flex-align: center;
      align-items: center;
  background: #f5f5f5;
  color: rgba(0, 0, 0, 0.54);
  display: -ms-flexbox;
  display: flex;
  height: 28px;
  padding-right: 15px;
  /* stylelint-disable */
  /* stylelint-enable */
}
.e-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-header-container .e-field-header {
  -ms-flex: auto;
      flex: auto;
}
.e-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-header-container .e-sort-none,
.e-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-header-container .e-sort-ascend,
.e-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-header-container .e-sort-descend {
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  font-size: 14px;
  padding: 0 0 0 4px;
  width: 20px;
}
.e-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-header-container .e-sort-none.e-selected::before,
.e-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-header-container .e-sort-ascend.e-selected::before,
.e-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-header-container .e-sort-descend.e-selected::before {
  color: #e3165b;
}
.e-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-header-container .e-sort-none:hover,
.e-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-header-container .e-sort-ascend:hover,
.e-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-header-container .e-sort-descend:hover {
  font-weight: bold;
}
.e-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-header-container .e-sort-none:focus,
.e-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-header-container .e-sort-ascend:focus,
.e-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-header-container .e-sort-descend:focus {
  font-weight: bold;
}
.e-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-list-tree-outer-div {
  display: inline-block;
  height: 310px;
  overflow: auto;
  width: 100%;
}
.e-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-list {
  display: inline-table;
  height: 100%;
  padding-left: 2px;
  width: 100%;
}
.e-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-list:not(.e-olap-field-list-tree) ul {
  padding-left: 4px;
}
.e-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-list:not(.e-olap-field-list-tree) ul li:not(.e-pivot-parent):not(.e-pivot-child) .e-text-content {
  padding-left: 2px;
}
.e-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-list ul {
  overflow: hidden;
  /* stylelint-disable */
  /* stylelint-enable */
}
.e-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-list ul li {
  /* stylelint-disable */
  /* stylelint-enable */
}
.e-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-list ul li .e-fullrow {
  cursor: default;
}
.e-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-list ul li .e-drag {
  color: #bdbdbd;
  cursor: move;
  display: inline-block;
  font-size: 14px;
  height: 20px;
  margin-bottom: 2px;
  margin-right: 6px;
  pointer-events: all;
  text-align: center;
  vertical-align: bottom;
  width: 20px;
}
.e-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-list ul li .e-drag.e-disable-drag {
  cursor: not-allowed;
  opacity: 0.4;
  visibility: hidden;
}
.e-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-list ul li .e-list-icon {
  color: rgba(0, 0, 0, 0.54);
  margin: 0;
}
.e-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-list ul li .e-calc-measure-icon,
.e-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-list ul li .e-calc-dimension-icon,
.e-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-list ul li .e-attributeCDB-icon,
.e-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-list ul li .e-hierarchyCDB-icon,
.e-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-list ul li .e-level-members,
.e-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-list ul li .e-namedSetCDB-icon,
.e-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-list ul li .e-measure-icon,
.e-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-list ul li .e-kpiGoal-icon,
.e-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-list ul li .e-kpiStatus-icon,
.e-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-list ul li .e-kpiTrend-icon,
.e-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-list ul li .e-kpiValue-icon {
  margin-left: 10px;
}
.e-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-list ul li .e-list-selected {
  opacity: 0.5;
}
.e-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-list ul li .e-selected-node {
  font-weight: 500;
}
.e-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-list ul li .e-list-text {
  font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif", "-apple-system", "BlinkMacSystemFont";
}
.e-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-list ul li.e-disable {
  display: none !important;
}
.e-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-list.e-olap-field-list-tree ul .e-list-icon {
  font-size: 14px;
  height: auto;
  width: auto;
}
.e-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-list.e-olap-field-list-tree.e-treeview ul:first-child {
  padding-left: 10px;
  padding-right: 10px;
}
.e-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-list.e-olap-field-list-tree.e-rtl ul .e-list-icon {
  transform: rotateX(0deg) rotateY(180deg);
}
.e-pivotfieldlist-container .e-field-list-container .e-field-table.e-olap-field-table {
  width: 40%;
}
.e-pivotfieldlist-container .e-field-list-container .e-axis-table {
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 70%;
}
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields {
  padding-left: 8px;
  width: 50%;
}
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  fill: #fff;
  font-weight: normal;
  min-height: 165px;
  position: relative;
  width: 100%;
}
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-container,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-container,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-container,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-container {
  -ms-flex-align: center;
      align-items: center;
  background: #f5f5f5;
  border-bottom: 1px solid #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
  display: -ms-flexbox;
  display: flex;
  height: 28px;
  padding: 0 15px 0;
}
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-container .e-axis-icon-container,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-container .e-axis-icon-container,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-container .e-axis-icon-container,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-container .e-axis-icon-container {
  display: -ms-flexbox;
  display: flex;
  height: 14px;
  width: 20px;
}
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-container .e-axis-icon-container .e-axis-rows,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-container .e-axis-icon-container .e-axis-columns,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-container .e-axis-icon-container .e-axis-values,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-container .e-axis-icon-container .e-axis-filters,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-container .e-axis-icon-container .e-axis-rows,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-container .e-axis-icon-container .e-axis-columns,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-container .e-axis-icon-container .e-axis-values,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-container .e-axis-icon-container .e-axis-filters,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-container .e-axis-icon-container .e-axis-rows,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-container .e-axis-icon-container .e-axis-columns,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-container .e-axis-icon-container .e-axis-values,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-container .e-axis-icon-container .e-axis-filters,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-container .e-axis-icon-container .e-axis-rows,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-container .e-axis-icon-container .e-axis-columns,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-container .e-axis-icon-container .e-axis-values,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-container .e-axis-icon-container .e-axis-filters {
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
}
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-header,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-header,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-header,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-header {
  -ms-flex: auto;
      flex: auto;
  font-size: 13px;
  line-height: 1.5em;
  overflow: hidden;
  padding: 0 8px;
  text-overflow: ellipsis;
  text-transform: none;
  width: 100%;
}
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content {
  cursor: default;
  width: 100%;
}
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content.e-drag-restrict,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content.e-drag-restrict,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content.e-drag-restrict,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content.e-drag-restrict {
  cursor: no-drop;
}
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-drop-indicator-last,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-drop-indicator,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-drop-indicator-last,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-drop-indicator,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-drop-indicator-last,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-drop-indicator,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-drop-indicator-last,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-drop-indicator {
  display: block;
  pointer-events: none;
  width: 100%;
}
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-drop-indicator-last.e-drop-hover,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-drop-indicator.e-drop-hover,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-drop-indicator-last.e-drop-hover,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-drop-indicator.e-drop-hover,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-drop-indicator-last.e-drop-hover,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-drop-indicator.e-drop-hover,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-drop-indicator-last.e-drop-hover,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-drop-indicator.e-drop-hover {
  border-top: 2px dashed #9e9e9e;
  margin-top: -2px;
}
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div.e-first-btn .e-drop-indicator.e-drop-hover,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div.e-first-btn .e-drop-indicator.e-drop-hover,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div.e-first-btn .e-drop-indicator.e-drop-hover,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div.e-first-btn .e-drop-indicator.e-drop-hover {
  position: absolute;
}
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button {
  background: #fafafa;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-left: 0;
  border-radius: 0;
  border-right: 0;
  border-top: 0;
  box-shadow: none;
  cursor: default;
  display: -ms-flexbox;
  display: flex;
  font-weight: normal;
  height: 32px;
  line-height: 1em;
  padding: 9px 12px;
  text-transform: none;
  width: 100%;
}
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button.e-list-selected,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button.e-list-selected,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button.e-list-selected,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button.e-list-selected {
  color: #000;
  opacity: 0.5;
}
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button:hover,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button:hover,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button:hover,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button:hover {
  background-color: #eee;
}
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button:hover .e-pvt-btn-content,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button:hover .e-pvt-btn-content,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button:hover .e-pvt-btn-content,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button:hover .e-pvt-btn-content {
  background-color: transparent;
}
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button:focus, .e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button:active,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button:focus,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button:active,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button:focus,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button:active,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button:focus,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button:active {
  background-color: #e0e0e0;
  color: #000;
}
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button:focus .e-pvt-btn-content, .e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button:active .e-pvt-btn-content,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button:focus .e-pvt-btn-content,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button:active .e-pvt-btn-content,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button:focus .e-pvt-btn-content,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button:active .e-pvt-btn-content,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button:focus .e-pvt-btn-content,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button:active .e-pvt-btn-content {
  background-color: transparent;
}
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button:focus .e-sort,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button:focus .e-pv-filter,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button:focus .e-pv-filtered,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button:focus .e-dropdown-icon,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button:focus .e-edit, .e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button:active .e-sort,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button:active .e-pv-filter,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button:active .e-pv-filtered,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button:active .e-dropdown-icon,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button:active .e-edit,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button:focus .e-sort,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button:focus .e-pv-filter,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button:focus .e-pv-filtered,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button:focus .e-dropdown-icon,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button:focus .e-edit,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button:active .e-sort,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button:active .e-pv-filter,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button:active .e-pv-filtered,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button:active .e-dropdown-icon,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button:active .e-edit,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button:focus .e-sort,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button:focus .e-pv-filter,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button:focus .e-pv-filtered,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button:focus .e-dropdown-icon,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button:focus .e-edit,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button:active .e-sort,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button:active .e-pv-filter,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button:active .e-pv-filtered,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button:active .e-dropdown-icon,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button:active .e-edit,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button:focus .e-sort,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button:focus .e-pv-filter,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button:focus .e-pv-filtered,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button:focus .e-dropdown-icon,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button:focus .e-edit,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button:active .e-sort,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button:active .e-pv-filter,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button:active .e-pv-filtered,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button:active .e-dropdown-icon,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button:active .e-edit {
  color: #000;
}
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pvt-btn-content,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pvt-btn-content,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pvt-btn-content,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pvt-btn-content {
  background: transparent;
  -ms-flex: auto;
      flex: auto;
  font-size: 13px;
  height: 16px;
  line-height: 1em;
  overflow: hidden;
  padding: 0 8px;
  text-align: left;
  text-overflow: ellipsis;
  width: 70%;
}
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-draggable,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-draggable,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-draggable,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-draggable {
  height: 20px;
  width: 20px;
}
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-drag,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-drag,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-drag,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-drag {
  color: #bdbdbd;
  cursor: move;
  font-size: 14px;
  height: 20px;
  width: 20px;
}
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-drag.e-disable-drag,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-drag.e-disable-drag,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-drag.e-disable-drag,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-drag.e-disable-drag {
  cursor: not-allowed;
  opacity: 0.4;
  visibility: hidden;
}
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-edit,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-edit,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-edit,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-edit {
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  height: 20px;
  padding-left: 4px;
  width: 20px;
}
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort:hover,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filter:hover,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filtered:hover,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-dropdown-icon:hover,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-edit:hover,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort:hover,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filter:hover,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filtered:hover,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-dropdown-icon:hover,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-edit:hover,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort:hover,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filter:hover,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filtered:hover,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-dropdown-icon:hover,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-edit:hover,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort:hover,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filter:hover,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filtered:hover,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-dropdown-icon:hover,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-edit:hover {
  color: rgba(0, 0, 0, 0.87);
}
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort.e-disable,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filter.e-disable,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filtered.e-disable,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-dropdown-icon.e-disable,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-edit.e-disable,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort.e-disable,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filter.e-disable,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filtered.e-disable,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-dropdown-icon.e-disable,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-edit.e-disable,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort.e-disable,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filter.e-disable,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filtered.e-disable,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-dropdown-icon.e-disable,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-edit.e-disable,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort.e-disable,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filter.e-disable,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filtered.e-disable,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-dropdown-icon.e-disable,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-edit.e-disable {
  display: none;
}
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort {
  height: 14px;
}
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-remove,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-remove,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-remove,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-remove {
  display: none;
}
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-pvt-btn-content,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-pvt-btn-content,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-pvt-btn-content,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-pvt-btn-content {
  text-align: right;
}
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-sort,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-pv-filter,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-pv-filtered,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-remove,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-dropdown-icon,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-edit,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-sort,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-pv-filter,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-pv-filtered,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-remove,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-dropdown-icon,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-edit,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-sort,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-pv-filter,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-pv-filtered,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-remove,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-dropdown-icon,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-edit,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-sort,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-pv-filter,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-pv-filtered,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-remove,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-dropdown-icon,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-edit {
  padding-left: 0;
  padding-right: 4px;
}
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-sort,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-sort,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-sort,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-sort {
  height: fit-content;
  transform: rotateX(0deg);
  transition: transform 200ms ease-in-out;
}
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-sort.e-descend,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-sort.e-descend,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-sort.e-descend,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-sort.e-descend {
  transform: rotateX(180deg);
}
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content {
  height: 133px;
  overflow: auto;
  scroll-behavior: smooth;
}
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-draggable-prompt,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-draggable-prompt,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-draggable-prompt,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-draggable-prompt {
  bottom: 32%;
  color: #e0e0e0;
  font-size: 12px;
  height: 25px;
  pointer-events: none;
  position: absolute;
  text-align: center;
  width: 100%;
}
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-draggable-prompt.e-disable,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-draggable-prompt.e-disable,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-draggable-prompt.e-disable,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-draggable-prompt.e-disable {
  display: none;
}
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows,
.e-pivotfieldlist-container .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values {
  margin-top: 8px;
}
.e-pivotfieldlist-container .e-field-list-container .e-axis-table.e-olap-axis-table {
  width: 60%;
}
.e-pivotfieldlist-container.e-search-mode.e-static {
  max-height: 750px;
}
.e-pivotfieldlist-container.e-search-mode.e-static .e-field-list-container .e-field-table {
  height: 261px;
}
.e-pivotfieldlist-container.e-search-mode.e-popup {
  min-height: 520px;
}
.e-pivotfieldlist-container.e-search-mode.e-popup .e-field-list-container {
  height: 369px;
  /* stylelint-disable */
  /* stylelint-enable */
}
.e-pivotfieldlist-container.e-search-mode.e-popup .e-field-list-container .e-field-table {
  height: 392px !important;
}
.e-pivotfieldlist-container.e-search-mode.e-popup .e-field-list-container .e-axis-table {
  height: 392px;
}
.e-pivotfieldlist-container.e-search-mode.e-popup .e-field-list-container .e-axis-table .e-axis-content {
  height: 162px !important;
}
.e-pivotfieldlist-container.e-search-mode .e-member-prompt {
  color: #bdbdbd;
  padding: 25px 12px;
  text-align: center;
  width: 100%;
}
.e-pivotfieldlist-container.e-search-mode .e-member-prompt.e-disable {
  display: none;
}
.e-pivotfieldlist-container.e-search-mode .e-field-table .e-field-list-search {
  display: -ms-flexbox;
  display: flex;
  padding: 9px 20px;
  /* stylelint-disable */
}
.e-pivotfieldlist-container.e-search-mode .e-field-table .e-field-list-search .e-field-list-search-input .e-field-list-search-icon {
  float: right;
  font-size: 14px;
}
.e-pivotfieldlist-container.e-search-mode .e-field-table .e-field-list-search .e-input-group .e-input-group-icon,
.e-pivotfieldlist-container.e-search-mode .e-field-table .e-field-list-search .e-input-group.e-control-wrapper .e-input-group-icon {
  border-width: 0 !important;
  cursor: text !important;
}
.e-pivotfieldlist-container.e-search-mode .e-field-table .e-field-list-search .e-input-group-icon:hover {
  background-color: transparent !important;
  border: transparent !important;
  color: currentColor !important;
}
.e-pivotfieldlist-container.e-search-mode .e-field-table .e-field-list-tree-outer-div .e-disable,
.e-pivotfieldlist-container.e-search-mode .e-field-table .e-disable {
  display: none !important;
}
.e-pivotfieldlist-container .e-calculated-field {
  color: #000;
  font-size: 13px;
  font-weight: 500;
  max-width: 160px;
  min-width: 145px;
}
.e-pivotfieldlist-container .e-calculated-field.e-disable {
  display: none;
}
.e-pivotfieldlist-container.e-rtl .e-field-table .e-field-list {
  padding-left: 0;
  padding-right: 2px;
}
.e-pivotfieldlist-container.e-rtl .e-field-table .e-field-list:not(.e-olap-field-list-tree) ul {
  padding-right: 4px;
}
.e-pivotfieldlist-container.e-rtl .e-field-table .e-field-list:not(.e-olap-field-list-tree) ul li:not(.e-pivot-parent):not(.e-pivot-child) .e-text-content {
  padding-right: 2px;
}
.e-pivotfieldlist-container.e-rtl .e-field-table .e-field-list ul li {
  /* stylelint-disable */
  /* stylelint-enable */
}
.e-pivotfieldlist-container.e-rtl .e-field-table .e-field-list ul li .e-list-text {
  font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif", "-apple-system", "BlinkMacSystemFont";
}
.e-pivotfieldlist-container.e-rtl .e-field-table .e-field-list ul li .e-drag {
  margin-left: 6px !important;
  margin-right: 0 !important;
}
.e-pivotfieldlist-container.e-rtl .e-field-table .e-field-list ul li .e-calc-measure-icon,
.e-pivotfieldlist-container.e-rtl .e-field-table .e-field-list ul li .e-calc-dimension-icon,
.e-pivotfieldlist-container.e-rtl .e-field-table .e-field-list ul li .e-attributeCDB-icon,
.e-pivotfieldlist-container.e-rtl .e-field-table .e-field-list ul li .e-hierarchyCDB-icon,
.e-pivotfieldlist-container.e-rtl .e-field-table .e-field-list ul li .e-level-members,
.e-pivotfieldlist-container.e-rtl .e-field-table .e-field-list ul li .e-namedSetCDB-icon,
.e-pivotfieldlist-container.e-rtl .e-field-table .e-field-list ul li .e-measure-icon,
.e-pivotfieldlist-container.e-rtl .e-field-table .e-field-list ul li .e-kpiGoal-icon,
.e-pivotfieldlist-container.e-rtl .e-field-table .e-field-list ul li .e-kpiStatus-icon,
.e-pivotfieldlist-container.e-rtl .e-field-table .e-field-list ul li .e-kpiTrend-icon,
.e-pivotfieldlist-container.e-rtl .e-field-table .e-field-list ul li .e-kpiValue-icon {
  margin-left: 0;
  margin-right: 10px;
}
.e-pivotfieldlist-container.e-rtl .e-field-table .e-field-header {
  padding-left: 0;
  padding-right: 15px;
  text-align: right;
}
.e-pivotfieldlist-container.e-rtl .e-field-table .e-field-header-container {
  padding-left: 15px;
  padding-right: 0;
}
.e-pivotfieldlist-container.e-rtl .e-field-table .e-field-header-container .e-sort-none,
.e-pivotfieldlist-container.e-rtl .e-field-table .e-field-header-container .e-sort-ascend,
.e-pivotfieldlist-container.e-rtl .e-field-table .e-field-header-container .e-sort-descend {
  padding: 0 4px 0 0;
}
.e-pivotfieldlist-container.e-rtl .e-axis-table .e-left-axis-fields,
.e-pivotfieldlist-container.e-rtl .e-axis-table .e-right-axis-fields {
  padding-left: 0;
  padding-right: 8px;
}
.e-pivotfieldlist-container.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-header,
.e-pivotfieldlist-container.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-header,
.e-pivotfieldlist-container.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-header,
.e-pivotfieldlist-container.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-header {
  float: right;
}
.e-pivotfieldlist-container.e-static {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  max-height: 700px;
  padding: 10px;
  position: relative;
}
.e-pivotfieldlist-container.e-static .e-title-content {
  color: #000;
}
.e-pivotfieldlist-container.e-static .e-field-list-container {
  display: inline-block;
  height: 100%;
  width: 100%;
}
.e-pivotfieldlist-container.e-static .e-field-list-container .e-field-table {
  height: 210px;
  width: 100%;
}
.e-pivotfieldlist-container.e-static .e-field-list-container .e-field-table .e-field-list-tree-outer-div {
  height: 180px;
}
.e-pivotfieldlist-container.e-static .e-field-list-container .e-center-div {
  display: none;
}
.e-pivotfieldlist-container.e-static .e-field-list-container .e-center-title {
  color: rgba(0, 0, 0, 0.54);
  font-size: 13px;
  height: 25px;
  margin-top: 5px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
}
.e-pivotfieldlist-container.e-static .e-field-list-container .e-axis-table {
  width: 100%;
}
.e-pivotfieldlist-container.e-static .e-field-list-container .e-axis-table .e-left-axis-fields {
  padding: 0;
}
.e-pivotfieldlist-container.e-static .e-field-list-container .e-axis-table .e-right-axis-fields {
  padding-left: 8px;
}
.e-pivotfieldlist-container.e-static .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows,
.e-pivotfieldlist-container.e-static .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values {
  margin-top: 8px;
}
.e-pivotfieldlist-container.e-static.e-rtl .e-center-title {
  text-align: right;
}
.e-pivotfieldlist-container.e-static.e-rtl .e-axis-table .e-left-axis-fields {
  padding-left: 10px;
}
.e-pivotfieldlist-container.e-static.e-rtl .e-axis-table .e-right-axis-fields {
  padding: 0;
}
.e-pivotfieldlist-container.e-device {
  height: 100%;
  /* stylelint-disable */
  max-height: 100% !important;
  max-width: 100% !important;
  /* stylelint-enable */
  min-height: 500px;
  width: 100%;
}
.e-pivotfieldlist-container.e-device .e-dlg-header-content {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
}
.e-pivotfieldlist-container.e-device .e-adaptive-field-list-dialog .e-dlg-header-content,
.e-pivotfieldlist-container.e-device .e-member-editor-dialog .e-dlg-header-content {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.e-pivotfieldlist-container.e-device .e-adaptive-field-list-dialog .e-dlg-content .e-field-list-tree-outer-div,
.e-pivotfieldlist-container.e-device .e-member-editor-dialog .e-dlg-content .e-field-list-tree-outer-div {
  height: 210px;
  overflow: auto;
}
.e-pivotfieldlist-container.e-device .e-title-mobile-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}
.e-pivotfieldlist-container.e-device .e-field-list-back-icon {
  color: rgba(0, 0, 0, 0.54);
  font-size: 16px;
  height: auto;
  margin-left: 0;
  margin-right: 12px;
  text-align: center;
  width: 24px;
}
.e-pivotfieldlist-container.e-device .e-disable {
  display: none;
}
.e-pivotfieldlist-container.e-device .e-adaptive-container {
  height: 100%;
  position: relative;
}
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-tab-header {
  background: #f5f5f5;
}
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content {
  display: initial;
}
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-filters,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-rows,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-columns,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-values {
  display: inline-block;
  height: 100%;
  min-height: 250px;
  padding-top: 20px;
  position: relative;
  width: 100%;
}
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-filters .e-axis-content,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-rows .e-axis-content,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-columns .e-axis-content,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-values .e-axis-content {
  overflow: auto;
}
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div {
  display: -ms-grid;
  display: grid;
}
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-drop-indicator,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-drop-indicator,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-drop-indicator,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-drop-indicator {
  display: none;
}
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button {
  -ms-flex-align: center;
      align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-left: 0;
  border-radius: 0;
  border-right: 0;
  border-top: 0;
  border-width: 1px 0;
  box-shadow: none;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  font-weight: normal;
  height: 36px;
  line-height: normal;
  padding: 0 16px;
  text-transform: none;
  width: 100%;
  /* stylelint-disable */
  /* stylelint-enable */
}
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button.e-list-selected,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button.e-list-selected,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button.e-list-selected,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button.e-list-selected {
  opacity: 0.5;
}
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button .e-pvt-btn-content,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button .e-pvt-btn-content,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button .e-pvt-btn-content,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button .e-pvt-btn-content {
  -ms-flex: auto;
      flex: auto;
  font-size: 14px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  width: 70%;
}
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button .e-drag,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button .e-drag,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button .e-drag,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button .e-drag {
  display: none;
}
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button .e-sort,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button .e-remove,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button .e-edit,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button .e-sort,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button .e-remove,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button .e-edit,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button .e-sort,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button .e-remove,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button .e-edit,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button .e-sort,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button .e-remove,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button .e-edit {
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  padding-left: 10px;
  width: 24px;
  font-size: 14px !important;
}
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button .e-sort:hover,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button .e-pv-filter:hover,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button .e-pv-filtered:hover,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button .e-remove:hover,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button .e-dropdown-icon:hover,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button .e-edit:hover,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button .e-sort:hover,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button .e-pv-filter:hover,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button .e-pv-filtered:hover,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button .e-remove:hover,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button .e-dropdown-icon:hover,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button .e-edit:hover,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button .e-sort:hover,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button .e-pv-filter:hover,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button .e-pv-filtered:hover,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button .e-remove:hover,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button .e-dropdown-icon:hover,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button .e-edit:hover,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button .e-sort:hover,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button .e-pv-filter:hover,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button .e-pv-filtered:hover,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button .e-remove:hover,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button .e-dropdown-icon:hover,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button .e-edit:hover {
  color: rgba(0, 0, 0, 0.87);
}
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button .e-sort.e-disable,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button .e-pv-filter.e-disable,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button .e-pv-filtered.e-disable,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button .e-remove.e-disable,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button .e-dropdown-icon.e-disable,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button .e-edit.e-disable,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button .e-sort.e-disable,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button .e-pv-filter.e-disable,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button .e-pv-filtered.e-disable,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button .e-remove.e-disable,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button .e-dropdown-icon.e-disable,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button .e-edit.e-disable,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button .e-sort.e-disable,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button .e-pv-filter.e-disable,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button .e-pv-filtered.e-disable,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button .e-remove.e-disable,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button .e-dropdown-icon.e-disable,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button .e-edit.e-disable,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button .e-sort.e-disable,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button .e-pv-filter.e-disable,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button .e-pv-filtered.e-disable,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button .e-remove.e-disable,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button .e-dropdown-icon.e-disable,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button .e-edit.e-disable {
  display: none;
}
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button .e-sort,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button .e-sort,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button .e-sort,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button .e-sort {
  height: fit-content;
  transform: rotateX(0deg);
  transition: transform 200ms ease-in-out;
}
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button .e-sort.e-descend,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button .e-sort.e-descend,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button .e-sort.e-descend,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button .e-sort.e-descend {
  transform: rotateX(180deg);
}
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button .e-remove,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button .e-remove,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button .e-remove,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button .e-remove {
  display: inline-block;
}
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button.e-rtl .e-pvt-btn-content,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button.e-rtl .e-pvt-btn-content,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button.e-rtl .e-pvt-btn-content,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button.e-rtl .e-pvt-btn-content {
  text-align: right;
}
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button.e-rtl .e-sort,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button.e-rtl .e-pv-filter,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button.e-rtl .e-pv-filtered,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button.e-rtl .e-remove,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button.e-rtl .e-dropdown-icon,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button.e-rtl .e-edit,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button.e-rtl .e-sort,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button.e-rtl .e-pv-filter,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button.e-rtl .e-pv-filtered,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button.e-rtl .e-remove,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button.e-rtl .e-dropdown-icon,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button.e-rtl .e-edit,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button.e-rtl .e-sort,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button.e-rtl .e-pv-filter,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button.e-rtl .e-pv-filtered,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button.e-rtl .e-remove,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button.e-rtl .e-dropdown-icon,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button.e-rtl .e-edit,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button.e-rtl .e-sort,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button.e-rtl .e-pv-filter,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button.e-rtl .e-pv-filtered,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button.e-rtl .e-remove,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button.e-rtl .e-dropdown-icon,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button.e-rtl .e-edit {
  padding-left: 0;
  padding-right: 10px;
}
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-filters .e-axis-content,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-rows .e-axis-content,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-columns .e-axis-content,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-values .e-axis-content {
  height: 100%;
  width: 100%;
}
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-filters .e-draggable-prompt,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-rows .e-draggable-prompt,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-columns .e-draggable-prompt,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-field-list-values .e-draggable-prompt {
  border: 1px dashed #f5f5f5;
  color: #f5f5f5;
  font-size: 14px;
  height: 200px;
  left: 15%;
  line-height: 200px;
  pointer-events: none;
  position: absolute;
  text-align: center;
  top: 25%;
  vertical-align: middle;
  width: 70%;
}
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div {
  /* stylelint-disable */
  /* stylelint-enable */
}
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div .e-pivot-formula {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  color: #000;
  height: 200px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 25px;
  padding: 2px 3px;
  resize: none;
  width: 90%;
}
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div .e-pivot-calc-input-div,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div .e-member-options,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div .e-pivot-calc-custom-format-div {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  width: 90% !important;
}
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div .e-accordion {
  margin-top: 20px;
  overflow: auto;
}
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div .e-accordion .e-acrdn-header {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
}
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div .e-accordion .e-acrdn-header .e-acrdn-header-icon {
  display: table;
  font-size: 12px;
  min-width: 28px;
  padding: 0;
  position: absolute;
  right: 16px;
}
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div .e-accordion .e-acrdn-header .e-acrdn-header-icon .e-list-icon {
  cursor: pointer;
  display: table-cell;
  padding-right: 6px;
  text-align: center;
  vertical-align: middle;
}
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div .e-accordion .e-icons.e-frame {
  margin-right: 12px;
}
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div .e-accordion.e-rtl {
  /* stylelint-disable */
  /* stylelint-enable */
}
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div .e-accordion.e-rtl .e-acrdn-header-icon {
  left: 16px;
  right: auto;
}
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div .e-accordion.e-rtl .e-icons.e-frame {
  margin-left: 12px !important;
  margin-right: 0 !important;
}
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div .e-radio-wrapper {
  margin-bottom: 24px;
  margin-right: 20px;
}
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div .e-radio-wrapper .e-label {
  color: #000;
  font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif", "-apple-system", "BlinkMacSystemFont";
  opacity: 0.87;
}
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div .e-pivot-ok-button,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div .e-pivot-add-button,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div .e-pivot-cancel-button {
  float: right;
  margin-right: 20px;
  margin-top: 32px;
}
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div .e-pivot-ok-button {
  bottom: 0;
  position: sticky;
}
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div .e-pivot-accord {
  max-height: 290px;
  overflow: auto;
}
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div .e-pivot-accord ul .e-list-icon {
  color: rgba(0, 0, 0, 0.54);
}
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div .e-pivot-accord ul .e-calcfieldmember .e-list-text {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 53%;
}
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div .e-pivot-accord ul .e-list-header-icon {
  display: table;
  height: 30px;
  min-height: 30px;
  min-width: 24px;
  padding: 0;
  position: absolute;
  right: 16px;
  top: 0;
}
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div .e-pivot-accord ul .e-list-header-icon .e-list-edit-icon,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div .e-pivot-accord ul .e-list-header-icon .e-remove-report.e-icons {
  color: rgba(0, 0, 0, 0.54);
  display: table-cell;
  padding: 5px;
  pointer-events: all;
  text-align: center;
  vertical-align: middle;
}
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div .e-pivot-accord.e-rtl ul .e-list-header-icon {
  left: 16px;
  right: auto;
}
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div .e-pivot-accord.e-rtl ul .e-list-icon {
  transform: rotateX(0deg) rotateY(180deg);
}
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div.e-olap-calc-outer-div {
  max-height: 350px;
  overflow: hidden;
  overflow-y: auto;
}
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-field-list-footer {
  bottom: 20px;
  display: inline-block;
  position: absolute;
  vertical-align: bottom;
  width: 100%;
}
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-field-list-footer .e-field-list-footer-content {
  display: inline-block;
  padding: 15px;
  width: 100%;
}
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-field-list-footer .e-field-list-footer-content .e-field-list-btn,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-field-list-footer .e-field-list-footer-content .e-calculated-field-btn {
  float: right;
  height: 52px;
  pointer-events: all;
  width: 52px;
  z-index: 1000;
}
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-field-list-footer .e-field-list-footer-content .e-field-list-btn .e-add-icon,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-field-list-footer .e-field-list-footer-content .e-calculated-field-btn .e-add-icon {
  font-size: 18px;
  line-height: 50px;
}
.e-pivotfieldlist-container.e-device .e-footer-content.e-field-list-footer {
  background-color: transparent;
  bottom: 0;
  display: inline-block;
  padding: 15px;
  pointer-events: none;
  position: absolute;
  vertical-align: bottom;
  width: 100%;
}
.e-pivotfieldlist-container.e-device .e-footer-content.e-field-list-footer .e-field-list-btn,
.e-pivotfieldlist-container.e-device .e-footer-content.e-field-list-footer .e-calculated-field-btn,
.e-pivotfieldlist-container.e-device .e-footer-content.e-field-list-footer .e-cancel-btn,
.e-pivotfieldlist-container.e-device .e-footer-content.e-field-list-footer .e-defer-apply-button {
  float: right;
  height: 52px;
  pointer-events: all;
  width: 52px;
  z-index: 1000;
}
.e-pivotfieldlist-container.e-device .e-footer-content.e-field-list-footer .e-field-list-btn .e-add-icon,
.e-pivotfieldlist-container.e-device .e-footer-content.e-field-list-footer .e-calculated-field-btn .e-add-icon,
.e-pivotfieldlist-container.e-device .e-footer-content.e-field-list-footer .e-cancel-btn .e-add-icon,
.e-pivotfieldlist-container.e-device .e-footer-content.e-field-list-footer .e-defer-apply-button .e-add-icon {
  font-size: 18px;
  line-height: 50px;
}
.e-pivotfieldlist-container.e-device .e-footer-content.e-field-list-footer .e-cancel-btn,
.e-pivotfieldlist-container.e-device .e-footer-content.e-field-list-footer .e-defer-apply-button {
  float: left;
}
.e-pivotfieldlist-container.e-device .e-footer-content.e-field-list-footer .e-cancel-btn,
.e-pivotfieldlist-container.e-device .e-footer-content.e-field-list-footer .e-defer-apply-button,
.e-pivotfieldlist-container.e-device .e-footer-content.e-field-list-footer .e-checkbox-layout {
  max-width: 20%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.e-pivotfieldlist-container.e-device .e-footer-content.e-field-list-footer .e-checkbox-layout {
  max-width: 45%;
}
.e-pivotfieldlist-container.e-device.e-static {
  border: 0;
  padding: 0;
}
.e-pivotfieldlist-container.e-device.e-static .e-field-list-filters .e-axis-content,
.e-pivotfieldlist-container.e-device.e-static .e-field-list-rows .e-axis-content,
.e-pivotfieldlist-container.e-device.e-static .e-field-list-columns .e-axis-content,
.e-pivotfieldlist-container.e-device.e-static .e-field-list-values .e-axis-content {
  max-height: 600px;
}
.e-pivotfieldlist-container.e-device.e-rtl {
  /* stylelint-disable */
  /* stylelint-enable */
}
.e-pivotfieldlist-container.e-device.e-rtl .e-field-list-back-icon {
  margin-left: 12px;
  margin-right: 0;
  transform: rotateY(180deg);
}
.e-pivotfieldlist-container.e-device.e-rtl .e-field-list-footer .e-field-list-btn,
.e-pivotfieldlist-container.e-device.e-rtl .e-field-list-footer .e-calculated-field-btn {
  float: left;
}
.e-pivotfieldlist-container.e-device.e-rtl .e-field-list-footer .e-cancel-btn,
.e-pivotfieldlist-container.e-device.e-rtl .e-field-list-footer .e-defer-apply-button {
  float: right;
}
.e-pivotfieldlist-container.e-device.e-rtl .e-pivot-calc-outer-div .e-pivot-ok-button,
.e-pivotfieldlist-container.e-device.e-rtl .e-pivot-calc-outer-div .e-pivot-add-button,
.e-pivotfieldlist-container.e-device.e-rtl .e-pivot-calc-outer-div .e-pivot-cancel-button {
  float: left !important;
  margin-left: 20px;
}

/* stylelint-disable */
.e-bigger .e-pivotfieldlist-container .e-field-table,
.e-bigger.e-pivotfieldlist-container .e-field-table {
  /* stylelint-enable */
}
.e-bigger .e-pivotfieldlist-container .e-field-table .e-left-axis-fields,
.e-bigger .e-pivotfieldlist-container .e-field-table .e-right-axis-fields,
.e-bigger.e-pivotfieldlist-container .e-field-table .e-left-axis-fields,
.e-bigger.e-pivotfieldlist-container .e-field-table .e-right-axis-fields {
  padding-left: 16px;
}
.e-bigger .e-pivotfieldlist-container .e-field-table .e-field-header,
.e-bigger.e-pivotfieldlist-container .e-field-table .e-field-header {
  background: #f5f5f5;
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
}
.e-bigger .e-pivotfieldlist-container .e-field-table .e-field-header-container,
.e-bigger.e-pivotfieldlist-container .e-field-table .e-field-header-container {
  padding-right: 20px;
}
.e-bigger .e-pivotfieldlist-container .e-field-table .e-field-header-container .e-sort-none,
.e-bigger .e-pivotfieldlist-container .e-field-table .e-field-header-container .e-sort-ascend,
.e-bigger .e-pivotfieldlist-container .e-field-table .e-field-header-container .e-sort-descend,
.e-bigger.e-pivotfieldlist-container .e-field-table .e-field-header-container .e-sort-none,
.e-bigger.e-pivotfieldlist-container .e-field-table .e-field-header-container .e-sort-ascend,
.e-bigger.e-pivotfieldlist-container .e-field-table .e-field-header-container .e-sort-descend {
  font-size: 16px;
  padding: 0 0 0 4px;
  width: 24px;
}
.e-bigger .e-pivotfieldlist-container .e-field-table .e-field-list,
.e-bigger.e-pivotfieldlist-container .e-field-table .e-field-list {
  padding-left: 5px;
}
.e-bigger .e-pivotfieldlist-container .e-field-table .e-field-list:not(.e-olap-field-list-tree) ul,
.e-bigger.e-pivotfieldlist-container .e-field-table .e-field-list:not(.e-olap-field-list-tree) ul {
  padding-left: 5px;
}
.e-bigger .e-pivotfieldlist-container .e-field-table .e-field-list:not(.e-olap-field-list-tree) ul li:not(.e-pivot-parent):not(.e-pivot-child) .e-text-content,
.e-bigger.e-pivotfieldlist-container .e-field-table .e-field-list:not(.e-olap-field-list-tree) ul li:not(.e-pivot-parent):not(.e-pivot-child) .e-text-content {
  padding-left: 0;
}
.e-bigger .e-pivotfieldlist-container .e-field-table .e-field-list ul li,
.e-bigger.e-pivotfieldlist-container .e-field-table .e-field-list ul li {
  padding: 0;
}
.e-bigger .e-pivotfieldlist-container .e-field-table .e-field-list .e-drag,
.e-bigger.e-pivotfieldlist-container .e-field-table .e-field-list .e-drag {
  font-size: 16px !important;
  height: 24px !important;
  margin-bottom: 3px !important;
  margin-right: 4px !important;
  width: 24px !important;
}
.e-bigger .e-pivotfieldlist-container .e-field-table .e-field-list .e-calc-measure-icon,
.e-bigger .e-pivotfieldlist-container .e-field-table .e-field-list .e-calc-dimension-icon,
.e-bigger .e-pivotfieldlist-container .e-field-table .e-field-list .e-attributeCDB-icon,
.e-bigger .e-pivotfieldlist-container .e-field-table .e-field-list .e-hierarchyCDB-icon,
.e-bigger .e-pivotfieldlist-container .e-field-table .e-field-list .e-level-members,
.e-bigger .e-pivotfieldlist-container .e-field-table .e-field-list .e-namedSetCDB-icon,
.e-bigger .e-pivotfieldlist-container .e-field-table .e-field-list .e-measure-icon,
.e-bigger .e-pivotfieldlist-container .e-field-table .e-field-list .e-kpiGoal-icon,
.e-bigger .e-pivotfieldlist-container .e-field-table .e-field-list .e-kpiStatus-icon,
.e-bigger .e-pivotfieldlist-container .e-field-table .e-field-list .e-kpiTrend-icon,
.e-bigger .e-pivotfieldlist-container .e-field-table .e-field-list .e-kpiValue-icon,
.e-bigger.e-pivotfieldlist-container .e-field-table .e-field-list .e-calc-measure-icon,
.e-bigger.e-pivotfieldlist-container .e-field-table .e-field-list .e-calc-dimension-icon,
.e-bigger.e-pivotfieldlist-container .e-field-table .e-field-list .e-attributeCDB-icon,
.e-bigger.e-pivotfieldlist-container .e-field-table .e-field-list .e-hierarchyCDB-icon,
.e-bigger.e-pivotfieldlist-container .e-field-table .e-field-list .e-level-members,
.e-bigger.e-pivotfieldlist-container .e-field-table .e-field-list .e-namedSetCDB-icon,
.e-bigger.e-pivotfieldlist-container .e-field-table .e-field-list .e-measure-icon,
.e-bigger.e-pivotfieldlist-container .e-field-table .e-field-list .e-kpiGoal-icon,
.e-bigger.e-pivotfieldlist-container .e-field-table .e-field-list .e-kpiStatus-icon,
.e-bigger.e-pivotfieldlist-container .e-field-table .e-field-list .e-kpiTrend-icon,
.e-bigger.e-pivotfieldlist-container .e-field-table .e-field-list .e-kpiValue-icon {
  margin-left: 10px;
}
.e-bigger .e-pivotfieldlist-container .e-field-table .e-field-list.e-olap-field-list-tree ul .e-list-icon,
.e-bigger.e-pivotfieldlist-container .e-field-table .e-field-list.e-olap-field-list-tree ul .e-list-icon {
  font-size: 16px;
}
.e-bigger .e-pivotfieldlist-container .e-field-table .e-left-axis-fields .e-field-list-rows,
.e-bigger .e-pivotfieldlist-container .e-field-table .e-right-axis-fields .e-field-list-values,
.e-bigger.e-pivotfieldlist-container .e-field-table .e-left-axis-fields .e-field-list-rows,
.e-bigger.e-pivotfieldlist-container .e-field-table .e-right-axis-fields .e-field-list-values {
  margin-top: 16px;
}
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-container,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-container,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-container,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-container,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-container,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-container,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-container,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-container {
  height: 30px;
  padding: 0 20px 0;
}
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-container .e-axis-icon-container,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-container .e-axis-icon-container,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-container .e-axis-icon-container,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-container .e-axis-icon-container,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-container .e-axis-icon-container,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-container .e-axis-icon-container,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-container .e-axis-icon-container,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-container .e-axis-icon-container {
  height: 16px;
  width: 24px;
}
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-container .e-axis-icon-container .e-axis-rows,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-container .e-axis-icon-container .e-axis-columns,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-container .e-axis-icon-container .e-axis-values,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-container .e-axis-icon-container .e-axis-filters,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-container .e-axis-icon-container .e-axis-rows,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-container .e-axis-icon-container .e-axis-columns,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-container .e-axis-icon-container .e-axis-values,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-container .e-axis-icon-container .e-axis-filters,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-container .e-axis-icon-container .e-axis-rows,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-container .e-axis-icon-container .e-axis-columns,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-container .e-axis-icon-container .e-axis-values,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-container .e-axis-icon-container .e-axis-filters,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-container .e-axis-icon-container .e-axis-rows,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-container .e-axis-icon-container .e-axis-columns,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-container .e-axis-icon-container .e-axis-values,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-container .e-axis-icon-container .e-axis-filters,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-container .e-axis-icon-container .e-axis-rows,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-container .e-axis-icon-container .e-axis-columns,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-container .e-axis-icon-container .e-axis-values,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-container .e-axis-icon-container .e-axis-filters,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-container .e-axis-icon-container .e-axis-rows,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-container .e-axis-icon-container .e-axis-columns,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-container .e-axis-icon-container .e-axis-values,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-container .e-axis-icon-container .e-axis-filters,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-container .e-axis-icon-container .e-axis-rows,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-container .e-axis-icon-container .e-axis-columns,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-container .e-axis-icon-container .e-axis-values,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-container .e-axis-icon-container .e-axis-filters,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-container .e-axis-icon-container .e-axis-rows,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-container .e-axis-icon-container .e-axis-columns,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-container .e-axis-icon-container .e-axis-values,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-container .e-axis-icon-container .e-axis-filters {
  font-size: 16px;
}
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-container .e-axis-header,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-container .e-axis-header,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-container .e-axis-header,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-container .e-axis-header,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-container .e-axis-header,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-container .e-axis-header,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-container .e-axis-header,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-container .e-axis-header {
  font-size: 14px;
  line-height: 1.6em;
  padding: 0 12px;
}
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content {
  height: 133px;
}
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button {
  /* stylelint-disable */
  font-size: 14px;
  height: 36px !important;
  padding: 9px 16px !important;
  /* stylelint-enable */
}
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pvt-btn-content,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pvt-btn-content,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pvt-btn-content,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pvt-btn-content,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pvt-btn-content,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pvt-btn-content,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pvt-btn-content,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pvt-btn-content {
  font-size: 14px;
  line-height: 1.1em;
  padding: 0 10px;
}
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-draggable,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-draggable,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-draggable,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-draggable,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-draggable,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-draggable,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-draggable,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-draggable {
  height: 24px;
  width: 24px;
}
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-edit,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-edit,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-edit,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-edit,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-edit,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-edit,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-edit,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-edit {
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  height: 24px;
  width: 24px;
}
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort {
  height: 16px;
}
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-drag,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-drag,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-drag,
.e-bigger .e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-drag,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-drag,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-drag,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-drag,
.e-bigger.e-pivotfieldlist-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-drag {
  font-size: 16px;
  width: 24px;
}
.e-bigger .e-pivotfieldlist-container .e-calculated-field,
.e-bigger.e-pivotfieldlist-container .e-calculated-field {
  font-size: 14px;
}
.e-bigger .e-pivotfieldlist-container.e-static,
.e-bigger.e-pivotfieldlist-container.e-static {
  max-height: 735px;
}
.e-bigger .e-pivotfieldlist-container.e-static .e-field-list-container .e-center-title,
.e-bigger.e-pivotfieldlist-container.e-static .e-field-list-container .e-center-title {
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
}
.e-bigger .e-pivotfieldlist-container.e-static .e-field-list-container .e-axis-table .e-right-axis-fields,
.e-bigger.e-pivotfieldlist-container.e-static .e-field-list-container .e-axis-table .e-right-axis-fields {
  padding-left: 16px;
}
.e-bigger .e-pivotfieldlist-container.e-static .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows,
.e-bigger .e-pivotfieldlist-container.e-static .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values,
.e-bigger.e-pivotfieldlist-container.e-static .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows,
.e-bigger.e-pivotfieldlist-container.e-static .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values {
  margin-top: 16px;
}
.e-bigger .e-pivotfieldlist-container.e-static .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-container .e-axis-icon-container,
.e-bigger .e-pivotfieldlist-container.e-static .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-container .e-axis-icon-container,
.e-bigger .e-pivotfieldlist-container.e-static .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-container .e-axis-icon-container,
.e-bigger .e-pivotfieldlist-container.e-static .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-container .e-axis-icon-container,
.e-bigger.e-pivotfieldlist-container.e-static .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-container .e-axis-icon-container,
.e-bigger.e-pivotfieldlist-container.e-static .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-container .e-axis-icon-container,
.e-bigger.e-pivotfieldlist-container.e-static .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-container .e-axis-icon-container,
.e-bigger.e-pivotfieldlist-container.e-static .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-container .e-axis-icon-container {
  padding-top: 7px;
}
.e-bigger .e-pivotfieldlist-container.e-static.e-rtl .e-axis-table,
.e-bigger.e-pivotfieldlist-container.e-static.e-rtl .e-axis-table {
  /* stylelint-disable */
}
.e-bigger .e-pivotfieldlist-container.e-static.e-rtl .e-axis-table .e-left-axis-fields,
.e-bigger.e-pivotfieldlist-container.e-static.e-rtl .e-axis-table .e-left-axis-fields {
  padding-left: 16px;
}
.e-bigger .e-pivotfieldlist-container.e-static.e-rtl .e-axis-table .e-right-axis-fields,
.e-bigger.e-pivotfieldlist-container.e-static.e-rtl .e-axis-table .e-right-axis-fields {
  padding: 0 !important;
}
.e-bigger .e-pivotfieldlist-container.e-rtl .e-field-table .e-field-list,
.e-bigger.e-pivotfieldlist-container.e-rtl .e-field-table .e-field-list {
  padding-left: 0;
  padding-right: 5px;
  /* stylelint-enable */
}
.e-bigger .e-pivotfieldlist-container.e-rtl .e-field-table .e-field-list:not(.e-olap-field-list-tree) ul,
.e-bigger.e-pivotfieldlist-container.e-rtl .e-field-table .e-field-list:not(.e-olap-field-list-tree) ul {
  padding-right: 5px;
}
.e-bigger .e-pivotfieldlist-container.e-rtl .e-field-table .e-field-list:not(.e-olap-field-list-tree) ul li:not(.e-pivot-parent):not(.e-pivot-child) .e-text-content,
.e-bigger.e-pivotfieldlist-container.e-rtl .e-field-table .e-field-list:not(.e-olap-field-list-tree) ul li:not(.e-pivot-parent):not(.e-pivot-child) .e-text-content {
  padding-right: 0;
}
.e-bigger .e-pivotfieldlist-container.e-rtl .e-field-table .e-field-list .e-drag,
.e-bigger.e-pivotfieldlist-container.e-rtl .e-field-table .e-field-list .e-drag {
  margin-left: 4px !important;
  margin-right: 0 !important;
}
.e-bigger .e-pivotfieldlist-container.e-rtl .e-field-table .e-field-list .e-calc-measure-icon,
.e-bigger .e-pivotfieldlist-container.e-rtl .e-field-table .e-field-list .e-calc-dimension-icon,
.e-bigger .e-pivotfieldlist-container.e-rtl .e-field-table .e-field-list .e-attributeCDB-icon,
.e-bigger .e-pivotfieldlist-container.e-rtl .e-field-table .e-field-list .e-hierarchyCDB-icon,
.e-bigger .e-pivotfieldlist-container.e-rtl .e-field-table .e-field-list .e-level-members,
.e-bigger .e-pivotfieldlist-container.e-rtl .e-field-table .e-field-list .e-namedSetCDB-icon,
.e-bigger .e-pivotfieldlist-container.e-rtl .e-field-table .e-field-list .e-measure-icon,
.e-bigger .e-pivotfieldlist-container.e-rtl .e-field-table .e-field-list .e-kpiGoal-icon,
.e-bigger .e-pivotfieldlist-container.e-rtl .e-field-table .e-field-list .e-kpiStatus-icon,
.e-bigger .e-pivotfieldlist-container.e-rtl .e-field-table .e-field-list .e-kpiTrend-icon,
.e-bigger .e-pivotfieldlist-container.e-rtl .e-field-table .e-field-list .e-kpiValue-icon,
.e-bigger.e-pivotfieldlist-container.e-rtl .e-field-table .e-field-list .e-calc-measure-icon,
.e-bigger.e-pivotfieldlist-container.e-rtl .e-field-table .e-field-list .e-calc-dimension-icon,
.e-bigger.e-pivotfieldlist-container.e-rtl .e-field-table .e-field-list .e-attributeCDB-icon,
.e-bigger.e-pivotfieldlist-container.e-rtl .e-field-table .e-field-list .e-hierarchyCDB-icon,
.e-bigger.e-pivotfieldlist-container.e-rtl .e-field-table .e-field-list .e-level-members,
.e-bigger.e-pivotfieldlist-container.e-rtl .e-field-table .e-field-list .e-namedSetCDB-icon,
.e-bigger.e-pivotfieldlist-container.e-rtl .e-field-table .e-field-list .e-measure-icon,
.e-bigger.e-pivotfieldlist-container.e-rtl .e-field-table .e-field-list .e-kpiGoal-icon,
.e-bigger.e-pivotfieldlist-container.e-rtl .e-field-table .e-field-list .e-kpiStatus-icon,
.e-bigger.e-pivotfieldlist-container.e-rtl .e-field-table .e-field-list .e-kpiTrend-icon,
.e-bigger.e-pivotfieldlist-container.e-rtl .e-field-table .e-field-list .e-kpiValue-icon {
  margin-left: 0;
  margin-right: 10px;
}
.e-bigger .e-pivotfieldlist-container.e-rtl .e-field-table .e-field-header,
.e-bigger.e-pivotfieldlist-container.e-rtl .e-field-table .e-field-header {
  padding-right: 20px;
}
.e-bigger .e-pivotfieldlist-container.e-rtl .e-field-table .e-field-header-container,
.e-bigger.e-pivotfieldlist-container.e-rtl .e-field-table .e-field-header-container {
  padding-left: 20px;
}
.e-bigger .e-pivotfieldlist-container.e-rtl .e-field-table .e-field-header-container .e-sort-none,
.e-bigger .e-pivotfieldlist-container.e-rtl .e-field-table .e-field-header-container .e-sort-ascend,
.e-bigger .e-pivotfieldlist-container.e-rtl .e-field-table .e-field-header-container .e-sort-descend,
.e-bigger.e-pivotfieldlist-container.e-rtl .e-field-table .e-field-header-container .e-sort-none,
.e-bigger.e-pivotfieldlist-container.e-rtl .e-field-table .e-field-header-container .e-sort-ascend,
.e-bigger.e-pivotfieldlist-container.e-rtl .e-field-table .e-field-header-container .e-sort-descend {
  padding: 0 4px 0 0;
}
.e-bigger .e-pivotfieldlist-container.e-rtl .e-axis-table .e-left-axis-fields,
.e-bigger .e-pivotfieldlist-container.e-rtl .e-axis-table .e-right-axis-fields,
.e-bigger.e-pivotfieldlist-container.e-rtl .e-axis-table .e-left-axis-fields,
.e-bigger.e-pivotfieldlist-container.e-rtl .e-axis-table .e-right-axis-fields {
  padding-left: 0;
  padding-right: 8px;
}
.e-bigger .e-pivotfieldlist-container.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-filters .e-pivot-button .e-sort,
.e-bigger .e-pivotfieldlist-container.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-filters .e-pivot-button .e-pv-filter,
.e-bigger .e-pivotfieldlist-container.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-filters .e-pivot-button .e-pv-filtered,
.e-bigger .e-pivotfieldlist-container.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-filters .e-pivot-button .e-remove,
.e-bigger .e-pivotfieldlist-container.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-filters .e-pivot-button .e-dropdown-icon,
.e-bigger .e-pivotfieldlist-container.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-filters .e-pivot-button .e-edit,
.e-bigger .e-pivotfieldlist-container.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-rows .e-pivot-button .e-sort,
.e-bigger .e-pivotfieldlist-container.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-rows .e-pivot-button .e-pv-filter,
.e-bigger .e-pivotfieldlist-container.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-rows .e-pivot-button .e-pv-filtered,
.e-bigger .e-pivotfieldlist-container.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-rows .e-pivot-button .e-remove,
.e-bigger .e-pivotfieldlist-container.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-rows .e-pivot-button .e-dropdown-icon,
.e-bigger .e-pivotfieldlist-container.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-rows .e-pivot-button .e-edit,
.e-bigger .e-pivotfieldlist-container.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-columns .e-pivot-button .e-sort,
.e-bigger .e-pivotfieldlist-container.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-columns .e-pivot-button .e-pv-filter,
.e-bigger .e-pivotfieldlist-container.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-columns .e-pivot-button .e-pv-filtered,
.e-bigger .e-pivotfieldlist-container.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-columns .e-pivot-button .e-remove,
.e-bigger .e-pivotfieldlist-container.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-columns .e-pivot-button .e-dropdown-icon,
.e-bigger .e-pivotfieldlist-container.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-columns .e-pivot-button .e-edit,
.e-bigger .e-pivotfieldlist-container.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-values .e-pivot-button .e-sort,
.e-bigger .e-pivotfieldlist-container.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-values .e-pivot-button .e-pv-filter,
.e-bigger .e-pivotfieldlist-container.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-values .e-pivot-button .e-pv-filtered,
.e-bigger .e-pivotfieldlist-container.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-values .e-pivot-button .e-remove,
.e-bigger .e-pivotfieldlist-container.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-values .e-pivot-button .e-dropdown-icon,
.e-bigger .e-pivotfieldlist-container.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-values .e-pivot-button .e-edit,
.e-bigger.e-pivotfieldlist-container.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-filters .e-pivot-button .e-sort,
.e-bigger.e-pivotfieldlist-container.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-filters .e-pivot-button .e-pv-filter,
.e-bigger.e-pivotfieldlist-container.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-filters .e-pivot-button .e-pv-filtered,
.e-bigger.e-pivotfieldlist-container.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-filters .e-pivot-button .e-remove,
.e-bigger.e-pivotfieldlist-container.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-filters .e-pivot-button .e-dropdown-icon,
.e-bigger.e-pivotfieldlist-container.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-filters .e-pivot-button .e-edit,
.e-bigger.e-pivotfieldlist-container.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-rows .e-pivot-button .e-sort,
.e-bigger.e-pivotfieldlist-container.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-rows .e-pivot-button .e-pv-filter,
.e-bigger.e-pivotfieldlist-container.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-rows .e-pivot-button .e-pv-filtered,
.e-bigger.e-pivotfieldlist-container.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-rows .e-pivot-button .e-remove,
.e-bigger.e-pivotfieldlist-container.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-rows .e-pivot-button .e-dropdown-icon,
.e-bigger.e-pivotfieldlist-container.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-rows .e-pivot-button .e-edit,
.e-bigger.e-pivotfieldlist-container.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-columns .e-pivot-button .e-sort,
.e-bigger.e-pivotfieldlist-container.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-columns .e-pivot-button .e-pv-filter,
.e-bigger.e-pivotfieldlist-container.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-columns .e-pivot-button .e-pv-filtered,
.e-bigger.e-pivotfieldlist-container.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-columns .e-pivot-button .e-remove,
.e-bigger.e-pivotfieldlist-container.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-columns .e-pivot-button .e-dropdown-icon,
.e-bigger.e-pivotfieldlist-container.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-columns .e-pivot-button .e-edit,
.e-bigger.e-pivotfieldlist-container.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-values .e-pivot-button .e-sort,
.e-bigger.e-pivotfieldlist-container.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-values .e-pivot-button .e-pv-filter,
.e-bigger.e-pivotfieldlist-container.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-values .e-pivot-button .e-pv-filtered,
.e-bigger.e-pivotfieldlist-container.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-values .e-pivot-button .e-remove,
.e-bigger.e-pivotfieldlist-container.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-values .e-pivot-button .e-dropdown-icon,
.e-bigger.e-pivotfieldlist-container.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-values .e-pivot-button .e-edit {
  padding-left: 0;
  padding-right: 4px;
}
.e-bigger .e-pivotfieldlist-container.e-search-mode,
.e-bigger.e-pivotfieldlist-container.e-search-mode {
  /* stylelint-disable */
}
.e-bigger .e-pivotfieldlist-container.e-search-mode.e-static,
.e-bigger.e-pivotfieldlist-container.e-search-mode.e-static {
  max-height: 750px;
}
.e-bigger .e-pivotfieldlist-container.e-search-mode.e-static .e-field-list-container,
.e-bigger.e-pivotfieldlist-container.e-search-mode.e-static .e-field-list-container {
  max-height: 800px;
}
.e-bigger .e-pivotfieldlist-container.e-search-mode.e-static .e-field-list-container .e-field-table,
.e-bigger.e-pivotfieldlist-container.e-search-mode.e-static .e-field-list-container .e-field-table {
  height: 270px;
}
.e-bigger .e-pivotfieldlist-container.e-search-mode.e-popup,
.e-bigger.e-pivotfieldlist-container.e-search-mode.e-popup {
  max-height: 600px !important;
  min-height: 550px !important;
}
.e-bigger .e-pivotfieldlist-container.e-search-mode.e-popup .e-field-list-container,
.e-bigger.e-pivotfieldlist-container.e-search-mode.e-popup .e-field-list-container {
  height: 387px;
}
.e-bigger .e-pivotfieldlist-container.e-search-mode.e-popup .e-field-list-container .e-field-table,
.e-bigger.e-pivotfieldlist-container.e-search-mode.e-popup .e-field-list-container .e-field-table {
  height: 400px !important;
}
.e-bigger .e-pivotfieldlist-container.e-search-mode.e-popup .e-field-list-container .e-axis-table,
.e-bigger.e-pivotfieldlist-container.e-search-mode.e-popup .e-field-list-container .e-axis-table {
  height: 410px;
}
.e-bigger .e-pivotfieldlist-container.e-search-mode.e-popup .e-field-list-container .e-axis-table .e-axis-content,
.e-bigger.e-pivotfieldlist-container.e-search-mode.e-popup .e-field-list-container .e-axis-table .e-axis-content {
  height: 165px !important;
}
.e-bigger .e-pivotfieldlist-container .e-adaptive-field-list-dialog .e-dlg-content .e-field-list-tree-outer-div,
.e-bigger.e-pivotfieldlist-container .e-adaptive-field-list-dialog .e-dlg-content .e-field-list-tree-outer-div {
  max-height: 165px;
}

/* stylelint-enable */