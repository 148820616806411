@use "sass:map";

.btn {
  font-size: 1.2rem;
  font-weight: 500;
  font-family: "Poppins";
  border-radius: 3px;
  height: 2.5rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  position: relative;
  transition: all 0.3s ease-out;
  outline: none;
  box-shadow: none;
  border: none;
  background-color: transparent;
  color: $color-primary-900;
  font-style: normal;
  letter-spacing: 0;
  text-align: left;
  cursor: pointer;

  mat-icon {
    width: 1.2rem;
    height: 1.2rem;
  }
  &.disabled,
  &.btn-disabled {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
    color: $color-grey-600;
  }
  &.btn-xs {
    height: 16px;
    padding: 0 4px;
    font-size: 1.1rem;
  }
  &.btn-sm {
    padding: 0 0.5rem;
    font-size: 1.1rem;
    height: 2rem;
  }
  &.btn-lg {
    font-size: 1.6rem;
    height: 4rem;
  }
  &.btn-inherit {
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    height: inherit;
    justify-content: inherit;
  }
  &.btn-primary {
    background-color: $color-primary-900;
    color: white;
    &:hover,
    &:active,
    &.active,
    &:focus {
      background-color: $color-primary-800;
    }
    &:disabled,
    &.btn-disabled,
    &.btn-disabled:hover,
    &.btn-disabled:active {
      background-color: $color-grey-200 !important;
      color: $color-grey-600 !important;
    }
  }
  &.btn-primary-v2 {
    background-color: #004FAC;
    color: white;
    &:hover,
    &:active,
    &.active,
    &:focus {
      background-color: $color-primary-800;
    }
    &:disabled,
    &.btn-disabled,
    &.btn-disabled:hover,
    &.btn-disabled:active {
      background-color: $color-grey-200 !important;
      color: $color-grey-600 !important;
    }
  }
  &.btn-primary-warning {
    background-color: $color-warning;
    color: $color-primary-900;
    &:hover,
    &:active,
    &.active,
    &:focus {
      color: white;
    }
  }
  &.btn-cancel-with-border {
    border: 1px solid $color-grey-400;
    border-radius: 4px;
  }
  &.btn-success {
    background-color: $color-success;
    color: white !important;
    &:hover,
    &:active,
    &.active,
    &:focus {
      background-color: $color-success-accent !important;
    }
    &:disabled,
    &.btn-disabled,
    &.btn-disabled:hover,
    &.btn-disabled:active {
      background-color: $color-grey-200 !important;
      color: $color-grey-600 !important;
    }
  }
  &.btn-error {
    background-color: $color-error;
    color: white !important;
    &:hover,
    &:active,
    &.active,
    &:focus {
      background-color: $color-error-accent !important;
    }
    &:disabled,
    &.btn-disabled,
    &.btn-disabled:hover,
    &.btn-disabled:active {
      background-color: $color-grey-200 !important;
      color: $color-grey-600 !important;
    }
  }
  &.btn-secondary {
    background-color: $color-primary-800;
    color: $color-primary-900 !important;
    &:hover,
    &:active,
    &.active,
    &:focus {
      background-color: $color-primary-800;
      color: white;
    }
    &.btn-disabled,
    &.btn-disabled:hover,
    &.btn-disabled:active {
      background-color: $color-grey-200 !important;
      color: $color-grey-600 !important;
    }
  }
  &.btn-stroked {
    border: 1.5px solid $color-primary-900;
    color: $color-primary-900;
    background-color: transparent;
    &.light {
      border-color: $color-grey-200;
      color: $color-primary-800;
    }
    &:hover,
    &:active,
    &.active {
      border-color: $color-primary-800;
      color: $color-primary-800;
    }
    &.btn-disabled,
    &.btn-disabled:hover,
    &.btn-disabled:active {
      border-color: $color-grey-600 !important;
      color: $color-grey-600 !important;
      background-color: $color-grey-200 !important;
    }
    &.warning {
      border: 1.5px solid $color-warning;
      &:hover,
      &:active,
      &.active {
        background-color: $color-warning;
        color: white;
      }
    }
  }
  &.btn-filter {
    border: 1.5px solid transparent;
    color: $color-grey-800;
    font-size: 1.3rem;
    font-weight: 400;
    &:hover,
    &:active,
    &[aria-expanded="true"] {
      border: 1.5px solid $color-primary-900;
      color: $color-primary-900;
    }
    &.active {
      color: $color-primary-900;
    }
    &.btn-toggle.active {
      border: 1.5px solid $color-primary-800;
    }
    &.btn-disabled:hover {
      border-color: transparent;
      color: $color-grey-800;
    }
  }
  &.btn-option {
    color: $color-grey-800;
    display: flex;
    align-items: center;
    &:hover,
    &:active,
    &.active {
      color: $color-primary-900;
    }
  }
  &.btn-fab {
    border-radius: 50%;
    padding: 0;
    &.btn-fab-xs {
      width: 0.5rem;
      height: 0.5rem;
    }
    &.btn-fab-lg {
      width: 4rem;
      height: 4rem;
      border: 1px solid;
    }
  }
  &.btn-warn {
    color: $color-error;
    &:hover,
    &:active,
    &.active {
      color: $color-primary-900;
    }
  }
  &.btn-loading {
    position: relative;
    pointer-events: none;
    &::after {
      content: url(/assets/img/loading.svg);
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: $color-success;
      color: white;
      border-radius: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 0.5rem;
    }
  }
  &.btn-profile {
    width: 2.5rem;
    border-radius: 50%;
    background-color: white;
    color: $color-primary-900 !important;
    &:hover,
    &:active,
    &.active {
      background-color: $color-primary-800 !important;
    }
  }
  &.btn-light {
    background-color: $color-grey-200;
    color: black;
    &:hover,
    &:active,
    &.active {
      background-color: $color-grey-600;
    }
    &:disabled,
    &.btn-disabled,
    &.btn-disabled:hover,
    &.btn-disabled:active {
      background-color: $color-grey-200 !important;
      color: $color-grey-600 !important;
    }
  }
}
.btn-hide {
  color: transparent !important;
  pointer-events: none;
  transition: none;
}

.btn-more-link {
  border: 1.5px solid transparent;
  background-color: $color-grey-50;
  color: $color-grey-800;
  &:hover,
  &:active,
  &.active {
    border: 1.5px solid $color-primary-800;
    color: $color-primary-800;
    background-color: white;
    cursor: pointer;
  }
}

.mat-icon-button.btn-sm {
  height: 20px;
  width: 20px;
  line-height: 20px;
}

.disabled-interactions {
  text-decoration: none;
  pointer-events: none;
}
