@use "sass:math";

@import "./variables";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");

// General
@import "./common/global.scss";
@import "./common/theming";
@import "./common/typography.scss";
@import "./common/colors.scss";
@import "./common/formatting.scss";
@import "./common/spacing.scss";
@import "./common/spacing-functions.scss";
@import "./common/animations.scss";
@import "./common/shadows.scss";
@import "./common/markers.scss";
// Layout
@import "./common/layout.scss";
@import "./common/grid.scss";
@import "./common/tabs.scss";
@import "./common/search.scss";
@import "./common/page.scss";
@import "./common/panels.scss";
@import "./common/sections.scss";
@import "./common/menus.scss";
// Actions
@import "./common/buttons.scss";
@import "./common/links.scss";
@import "./common/drag-drop.scss";
// Elements
@import "./common/cards.scss";
@import "./common/components.scss";
@import "./common/sliders.scss";
//@import './common/data-formats.scss';
@import "./common/forms.scss";
@import "./common/tables.scss";
// @import './common/trees.scss';
@import "./common/tips.scss";
// @import './common/dragzone.scss';
// Dialogs and Alerts
@import "./common/alerts.scss";
@import "./common/dialogs.scss";
@import "./common/loader.scss";
@import "./common/steppers.scss";
// Components
@import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-angular-layouts/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-angular-pivotview/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-grids/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-icons/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-spreadsheet/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-angular-documenteditor/styles/material.css";
@import "../../../node_modules/quill/dist/quill.snow.css";
@import "../../../node_modules/primeng/resources/themes/lara-light-blue/theme.css";
@import "../../../node_modules/primeng/resources/primeng.min.css";
@import "../../../node_modules/primeicons/primeicons.css";
