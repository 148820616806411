.alert {
  &.mdc-snackbar {
    border-radius: 4px;
  }

  .mat-mdc-simple-snackbar > span,
  .mat-mdc-snack-bar-label {
    font-size: 1.1rem !important;
    font-weight: 600 !important;
  }

  .mdc-snackbar__surface {
    background-color: inherit !important;
  }
}
