@use "@angular/material" as mat;
@import "../variables";

// Common styles for Angular Material
$novisto-typography: mat.m2-define-typography-config(
  $font-family: "Poppins",
  $headline-4: mat.m2-define-typography-level(3.6rem, 3.6rem, 600),
  $headline-5: mat.m2-define-typography-level(2.4rem, 2.4rem, 600),
  $headline-6: mat.m2-define-typography-level(2rem, 2.4rem, 600),
  $subtitle-1: mat.m2-define-typography-level(1.6rem, 1.9rem, 600),
  $subtitle-2: mat.m2-define-typography-level(1.4rem, 1.7rem, 600),
  $body-1: mat.m2-define-typography-level(1.3rem, 1.8rem, 400),
  $body-2: mat.m2-define-typography-level(1.1rem, 1.3rem, 400),
  $caption: mat.m2-define-typography-level(0.9rem, 1.1rem, 400),
  $button: mat.m2-define-typography-level(1.2rem, 1.4rem, 500),
  $overline: mat.m2-define-typography-level(inherit, 1, 400),
);

@include mat.core();

.mat-h1,
.mat-h2,
.mat-h4,
.mat-body-1,
.mat-subtitle-2 {
  margin-bottom: 0 !important;
}

$novisto-platform-primary: mat.m2-define-palette($navy-blue-palette, 900);
$novisto-platform-accent: mat.m2-define-palette($navy-blue-palette, 600);
$novisto-platform-warn: mat.m2-define-palette($error-palette);
$novisto-platform-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $novisto-platform-primary,
      accent: $novisto-platform-accent,
      warn: $novisto-platform-warn,
    ),
    density: 0,
  )
);

$novisto-slide-toggle-primary: mat.m2-define-palette($success-palette, 500);
$slide-toggle-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $novisto-slide-toggle-primary,
      accent: $novisto-slide-toggle-primary,
      warn: $novisto-platform-warn,
    ),
  )
);

@include mat.all-component-themes($novisto-platform-theme);
@include mat.all-component-typographies($novisto-typography);

@include mat.slide-toggle-color($slide-toggle-theme);
