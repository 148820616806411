.section {
  margin: 2rem 0 3rem 0;
  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .mat-subtitle-2 {
      padding-bottom: 1.6rem !important;
    }
  }
  &.section-dynamic,
  &.section-secondary {
    min-height: 15rem;
    padding-top: 1rem;
    border-top: 1px solid $color-grey-200;
  }
}

.section.section-dynamic .section-content {
  position: relative;
  .collection {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    align-content: start;
    .card {
      .item-title {
        height: 5.4rem;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 3;
      }
      .item-subtitle {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .collection-dummies {
    width: 100%;
    position: absolute;
    overflow: hidden;
    z-index: 10;
  }
}

.section.section-dynamic.loaded .collection-dummies {
  animation: fadeOut 0.5s linear forwards;
}
