.strong {
  font-weight: 600;
}
.capitalize {
  text-transform: capitalize;
}
.lowercase {
  text-transform: lowercase;
}
.uppercase {
  text-transform: uppercase;
}
.block {
  display: block !important;
}
.inline-block {
  display: inline-block;
}
.hide {
  display: none !important;
}
.move {
  cursor: move;
}
.img-responsive {
  max-width: 100%;
}
.container {
  padding-left: 2rem;
  padding-right: 2rem;
  overflow: auto;
}
.left-container {
  padding-left: 2rem;
}
.nowrap {
  white-space: nowrap;
}
.w-10 {
  width: 10%;
}
.w-20 {
  width: 20%;
}
.w-25 {
  width: 25%;
}
.w-33 {
  width: 33.33%;
}
.w-40 {
  width: 40%;
}
.w-50 {
  width: 50%;
}
.w-60 {
  width: 60%;
}
.w-75 {
  width: 75%;
}
.w-80 {
  width: 80%;
}
.w-100 {
  width: 100%;
}
.h-80 {
  height: 80%;
}
.h-100 {
  height: 100%;
}
.center {
  margin-left: auto;
  margin-right: auto;
}
.right {
  margin-left: auto;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.wide .page-wrapper.level-1 {
  .container {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .left-container {
    padding-left: 4rem;
  }
}
.cursor-pointer {
  cursor: pointer;
}
.line-clamp {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  &.text-clamp-1 {
    -webkit-line-clamp: 1;
  }
  &.text-clamp-2 {
    -webkit-line-clamp: 2;
  }
  &.text-clamp-3 {
    -webkit-line-clamp: 3;
  }
  &.text-clamp-4 {
    -webkit-line-clamp: 4;
  }
}
.break-word {
  word-break: break-word;
}
.break-all {
  word-break: break-all;
}
.rounded-corners {
  border-radius: 999px;
}
.fx-row {
  display: flex;
  flex-direction: row;
}
.fx-col {
  display: flex;
  flex-direction: column;
}
.fx-grow {
  display: flex;
  flex-grow: 1;
}
$directions: (
  start: flex-start,
  end: flex-end,
  space-between: space-between,
  center: center,
  baseline: baseline,
);
@each $key, $value in $directions {
  .align-#{$key} {
    align-items: $value;
  }
  .justify-#{$key} {
    justify-content: $value;
  }
}

.profile-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  background-color: $color-success;
  font-size: 2rem;
  font-weight: 500;
  border-radius: 999px;
  color: white;
}
