.card {
  padding: 1rem 2rem;
  border-radius: 3px;
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1.5px solid $color-grey-200;
  color: $color-primary-900;
  background-color: white;
  &.card-noborders {
    border: 1.5px solid transparent;
  }
  &.card-variable {
    padding: 0.5rem 1rem;
    background-color: $color-grey-50;
    &:hover,
    &:active,
    &.active {
      background-color: white;
    }
  }
  &.card-color-light {
    background-color: $color-grey-200;
    color: $color-grey-800;
    border: 1.5px solid $color-grey-200;
  }
  &.card-color-medium {
    background-color: $color-grey-600;
    color: white;
    border: 1.5px solid $color-grey-600;
  }
  &.card-dashed {
    padding: 2.5rem;
    border: 1.5px dashed $color-grey-200;
    min-height: 6.5rem;
    input {
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
    &.error {
      border: 1.5px dashed $color-error;
    }
    &:focus {
      border-color: $color-primary-600;
    }
  }
  &.card-condensed {
    padding: 1rem;
  }
  &.with-image {
    padding-top: 2rem;
    padding-bottom: 2rem;
    min-height: 16rem;
    .card-definition {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .card-definition-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }
  .card-header,
  .card-footer {
    flex-shrink: 0;
  }
  .card-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-grow: 1;
  }
  .card-image {
    position: relative;
    height: 4rem;
    min-width: 4rem;
    border-radius: 3px;
    transition: all 0.3s ease-out;
  }
  &.card-primary {
    padding: 2rem;
    height: 100%;
    min-height: 15rem;
    min-width: 14rem;
    .line-clamp-3 {
      height: 5.1rem;
    }
  }
  &.card-secondary {
    padding: 2rem;
    height: 100%;
    min-height: 10rem;
    min-width: 14rem;
    &:active,
    &.active {
      border-color: $color-primary-900;
    }
  }
  &.presentation-card {
    height: auto !important;
    display: flex;
    flex-direction: row;
    .card-definition {
      width: 100%;
      flex-grow: 1;
    }
    .card-value {
      width: 0;
      overflow: hidden;
      flex-shrink: 0;
    }
    .card-drag {
      cursor: move;
    }
    [class^="tip-text-"]::before {
      top: -0.65rem;
      bottom: -0.65rem;
      left: -1.65rem; //(0.15(border)+2(padding))-0.5rem
      height: auto;
    }
  }
  &.presentation-doc {
    padding-top: 2rem;
    padding-bottom: 2rem;
    height: 16rem !important;
  }
  &.presentation-highlights {
    height: auto !important;
  }

  .card-menu {
    visibility: hidden;
  }
  &:hover .card-menu,
  &:active .card-menu,
  &.active .card-menu {
    visibility: visible;
  }

  &.card-link {
    background-color: $color-grey-50;
    color: $color-primary-600;
    border-color: transparent;
    padding: 0.7rem 1rem;
    margin-bottom: 2px;
    &:hover,
    &.active,
    &:active {
      background-color: white;
      border-color: $color-primary-600;
    }
    .link,
    span {
      word-break: break-all;
    }
    &.success {
      background-color: $color-success;
    }
  }
  &.card-required {
    border: 1.5px solid $color-grey-600;
  }
}

.presentation-highlights {
  a.card-highlight {
    overflow-wrap: break-word;
    display: block;
    color: $color-grey-900;
    border: 1.5px solid transparent;
    border-radius: 3px;
    margin: 0.5rem 1rem;
    padding: 0.4rem 0.6rem;
    > div {
      border-left: 2px solid $color-grey-200;
      padding-left: 0.8rem;
    }
    em {
      background-color: $color-highlight !important;
      font-style: normal;
    }
    &:hover,
    &:active,
    &.active {
      border: 1.5px solid $color-primary-600;
      > div {
        border-left: 2px solid $color-primary-600;
      }
    }
  }
}

a.card,
div.card {
  color: $color-primary-900;
  &.disabled,
  &.deactivated {
    opacity: 0.3;
    background-color: $color-grey-50;
  }
}

a.card {
  &:hover,
  &:active,
  &.active {
    border: 1.5px solid $color-primary-600;
    .card-image {
      border: 1.5px solid $color-primary-600;
      border-bottom: 0;
    }
  }
}

.card-container {
  position: relative;
  .context-menu {
    opacity: 0;
    border: none;
    cursor: pointer;
    transition: opacity 0.3s ease-out;
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 2.5rem;
    height: 2.5rem;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 3px 0 $color-grey-800;
  }
  &.active,
  &:hover {
    .context-menu {
      opacity: 1;
    }
    .card {
      border: 1.5px solid $color-primary-600;
      .card-image {
        border: 1.5px solid $color-primary-600;
        border-bottom: 0;
      }
    }
  }
  &.selected {
    .card {
      &:not(.no-highlight) {
        background-color: $color-grey-200;
        pointer-events: none;
      }
    }
  }
}

.card {
  .card-footer-active,
  .card-footer-hover {
    display: none;
  }
  &:hover,
  &.active {
    .card-footer-default {
      display: none;
    }
    .card-footer-hover {
      display: inherit;
    }
  }
}

.card-container {
  .card {
    .card-footer-active,
    .card-footer-hover {
      display: none;
    }
    &:hover {
      .card-footer-hover {
        display: inherit;
      }
    }
    &.active {
      .card-footer-active {
        display: inherit;
      }
      .card-footer-hover {
        display: none;
      }
    }
  }
}
.card.card-list-item {
  .card-content {
    word-break: break-all;
  }
  .btn-fab,
  .card-footer-default,
  .card-footer-active {
    flex-shrink: 0;
  }
}
