@use "sass:map";
@use "@angular/material" as mat;

/* Primary palettes */
$navy-blue-palette: (
  50: #eeeeff,
  100: #caccff,
  200: #b0b3ff,
  300: #8c90ff,
  400: #757aff,
  500: #5359ff,
  600: #4c51e8,
  650: #3f51b5,
  700: #3b3fb5,
  800: #2e318c,
  900: #000140,
  contrast: (
    200: white,
    500: white,
    700: white,
  ),
);
$grey-palette: (
  25: #fcfcfd,
  50: #f9fafb,
  100: #f2f4f7,
  200: #eeeeee,
  300: #d0d5dd,
  400: #98a2b3,
  500: #667085,
  550: #4B5563,
  600: #475467,
  700: #344054,
  800: #1d2939,
  900: #101828,
);

/* Secondary palettes */
$info-palette: (
  25: #f6f9fc,
  50: #eaf4ff,
  75: #d0e1fd,
  100: #beddfd,
  200: #9fccfd,
  300: #73b5fc,
  400: #58a6fb,
  500: #2e90fa,
  600: #2a83e4,
  700: #2166b2,
  800: #194f8a,
  900: #133c69,
);
$success-palette: (
  50: #e7f8f0,
  75: #caf1d8,
  100: #b6e9d1,
  200: #92deba,
  300: #60cf9b,
  400: #41c588,
  500: #12b76a,
  600: #10a760,
  700: #0d824b,
  800: #0a653a,
  900: #084d2d,
  contrast: (
    200: white,
    500: white,
    700: white,
  ),
);
$warning-palette: (
  50: #fef4e6,
  100: #fdddb3,
  200: #fbcc8e,
  300: #fab55a,
  400: #f9a63a,
  500: #f79009,
  600: #e18308,
  700: #af6606,
  800: #884f05,
  900: #683c04,
);
$error-palette: (
  50: #fde6e7,
  100: #f7b0b3,
  200: #f48a8f,
  300: #ee545c,
  400: #eb333c,
  500: #e6000b,
  600: #d1000a,
  700: #a30008,
  800: #7f0006,
  900: #610005,
  contrast: (
    200: black,
    500: black,
    700: white,
  ),
);

/* Color vars */
$color-black: #000000;
$color-white: #ffffff;

// Common primary shades
$color-primary-50: mat.m2-get-color-from-palette($navy-blue-palette, 50);
$color-primary-100: mat.m2-get-color-from-palette($navy-blue-palette, 100);
$color-primary-300: mat.m2-get-color-from-palette($navy-blue-palette, 300);
$color-primary-500: mat.m2-get-color-from-palette($navy-blue-palette, 500);
$color-primary-600: mat.m2-get-color-from-palette($navy-blue-palette, 600);
$color-primary-650: mat.m2-get-color-from-palette($navy-blue-palette, 650);
$color-primary-800: mat.m2-get-color-from-palette($navy-blue-palette, 800);
$color-primary-900: mat.m2-get-color-from-palette($navy-blue-palette, 900);

// Common info shades
$color-info-500: mat.m2-get-color-from-palette($info-palette, 500);
$color-info-600: mat.m2-get-color-from-palette($info-palette, 600);

// Common error shades
$color-error-200: mat.m2-get-color-from-palette($error-palette, 200);
$color-error-500: mat.m2-get-color-from-palette($error-palette, 500);

// Common grey shades
$color-grey-25: mat.m2-get-color-from-palette($grey-palette, 25);
$color-grey-50: mat.m2-get-color-from-palette($grey-palette, 50);
$color-grey-100: mat.m2-get-color-from-palette($grey-palette, 100);
$color-grey-200: mat.m2-get-color-from-palette($grey-palette, 200);
$color-grey-300: mat.m2-get-color-from-palette($grey-palette, 300);
$color-grey-400: mat.m2-get-color-from-palette($grey-palette, 400);
$color-grey-500: mat.m2-get-color-from-palette($grey-palette, 500);
$color-grey-600: mat.m2-get-color-from-palette($grey-palette, 600);
$color-grey-700: mat.m2-get-color-from-palette($grey-palette, 700);
$color-grey-800: mat.m2-get-color-from-palette($grey-palette, 800);
$color-grey-900: mat.m2-get-color-from-palette($grey-palette, 900);

// Validation
$color-success: mat.m2-get-color-from-palette($success-palette, 400);
$color-success-accent: mat.m2-get-color-from-palette($success-palette, 300);
$color-warning: mat.m2-get-color-from-palette($warning-palette, 500);
$color-warning-accent: mat.m2-get-color-from-palette($warning-palette, 600);
$color-warning-900: mat.m2-get-color-from-palette($warning-palette, 900);
$color-warning-50: mat.m2-get-color-from-palette($warning-palette, 50);

$color-error: mat.m2-get-color-from-palette($error-palette, 500);
$color-error-accent: mat.m2-get-color-from-palette($error-palette, 600);

// Highlights
$color-highlight: #ffff00;

// Table
$color-table-border: mat.m2-get-color-from-palette($grey-palette, 200);
$color-table-header-background: mat.m2-get-color-from-palette($grey-palette, 200);
$color-table-children-background: mat.m2-get-color-from-palette($grey-palette, 50);
$color-table-children-empty-result-background: mat.m2-get-color-from-palette($grey-palette, 100);

// Docs
$color-doc: mat.m2-get-color-from-palette($info-palette, 700);
$color-doc-dark: mat.m2-get-color-from-palette($info-palette, 800);
$color-xls: mat.m2-get-color-from-palette($success-palette, 700);
$color-xls-dark: mat.m2-get-color-from-palette($success-palette, 800);
$color-ppt: #c43d1b;
$color-ppt-dark: #a2381c;
$color-pdf: mat.m2-get-color-from-palette($error-palette, 700);
$color-pdf-dark: mat.m2-get-color-from-palette($error-palette, 800);

// Status
$color-status-in-progress: mat.m2-get-color-from-palette($info-palette, 800);
$color-status-in-progress-accent: mat.m2-get-color-from-palette($info-palette, 100);
$color-status-not-started: mat.m2-get-color-from-palette($grey-palette, 200);
$color-status-not-started-accent: mat.m2-get-color-from-palette($grey-palette, 700);
$color-status-inactive: mat.m2-get-color-from-palette($grey-palette, 200);
$color-status-inactive-accent: mat.m2-get-color-from-palette($grey-palette, 700);
$color-status-imported: mat.m2-get-color-from-palette($success-palette, 100);
$color-status-imported-accent: mat.m2-get-color-from-palette($success-palette, 700);
$color-status-importing: mat.m2-get-color-from-palette($grey-palette, 200);
$color-status-importing-accent: mat.m2-get-color-from-palette($grey-palette, 700);
$color-status-in-approval: mat.m2-get-color-from-palette($info-palette, 800);
$color-status-in-approval-accent: mat.m2-get-color-from-palette($info-palette, 100);
$color-status-in-review: mat.m2-get-color-from-palette($info-palette, 800);
$color-status-in-review-accent: mat.m2-get-color-from-palette($info-palette, 100);
$color-status-in-contribution: mat.m2-get-color-from-palette($info-palette, 800);
$color-status-in-contribution-accent: mat.m2-get-color-from-palette($info-palette, 100);
$color-status-complete: mat.m2-get-color-from-palette($success-palette, 100);
$color-status-complete-accent: mat.m2-get-color-from-palette($success-palette, 700);
$color-status-archived: mat.m2-get-color-from-palette($grey-palette, 200);
$color-status-archived-accent: mat.m2-get-color-from-palette($grey-palette, 700);

// Data request
$color-completed: mat.m2-get-color-from-palette($navy-blue-palette, 900);
$color-completed-accent: mat.m2-get-color-from-palette($navy-blue-palette, 800);
$color-rejected: mat.m2-get-color-from-palette($error-palette, 500);
$color-rejected-accent: mat.m2-get-color-from-palette($error-palette, 400);
$color-accepted: mat.m2-get-color-from-palette($info-palette, 400);
$color-accepted-accent: mat.m2-get-color-from-palette($info-palette, 300);
$color-approved: mat.m2-get-color-from-palette($success-palette, 400);
$color-approved-accent: mat.m2-get-color-from-palette($success-palette, 300);
$color-incomplete: mat.m2-get-color-from-palette($grey-palette, 500);
$color-incomplete-accent: mat.m2-get-color-from-palette($grey-palette, 400);
$color-in-progress: mat.m2-get-color-from-palette($warning-palette, 100);
$color-in-progress-accent: mat.m2-get-color-from-palette($warning-palette, 50);

// Fiscal year
$color-open: mat.m2-get-color-from-palette($grey-palette, 100);
$color-closed: mat.m2-get-color-from-palette($grey-palette, 100);

// Fiscal year audit trail
$color-open: mat.m2-get-color-from-palette($grey-palette, 100);
$color-closed: mat.m2-get-color-from-palette($grey-palette, 100);
$color-reopen: mat.m2-get-color-from-palette($grey-palette, 100);

// Fiscal year taxonomy
$color-tree-border: #e5e7eb;

// Panel menu and Tree Table selected item
$color-selected-item-background: #eff6ff;
$color-selected-item: #1d4ed8;

// Tree table
$color-tree-table-disabled-selection: #f2f4f7;

// HTML markers
$color-ins-marker-bg-color: mat.m2-get-color-from-palette($success-palette, 50);
$color-ins-marker: mat.m2-get-color-from-palette($success-palette, 800);
$color-del-marker: mat.m2-get-color-from-palette($error-palette, 600);

// Job Status
$color-job-status-not-started: mat.m2-get-color-from-palette($success-palette, 100);
$color-job-status-in-progress: mat.m2-get-color-from-palette($warning-palette, 100);
$color-job-status-error: mat.m2-get-color-from-palette($error-palette, 100);
$color-job-status-queued: mat.m2-get-color-from-palette($info-palette, 100);

// Responsiveness
$breakpoint-medium-screen-width: 1450px;
$breakpoint-small-screen-width: 876px;
