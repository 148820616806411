a {
  cursor: pointer;
  outline: none;
  text-decoration: none;
  transition: all 0.3s ease-out;
  color: $color-info-600;
  &:hover,
  &:active,
  &.active {
    color: $color-primary-600;
  }
  &.disabled {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
    color: $color-grey-600 !important;
  }
}

a.label {
  display: inline-block;
  background-color: $color-grey-200;
  border-radius: 2px;
  padding: 0.1rem 0.3rem;
  margin-right: 0.5rem;
}
